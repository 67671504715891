import React, {useState} from 'react';
import {Graph} from "./HighchartGraph";
import {AxisTypes, Equipment, GraphTypes, ViewTypes} from "../../componentObjects";
import moment from "moment/moment";
import {Event} from "../GoogleAnalytics";
import AlertService from "../../services/alerts.service";

function GraphComponent({ isLoading, setLoading, assetType, graphType, chartData, visibility, title, yAxisLabel, noDataStr, graphAnnotations = [], arrowAnnotations = [], eventAnnotations = [], predictions = null, secondAxisType, 
                            dataMax, graph = {}, rlaValue = null, lraValue = null, flaValue = null, pointStart = null, AnalyzeData = null, AnalyzeIgnitionData = null, AnalyzeShutdownData = null,
                            yDataMax = null, last150Data = [], rawDataMax = 0, isTraining = false, is24Hrs = false, 
                            showAnalyzeIgnitionData = false, showAnalyzeShutdownData = false, assetId, alertList = [] }) {
    const [viewType, setViewType] = useState(ViewTypes.WholeRun);
    const [rawData, setRawData] = useState([]);
    
    const onChangeRadio = async (e) => {
        let x = e.target.value;
        setViewType(x);
        if (viewType === "RawData") {
            setLoading(true);
            await AlertService.getalertrawdata(assetId, alertList)
                .then(response => {
                    let respData = response.data;
                    let rawDataList = [];

                    for (let i = 0; i < alertList.length; i++) {
                        let alert = alertList[i];
                        let series = respData[alert.alertId];

                        let rawArr = series.data.map((item) => (item.value));
                        let rawData = {
                            name: `${alert.alertId}`,
                            data: rawArr,
                            color: series.color,
                            dashStyle: 'solid',
                            type: 'line',
                            opacity: 1,
                            marker: {
                                enabledThreshold: 0
                            },
                            yAxis: 0
                        };
                        rawDataList = rawDataList.concat(rawData);
                    }
                    
                    setRawData(rawDataList);
                    setLoading(false);
                })
                .catch(e => {
                    console.log(e);
                })
        }
    }
    const getRlaLraArray = (seriesArray, max) => {
        if (max > 0 && assetType === Equipment.AIR_CONDITIONER) {
            if (visibility.rla && rlaValue !== null && rlaValue !== undefined) {
                let rlaArr = max > 1 ? Array(max - 1).fill(rlaValue) : [];
                let rlaLabel = {
                    y: rlaValue,
                    dataLabels: { enabled: true, format: 'RLA', allowOverlap: true }
                };
                rlaArr = rlaArr.concat(rlaLabel);
                let rlaData = {
                    name: "RLA",
                    data: rlaArr,
                    color: '#f0b169',
                    type: 'line',
                    lineWidth: 2,
                    opacity: 1,
                    marker: {
                        enabledThreshold: 0
                    },
                    yAxis: 0
                }
                seriesArray = seriesArray.concat(rlaData);
            }

            if (visibility.lra && lraValue !== null && lraValue !== undefined) {
                let lraArr = max > 1 ? Array(max - 1).fill(lraValue) : [];
                let lraLabel = {
                    y: lraValue,
                    dataLabels: { enabled: true, format: 'LRA', allowOverlap: true }
                };
                lraArr = lraArr.concat(lraLabel);
                let lraData = {
                    name: "LRA",
                    data: lraArr,
                    color: 'red',
                    type: 'line',
                    lineWidth: 2,
                    opacity: 1,
                    marker: {
                        enabledThreshold: 0
                    },
                    yAxis: 0
                }
                seriesArray = seriesArray.concat(lraData);
            }

            if (visibility.fla && flaValue !== null && flaValue !== undefined) {
                let flaArr = max > 1 ? Array(max - 1).fill(flaValue) : [];
                let flaLabel = {
                    y: flaValue,
                    dataLabels: { enabled: true, format: 'FLA', allowOverlap: true }
                };
                flaArr = flaArr.concat(flaLabel);
                let flaData = {
                    name: "FLA",
                    data: flaArr,
                    color: 'pink',
                    type: 'line',
                    lineWidth: 2,
                    opacity: 1,
                    marker: {
                        enabledThreshold: 0
                    },
                    yAxis: 0
                }
                seriesArray = seriesArray.concat(flaData);
            }
        }

        return seriesArray;
    }
    
    const getRadios = () => {
        let radios150s = assetType !== Equipment.AIR_CONDITIONER || isTraining ?
            <span>
                <input type="radio" value="First150" name="viewType" />
                &nbsp; First 150s &nbsp;&nbsp;
                <input type="radio" value="Last150" name="viewType" />
                &nbsp; Last 150s &nbsp;&nbsp;
            </span>
            : null;

        let rawRadio = !isTraining ?
            <span>
                <input type="radio" value="RawData" name="viewType" />
                &nbsp; Raw Data &nbsp;&nbsp;
            </span>
            : null;

        return graphType === GraphTypes.ALERT && !is24Hrs ?
            <div className="margin-left-80 radio-container" onChange={e => onChangeRadio(e)}>
                <span>
                    <input type="radio" value="WholeRun" defaultChecked name="viewType"/>
                    &nbsp; Whole Run &nbsp;&nbsp;
                    {radios150s}
                    {rawRadio}
                </span>
            </div>
            : null;
    }
    const getOptions = () => {
        let typeOptions = {
            title: {
                text: title,
            },
            time: {
                useUTC: false
            },
            yAxis: [{
                offset: 10,
                crosshair: true,
                title: {
                    text: yAxisLabel,
                }
            }],
            xAxis: {},
            chart: {
                type: 'line',
                zoomBySingleTouch: true,
                zoomType: 'x',
                accessibility: false
            },
            series: chartData,
            plotOptions: {
                series: {
                    lineWidth: 1,
                    findNearestPointBy: 'xy',
                    marker: {
                        enabled: false
                    }
                }
            },
            lang: {
                noData: noDataStr
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#303030'
                }
            },
            annotations: []
        };
        
        if (chartData !== null && chartData !== undefined && chartData.length && chartData.filter(s => s.yAxis === 1).length) {
            let axisList = typeOptions.yAxis;
            let secondAxis = {
                crosshair: true,
                opposite: true,
                title: ""
            };
            
            if (secondAxisType === AxisTypes.TEMP) {
                secondAxis.title = { text: visibility.temp === true ? "Temp" : "" };
                axisList = axisList.concat(secondAxis);
            }
            else if (secondAxisType === AxisTypes.WIFI) {
                secondAxis.title = { text: "dBm" };
                axisList = axisList.concat(secondAxis);
            }

            typeOptions.yAxis = axisList;
        }

        if (graphType === GraphTypes.ALERT) {
            if (viewType === ViewTypes.RawData) {
                typeOptions.series = rawData;
                chartData = rawData;
            }
            else if (viewType === ViewTypes.Last150) {
                typeOptions.series = last150Data;
                chartData = last150Data;
            }

            if (viewType !== ViewTypes.WholeRun) {
                typeOptions.xAxis.max = viewType === ViewTypes.RawData ? rawDataMax : 150;
            }
            else {
                typeOptions.xAxis.max = dataMax;
            }

            typeOptions.series = getRlaLraArray(chartData, typeOptions.xAxis.max);

            if (isTraining) {
                if (visibility.annotations) {
                    if (graphAnnotations !== null && graphAnnotations !== undefined && graphAnnotations.length) {
                        typeOptions.xAxis.plotBands = graphAnnotations;
                    }

                    if (arrowAnnotations !== null && arrowAnnotations !== undefined && arrowAnnotations.length) {
                        typeOptions.xAxis.plotLines = arrowAnnotations;
                    }
                }

                if (visibility.events) {
                    if (eventAnnotations !== null && eventAnnotations !== undefined && eventAnnotations.length) {
                        typeOptions.annotations = eventAnnotations;
                    }
                }
            }
        }
        else if (graphType === GraphTypes.ASSET || graphType === GraphTypes.TRIAGE_ALERTS) {
            let tickInterval = 60;
            if (chartData != null && chartData.length && chartData[0] !== undefined && chartData[0].data !== undefined && chartData[0].data.length > 5) {
                tickInterval = chartData[0].data.length / 6;
            }

            typeOptions.xAxis = {
                type: 'datetime',
                tickInterval: 1000 * tickInterval,
                labels: {
                    format: '{value:%l:%M:%S %p}',
                    align: 'center'
                },
                offset: 10,
                crosshair: true,
                minPadding: 0.03,
                maxPadding: 0.02
            };

            if (pointStart !== null && pointStart !== undefined) {
                typeOptions.plotOptions.series.pointStart = graphType === GraphTypes.ASSET ? moment(pointStart[0]) : moment(pointStart);
            }

            if (visibility?.annotations) {
                if (graphAnnotations !== null && graphAnnotations !== undefined && graphAnnotations.length) {
                    typeOptions.xAxis.plotBands = graphAnnotations;
                }

                if (arrowAnnotations !== null && arrowAnnotations !== undefined && arrowAnnotations.length) {
                    typeOptions.xAxis.plotLines = arrowAnnotations;
                }
            }

            typeOptions.tooltip = {
                xDateFormat: '%A %b %e, %l:%M:%S %p'
            };
        }
        else if (graphType === GraphTypes.MULTISERIES) {
            typeOptions.xAxis = {
                type: 'datetime',
                tickInterval: 1000 * 3600 * 24,
                labels: {
                    format: '{value:%m/%d/%Y}',
                    align: 'center'
                },
                offset: 10,
                crosshair: true,
                minPadding: 0.03,
                maxPadding: 0.02
            };

            if (visibility.annotations) {
                if (graphAnnotations !== null && graphAnnotations !== undefined && graphAnnotations.length) {
                    typeOptions.xAxis.plotBands = graphAnnotations;
                }

                if (arrowAnnotations !== null && arrowAnnotations !== undefined && arrowAnnotations.length) {
                    typeOptions.xAxis.plotLines = arrowAnnotations;
                }
            }

            if (pointStart !== null && pointStart !== undefined) {
                typeOptions.plotOptions.series.pointStart = moment(pointStart);
            }

            if (graph !== null && graph !== undefined && graph.series !== null && graph.series !== undefined && graph.series[0] !== undefined && graph.series[0].referenceLines !== undefined && graph.series[0].referenceLines.length) {
                let plotLines = [];
                let newMax = 0;
                let newMin = graph.yDataMin;

                for (let i = 0; i < graph.series[0].referenceLines.length; i++) {
                    let refInfo = graph.series[0].referenceLines[i];

                    if (refInfo.value > newMax)
                        newMax = refInfo.value;
                    if (refInfo.value < newMin)
                        newMin = refInfo.value;

                    let refLine = {
                        color: refInfo.color,
                        width: 2,
                        value: refInfo.value,
                        label: {
                            text: refInfo.name,
                            align: 'right',
                            x: -10
                        },
                        zIndex: 2
                    };
                    plotLines = plotLines.concat(refLine);
                }
                typeOptions.yAxis[0].plotLines = plotLines;
                if (newMax > 0) {
                    typeOptions.yAxis[0].max = newMax;
                }
                typeOptions.yAxis[0].min = newMin;
            }
        }
        else if (is24Hrs) {
            typeOptions.xAxis = {
                type: 'datetime',
                tickInterval: 1000 * 3600,
                labels: {
                    format: '{value:%l:%M:%S %p}',
                    align: 'center'
                },
                offset: 10,
                crosshair: true,
                minPadding: 0.03,
                maxPadding: 0.02
            };

            if (pointStart !== null && pointStart !== undefined) {
                typeOptions.plotOptions.series.pointStart = moment(pointStart);
            }
        }

        if (assetType === Equipment.AIR_CONDITIONER && graphType !== GraphTypes.MULTISERIES) {
            let plotLines = [];
            let newMax = yDataMax !== null && yDataMax !== undefined && yDataMax > 0 ? yDataMax : 0; 

            if (graphType === GraphTypes.ASSET) {
                if (predictions !== null && visibility.annotations) {

                    if (predictions.MinCurrent !== predictions.MaxCurrent && predictions.MaxCurrent > 0) {
                        let minVal = predictions.MinCurrent;
                        let minLine = {
                            color: 'purple',
                            width: 2,
                            value: minVal,
                            label: {
                                text: 'Predicted Min',
                                align: 'right',
                                x: -10
                            },
                            zIndex: 2,
                            dashStyle: 'longDash'
                        };
                        plotLines = plotLines.concat(minLine);

                        let maxVal = predictions.MaxCurrent;
                        let maxLine = {
                            color: 'pink',
                            width: 2,
                            value: maxVal,
                            label: {
                                text: 'Predicted Max',
                                align: 'right',
                                x: -10
                            },
                            zIndex: 2,
                            dashStyle: 'longDash'
                        };
                        plotLines = plotLines.concat(maxLine);
                    }
                }
            }

            typeOptions.yAxis[0].plotLines = plotLines;
            if (newMax > 0) {
                typeOptions.yAxis[0].max = newMax;
            }
        }

        if ((showAnalyzeIgnitionData) || (showAnalyzeShutdownData)) {
            let plotLines = [];
            let newMin = 0;
            let newMax = 0;
            let basevalue = 0;
            if (chartData.length > 0) {
                basevalue = Math.min(...chartData[0]?.data);
            }
            if (basevalue === null || basevalue === undefined) { 
                basevalue = 0; 
            }

            if (AnalyzeData?.furnaceFinalResult?.furnaceProperty !== undefined) {
                let IgnitionProps = Object.keys(AnalyzeIgnitionData).concat(Object.keys(AnalyzeShutdownData));
                if (IgnitionProps.length > 0) {
                    for (let i = 0; i < IgnitionProps.length; i++) {
                        if (AnalyzeIgnitionData[IgnitionProps[i]] || AnalyzeShutdownData[IgnitionProps[i]]) {

                            const setValue = basevalue + AnalyzeData?.furnaceFinalResult?.furnaceProperty?.[IgnitionProps[i]];
                            let flaLine = {
                                color: 'pink',
                                width: 2,
                                value: setValue,
                                label: {
                                    text: IgnitionProps[i] + "," + setValue,
                                    align: 'right',
                                    x: -10
                                },
                                zIndex: 2
                            };
                            if (newMin > AnalyzeData?.furnaceFinalResult?.furnaceProperty?.[IgnitionProps[i]]) {
                                newMin = AnalyzeData?.furnaceFinalResult?.furnaceProperty?.[IgnitionProps[i]];
                            }
                            if (newMax < AnalyzeData?.furnaceFinalResult?.furnaceProperty?.[IgnitionProps[i]]) {
                                newMax = AnalyzeData?.furnaceFinalResult?.furnaceProperty?.[IgnitionProps[i]];
                            }
                            plotLines = plotLines.concat(flaLine);
                        }
                    }
                }
            }
            typeOptions.yAxis[0].plotLines = plotLines;

            if (newMin <= 0) {
                typeOptions.yAxis[0].min = newMin;
            }

        }
        typeOptions.xAxis.events = {
            afterSetExtremes: function (event) {
                if (event.min > 0) {
                    Event("User Zoomed In On Graph", "User zoomed in on graph", "User Zoomed In On Graph");
                }
            }
        };
        
        return typeOptions;
    }
    
    let options = getOptions();
    let radios = getRadios();
    
    return(
        <div>
            {radios}
            <Graph key={`${chartData.length}-${assetType === Equipment.AIR_CONDITIONER}-${Math.random()}-${viewType}-2`} options={options} />
        </div>
    )
}

export default GraphComponent;