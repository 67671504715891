import reparentCustomer from 'react';
import OnboardingDashboardCard from './OnboardingDashboardCard';
import AssetStatesRow from './AssetStatesRow';
import TrainingCountsRow from './TrainingCountsRow';
import AssetTrainingHistoryCard from './AssetTrainingHistoryCard';
import TrainingFaultsCard from './TrainingFaultsCard';
import Loader from '../../Loader/Loader';
import MultipleStatesCard from "./MultipleStatesCard";


function SharedOnboardingDashboard(props) {
    const { loading, showShowAllBtn, showAllTraining, assetType, onboardInfo, assetStateCounts, 
        onAssetsNeedTrainingClick, onTrainingReportClick, onAssetsTrainedClick, onTrainingHistoryClick, 
        onTrainingFaultsClick, onShowAllClick } = props;

    let assetsTrainedContent = <div className="row no-gutters card-row text-center">
        <div className="col min-height-40">
            <div className="row no-gutters dashboard-val justify-content-center">
                <div className="col">
                    <div className="dashboard-number-3">
                        {assetStateCounts.operational}
                    </div>
                </div>

            </div>
            <div>
                <small className={"dashboard-placeholder"}>placeholder</small>
            </div>
        </div>
    </div>;
    
    let contents = loading ?
            <Loader />
            : 
            <div>
                <AssetStatesRow title={`${assetType} Asset States`} assetStateCounts={assetStateCounts} onAssetsNeedTrainingClick={onAssetsNeedTrainingClick} />
                <div className="row pad-0-30-0">
                    <TrainingCountsRow onboardInfo={onboardInfo} onTrainingReportClick={onTrainingReportClick}/>
                    <div className="col-6 display-grid">
                        <div>
                            <OnboardingDashboardCard title={`${assetType} Assets Trained`} content={assetsTrainedContent} onClick={onAssetsTrainedClick} isGraphCard={false}/>
                        </div>
                    </div>
                </div>
                <div className="row height-100p pad-0-30-0">
                    <AssetTrainingHistoryCard assetType={assetType} onboardInfo={onboardInfo} onTrainingHistoryClick={onTrainingHistoryClick}/>
                    <div className={"col-6"}>
                        <TrainingFaultsCard assetType={assetType} onboardInfo={onboardInfo} onTrainingFaultsClick={onTrainingFaultsClick}/>
                        <MultipleStatesCard assetType={assetType} multiStateAssets={onboardInfo.assetStateCounts?.multiStateAssets}/>
                    </div>
                </div>
            </div>;

        let showAllBtn = showShowAllBtn ?
            <div className="btn-right pad-25-0-0-30">
                <button className={showAllTraining ? "primary-btn btn-large width-150" : "secondary-btn btn-large width-150"} onClick={onShowAllClick}>Show All</button>
            </div>
            : null;

    return (
        <div sx={(theme)=>({
            [theme.breakpoints.down('md')]: {
                marginLeft: '-225px !important',
            },
        })}>
            <div>
                <div className="row no-gutters">
                    <div className="col">
                        <h2 className="pageTitle dashboard-title" id="tabelLabel" >
                            {assetType} Onboarding
                        </h2>
                    </div>
                    <div className="col">
                        {showAllBtn}
                    </div>
                </div>

                {contents}

            </div>
        </div>
    );
}

export default SharedOnboardingDashboard;