import React, { useState } from 'react';
import "../../custom.css";

function AddressvalidationModel(props) {
    const onCancelClick = (e) => {
        props.onChage(e);
    };

    const selectedAddress = (e,value) => {
        props.onSelectedAddress(value);
    };
    
    return (
        <div className="modal" style={{display: props?.show ? "block" : "none"}}>
            <div className="modal-wrapper"
                 style={{
                     transform: props?.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                     opacity: props?.show ? '1' : '0'
                 }}>

                <div className="modal-header">
                    <h3>Select Address</h3>
                    <span className="close-modal-btn" onClick={onCancelClick}>×</span>
                </div>
                <div>
                    <div className="address-container">
                        {props.addressList.map((option, index) => (
                            <div className="address-grid">
                                <div>
                                    <div key= {index +"addresstype"} className="address-title">
                                        <strong>{option.type}</strong> 
                                    </div>
                                    <div key= {index +"address"} className="address-field">
                                        <strong>Address :</strong> {option.address}
                                    </div>
                                    <div key= {index +"city"} className="address-field">
                                        <strong>City :</strong> {option.city}
                                    </div>
                                    <div key= {index +"state"} className="address-field">
                                        <strong>State :</strong> {option.state}
                                    </div>
                                    <div key= {index +"country"} className="address-field">
                                        <strong>Country :</strong> {option.country}
                                    </div>
                                    <div key= {index +"zip"} className="address-field">
                                        <strong>Zip :</strong>  {option.zip}
                                    </div>
                                    
                                </div>
                                <div key="County" className="address-btn">
                                <button className="primary-btn btn-small"
                                        onClick={e=> selectedAddress(e,option)}>Select
                                </button>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddressvalidationModel;