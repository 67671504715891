import http from "../http-common";

const controller = "/AddressValidation";
class AddressValidationService {
    async getuspsaddress(addressQuery) {
        const response = await http.post(`${controller}/addressvalidation`,addressQuery);
        const data = response;
        return data;
    }
}
export default new AddressValidationService();