/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import FullLoader from "../Loader/FullLoader";

class AlertTypeModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            selectedTypeId: null,
            escalateChecked: false
        };

        this.onChangeAlertType = this.onChangeAlertType.bind(this);
        this.onSelectAlertType = this.onSelectAlertType.bind(this);
        this.changeEscalateCheckbox = this.changeEscalateCheckbox.bind(this);
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        if (this.state.escalateChecked) {
            this.setState({ escalateChecked: false });
        }
    };

    onSelectAlertType(e) {
        const typeId = e.target.value;
        this.setState({ selectedTypeId: typeId })
    }

    async onChangeAlertType() {
        this.props.onChangeAlertType(this.state.selectedTypeId, this.state.escalateChecked);
        if (this.state.escalateChecked) {
            this.setState({ escalateChecked: false });
        }
    }

    changeEscalateCheckbox() {
        this.setState({ escalateChecked: !this.state.escalateChecked });
    }

    render() {
        let alertTypesLoading = this.props.modalLoading ?
            <FullLoader/>
            : <div>{null}</div>

        if (!this.props.show) {
            return null;
        }
        else {
            return (
                <div>
                    <div className="modal" style={{ display: this.props.show ? (this.props.modalLoading ? "none" : "block") : "none" }}>
                        <div className="modal-wrapper"
                            style={{
                                transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                                opacity: this.props.show ? '1' : '0'
                            }}>

                            <div className="modal-header">
                                <h3>Change Alert Type</h3>
                                <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                            </div>

                            <div className="modal-body">
                                <div className="centered">
                                    <div className="row">
                                        <div className="col-4">
                                            <br />
                                            <span className="select-message">Select New Alert Type</span>
                                        </div>
                                        <div className="col">
                                            <br />
                                            <select className="txt-detail" value={this.state.selectedTypeId == null ? this.props.selectedTypeId : this.state.selectedTypeId} onChange={this.onSelectAlertType} style={{ paddingLeft: '2px', paddingRight: '18px' }}>
                                                {this.props.alertTypes.map((type, index) =>
                                                    <option key={`${index}-${type.alertTypeId}`} id={`${index}-${type.alertTypeId}`} value={type.alertTypeId}>{type.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-4">
                                            <br />
                                            Escalate
                                            <input style={{marginLeft: '10px'}} type="checkbox" value="escalate" checked={this.state.escalateChecked} id="esc" onChange={this.changeEscalateCheckbox} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal-footer">
                                <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                                <button className="primary-btn btn-small" onClick={this.onChangeAlertType}>Change Alert Type</button>
                            </div>

                        </div>

                    </div>
                    {alertTypesLoading}
                </div>
            );
        }
    }
}

export default AlertTypeModal;