/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, {Component, createRef} from 'react';
import DatePicker from 'react-datepicker';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AssetService from "../../services/asset.service";
import AlertService from '../../services/alerts.service';
import ThermostatService from '../../services/thermostat.service';
import DailyStatisticsService from '../../services/dailyStatistics.service';
import AddUnparentedModal from "./AddUnparentedModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import CommentsModal from '../TriageAlerts/CommentsModal';
import UpdateAssetClassModal from "./UpdateAssetClassModal";
import TestSystemModal from "./TestSystemModal";
import TurnOffSystemModal from "./TurnOffSystemModal";
import FullScreenChartModal from '../LWTriageAlerts/FullScreenChartModal';
import "react-datepicker/dist/react-datepicker.css";
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StarOutlineIcon from '@mui/icons-material/StarOutlineRounded';
import StarIcon from '@mui/icons-material/StarRounded';
import Snackbar from '@mui/material/Snackbar';
import CircleIcon from '@mui/icons-material/FiberManualRecord';

import {
    assetHierarchy,
    AxisTypes,
    Equipment,
    GraphTypes,
    jsACAssetsGraphData,
    jsACAssetTimelineGraph,
    jsACGraphData,
    jsAsset,
    jsAssetTimelineGraph,
    jsGraph,
    jsGraphData,
    jsSource,
    ThermostatPocData
} from "../../componentObjects";
import {Event, ModalView, PageView, Timing} from "../GoogleAnalytics";

import {addHours} from 'date-fns';
import TechnicianView from "../Technician/TechnicianInfo";
import moment from "moment/moment";
import AssetHierarchyView from "./Hierarchy/AssetHierarchyView";
import commentsService from '../../services/comments.service';
import EnergyMetricsTable from '../EnergyMetrics/EnergyMetricsTable';
import UserAssetList from "./Hierarchy/UserAssetList";
import ConfirmPasswordModal from "../Account/ConfirmUser/ConfirmPasswordModal";
import AccountService from "../../services/account.service";
import ConfirmDeleteAccountModal from "../Account/ConfirmDeleteAccountModal";
import CommentButton from "../Buttons/CommentButton";
import ContextMenu from "../ContextMenu/contextMenu";
import Loader from "../Loader/Loader";
import GraphComponent from "../Graph/GraphComponent";
import {withRouter} from "../Routing/withRouter";

const currentDate = new Date();

function collect(props) {
    return props;
}

class Assets extends Component {
    static displayName = Assets.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.calculateMonthlyData = this.calculateMonthlyData.bind(this);
        this.onAssetSelect = this.onAssetSelect.bind(this);
        this.onHVACSystemSelect = this.onHVACSystemSelect.bind(this);
        this.onChangeAssetType = this.onChangeAssetType.bind(this);
        this.onChangeNickName = this.onChangeNickName.bind(this);
        this.onChangeMake = this.onChangeMake.bind(this);
        this.onChangeModel = this.onChangeModel.bind(this);
        this.onChangeSerialNumber = this.onChangeSerialNumber.bind(this);
        this.updateAsset = this.updateAsset.bind(this);
        this.deleteAsset = this.deleteAsset.bind(this);
        this.routeToBilling = this.routeToBilling.bind(this);
        this.showAddUnparentedAssetModal = this.showAddUnparentedAssetModal.bind(this);
        this.openAddUnparentedAssetModal = this.openAddUnparentedAssetModal.bind(this);
        this.addUnparentedAsset = this.addUnparentedAsset.bind(this);
        this.onUnparentedAssetSelect = this.onUnparentedAssetSelect.bind(this);
        this.onChangeStart = this.onChangeStart.bind(this);
        this.onChangeEnd = this.onChangeEnd.bind(this);
        this.onChangeMFGDate = this.onChangeMFGDate.bind(this);
        this.onChangeRun = this.onChangeRun.bind(this);
        this.onAssetClick = this.onAssetClick.bind(this);
        this.getAssetData = this.getAssetData.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.isDstObserved = this.isDstObserved.bind(this);
        this.stdTimezoneOffset = this.stdTimezoneOffset.bind(this);
        this.getDisableAnalytics = this.getDisableAnalytics.bind(this);

        this.onLeftRunClick = this.onLeftRunClick.bind(this);
        this.onRightRunClick = this.onRightRunClick.bind(this);
        this.onViewRuns = this.onViewRuns.bind(this);

        this.openDeleteAssetModal = this.openDeleteAssetModal.bind(this);
        this.showDeleteAssetModal = this.showDeleteAssetModal.bind(this);

        this.openUpdateAssetModal = this.openUpdateAssetModal.bind(this);
        this.showUpdateAssetModal = this.showUpdateAssetModal.bind(this);

        this.openUpdateAssetClassModal = this.openUpdateAssetClassModal.bind(this);
        this.showUpdateAssetClassModal = this.showUpdateAssetClassModal.bind(this);

        this.openAssetCommentsModal = this.openAssetCommentsModal.bind(this);
        this.showAssetCommentsModal = this.showAssetCommentsModal.bind(this);
        this.onChangeCommentAlertType = this.onChangeCommentAlertType.bind(this);
        this.addConversationToAsset = this.addConversationToAsset.bind(this);
        this.updateAlertComment = this.updateAlertComment.bind(this);

        this.onTestSystemClick = this.onTestSystemClick.bind(this);
        this.openTestSystemModal = this.openTestSystemModal.bind(this);
        this.showTestSystemModal = this.showTestSystemModal.bind(this);
        this.onSystemSelect = this.onSystemSelect.bind(this);
        this.onRunRoutine = this.onRunRoutine.bind(this);

        this.onTurnOffSystemClick = this.onTurnOffSystemClick.bind(this);
        this.openTurnOffSystemModal = this.openTurnOffSystemModal.bind(this);
        this.showTurnOffSystemModal = this.showTurnOffSystemModal.bind(this);
        this.onTurnOffSystem = this.onTurnOffSystem.bind(this);

        this.onChangeGraph = this.onChangeGraph.bind(this);

        this.openGraphModal = this.openGraphModal.bind(this);
        this.showGraphModal = this.showGraphModal.bind(this);
        this.showGraphAnnotations = this.showGraphAnnotations.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleCTCheckbox = this.handleCTCheckbox.bind(this);

        this.openTimelineModal = this.openTimelineModal.bind(this);
        this.showTimelineModal = this.showTimelineModal.bind(this);

        this.updateClasses = this.updateClasses.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.handleRemove = this.handleRemove.bind(this);

        this.onChangeCompressorRla = this.onChangeCompressorRla.bind(this);
        this.onChangeCompressorLra = this.onChangeCompressorLra.bind(this);
        this.onChangeFanFla = this.onChangeFanFla.bind(this);
        this.onChangeFanLra = this.onChangeFanLra.bind(this);

        this.openInfoModal = this.openInfoModal.bind(this);
        this.showInfoModal = this.showInfoModal.bind(this);

        this.updateAssetList = this.updateAssetList.bind(this);
        this.updateAssetListInParent = this.updateAssetListInParent.bind(this);
        this.removeFromAssetList = this.removeFromAssetList.bind(this);
        this.addToAssetList = this.addToAssetList.bind(this);

        this.openFavoriteModal = this.openFavoriteModal.bind(this);
        this.showFavoriteModal = this.showFavoriteModal.bind(this);
        this.updateAssetFavorites = this.updateAssetFavorites.bind(this);
        this.getHighchartsData2 = this.getHighchartsData2.bind(this);

        this.populateAssetData = this.populateAssetData.bind(this);
        this.populateEnergyData = this.populateEnergyData.bind(this);
        this.setDevices = this.setDevices.bind(this);
        this.showConfirmationModal = this.showConfirmationModal.bind(this);
        this.checkAccountAssets = this.checkAccountAssets.bind(this);
        this.cancelAccountDelete = this.cancelAccountDelete.bind(this);
        this.showPasswordConfirmationModal = this.showPasswordConfirmationModal.bind(this);
        this.onConfirmCancel = this.onConfirmCancel.bind(this);
        this.showDeleteAccounModal = this.showDeleteAccounModal.bind(this);

        this.openTTConfirmModal = this.openTTConfirmModal.bind(this);
        this.showTTConfirmModal = this.showTTConfirmModal.bind(this);
        this.onTTConfirm = this.onTTConfirm.bind(this);

        this.state = {            
            assetList: [],
            clearPreviusState: false,
            currentHVACSystem: assetHierarchy,
            currentAsset: jsAsset,
            newAsset: jsAsset,            
            unparentedAsset: jsAsset,            
            unparentedAssets: [],
            appliedClasses: [],
            unappliedClasses: [],
            applyList: [],
            removeList: [],
            loading: false,
            energyLoading: true,
            //showAddAsset: false,
            showAddUnparentedAsset: false,
            statusMessage: null,
            showStatusMessage: null,
            assetLoading: false,
            graphLoading: false, 
            updateClassesLoading: false,
            showUpdateConfirmation: false,
            showDeleteConfirmation: false,
            showUpdateAssetClass: false,
            showTestSystem: false,
            showTurnOffSystem: false,
            showCommentsModal: false,
            selectedSystemId: -1,
            isSelected: -1,
            assetSelected: false,
            acSelected: false,
            thermostatSelected: false,
            selectedAssetNumber: -1,
            modalMessage: null,
            tabIndex: 0, 
            graph: jsGraph,
            graphData: jsACAssetsGraphData,
            graphDataAC: jsACAssetsGraphData,
            graphConstants: {},
            timelineGraph: jsAssetTimelineGraph, 
            timelineData: jsGraphData, 
            timelineDataAnnotated: jsGraphData,
            timelineGraphAC: jsACAssetTimelineGraph,
            timelineDataAC: jsACGraphData,
            currentRun: null,
            currentMax: 0,
            currentRunName: "",
            runList: [],
            selectedRun: 0,
            numRuns: 0,
            currentGraphAnnotations: [],
            currentGraphArrowAnnotations: [],
            timelineAnnotations: [], 
            timelineArrowAnnotations: [],
            currentSource: jsSource,
            currentHighchartSource: {},
            currentPredictions: null,

            currentTimeline: {},
            chartDataList: [],
            showGraphAnnotations: false,
            showGraphModal: false,
            showTimelineAnnotations: false,  
            showTimelineModal: false,
            startDateTimeStr: "",
            endDateTimeStr: "",
            dpStartDateTime: null,
            dpEndDateTime: null,
            canLeftClick: false,
            canRightClick: false,
            graphType: "Graph",  
            disableGraphOptions: false, 
            showInfoModal: false,
            showFavoriteModal: false,
            untrainedRun: false,
            isTraining: false,

            systemTurnOffLoading: false,
            showTurnOffMessage: false,
            turnOffSuccess: false,
            turnOffMessage: "",

            monthlyDataObj:{},
            summarizedData:{},
            dateSelected: currentDate,
            assetIds: [],

            commentDict: {},
            assetComments: [],
            alertTypes: [],
            currentType: "",
            assetConversation: [],
            currentTypeConversation: [],
            conversationId: null,
            alertComment: {},

            systemTestLoading: false,
            systemTestResult: "",
            thermData: ThermostatPocData,
            thermDataLoaded: false,
            currentThermMac: "",
            visibility: {
                analytics: false,
                annotations: false,
                furnace: true,
                temp: false,
                rla: false,
                lra: false,
                batteryVoltage: false,
                blowerCurrent: false,
                inducerCurrent: false,
                coolSetpoint: false,
                heatSetpoint: false
            },
            userassethierarchy: [],
            showConfirmation: false,
            modalTitle: "",
            isActiveBillingPlan: false,
            showPasswordConfirmation: false,
            isDeleted: false,
            waitDelete: false,
            showDeleteAccount: false,
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,
            
            showTTConfirm: false,
            devTTUrl: "",
            selectedRowIdForContextMenu: null
        };
        this.contextMenuRef = createRef();
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();
        this.populateAssetData();
        this.setState({ clearPreviusState: false });
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentCustomerId !== this.props.currentCustomerId) {
            this.populateAssetData();
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    populateAssetData() {
                var now = moment();
                var before = moment(now).add(-1, 'days');
                var beforeStr = before.utc().format();
                var nowStr = now.utc().format();
                var dpSTR = before.toDate();    //date picker string 
                var dpSTR2 = now.toDate();     // date picker string end
            this.setState({loading: true, dpStartDateTime: dpSTR, startDateTimeStrPlus: beforeStr, dpEndDateTime: dpSTR2, endDateTimeStrPlus: nowStr }, async () => {
                if (this.props.currentCustomerId === "-1") {
                    this.setState({ loading: false });
                }
            });
    }

    async setDevices(){
        if (this.state.currentHVACSystem.children.length){
            var startTime = performance.now();
            let devices = this.state.currentHVACSystem.children.map(d => d.id);
            this.populateEnergyData(this.state.dateSelected, devices);
            await AssetService.getassetsproperties(devices).then(hvacsResponse => {
                if (this._isMounted) {
                    if (hvacsResponse.data !== null && hvacsResponse.data !== undefined) {
                        var newAssetList = hvacsResponse.data;
                        if (newAssetList !== null && newAssetList !== undefined && newAssetList.length && this.props.hasServiceTier && this.props.serviceTier < 3)
                            newAssetList = newAssetList.filter(n => n.type.trim() === "Thermostat");
                        for (let i = 0; i < newAssetList.length; i++) {
                            for (let j = 0; j < this.state.currentHVACSystem.children.length; j++) {
                                if (newAssetList[i].assetId === this.state.currentHVACSystem.children[j].id) {
                                    newAssetList[i].mac = this.state.currentHVACSystem.children[j].name;
                                    newAssetList[i].name = this.state.currentHVACSystem.children[j].name;
                                }
                            }
                        }
                        this.setState({loading: false, assetList: newAssetList, currentAsset: jsAsset}, async () => {
                            var elapsedTime = performance.now() - startTime;
                            Timing("Assets Loading", "loading", elapsedTime, "Customer Assets Loading");
                        });
                    } else {
                        this.setState({loading: false}, () => {
                            var elapsedTime = performance.now() - startTime;
                            Timing("Assets Loading", "loading", elapsedTime, "Customer Assets Loading");
                        });
                    }
                }
            });
        }else{
            this.setState({loading: false, assetList: [], currentAsset: jsAsset }, async () => {
                var elapsedTime = performance.now() - startTime;
                Timing("Assets Loading", "loading", elapsedTime, "Customer Assets Loading");
            });
        }
    }

    stdTimezoneOffset(date) {
        var jan = new Date(date.getFullYear(), 0, 1);
        var jul = new Date(date.getFullYear(), 6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    }

    isDstObserved(date) {
        var stdOffset = this.stdTimezoneOffset(date)
        return date.getTimezoneOffset() < stdOffset;
    }

    showAddUnparentedAssetModal() {
        this.setState({
            showAddUnparentedAsset: !this.state.showAddUnparentedAsset
        }, () => {
                if (this.state.showAddUnparentedAsset)
                    ModalView("addUnparentedAsset");
        });
        window.scrollTo(0, 0);
    }

    async openAddUnparentedAssetModal() {
        await AssetService.getunparented()
            .then(response => {
                this.setState({ unparentedAssets: response.data, unparentedAsset: response.data !== undefined && response.data.length ? response.data[0] : jsAsset });
            })
            .then(this.showAddUnparentedAssetModal)
            .catch(e => {
                console.log(e);
            });
    }

    async addUnparentedAsset() {
        await AssetService.addunparented(this.state.unparentedAsset.assetId, this.state.unparentedAsset.name, this.state.currentHVACSystem.id, this.props.currentCustomerId).then(response => {
            let message = "";

            if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                message = "Successfully parented Asset!";

                Event("Asset Add Unparented", "User clicked add unparented asset button", "Add Unparented Asset Clicked");
            }
            else {
                message = "Failed to parent Asset!";
            }

            this.setState({
                showAddUnparentedAsset: !this.state.showAddUnparentedAsset, statusMessage: message,
                unparentedAsset: jsAsset, 
                loading: true
            }, () => {
                window.location.reload();
            });
        });
    }

    updateAsset() {
        AssetService.update(
            this.state.currentAsset
        ).then(response => {
            let message = "";

            if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                message = "Successfully updated Asset!";

                this.setState({ statusMessage: message, showUpdateConfirmation: false });

                Event("Asset Update", "User clicked update asset button", "Update Asset Clicked");

                // update the master list in app.js..

                this.updateAssetListInParent(this.state.currentAsset);
            }
            else {
                message = "Failed to update Asset!";
                this.setState({ statusMessage: message, showUpdateConfirmation: false });
            }

        }).catch(e => {
            console.log(e);
        });
    }

    routeToBilling = () => {
        Event("Delete Asset Clicked", "User clicked Delete Asset, routing to Billing Info Page", "Delete Asset Clicked");
        this.props.setActivePath("/billingInfo");
        this.props.navigate("/billingInfo");
    }

    deleteAsset() {
        if (this.state.currentAsset != null && this.state.currentAsset !== undefined) {
            AssetService.delete(this.state.currentAsset.assetId, this.state.currentAsset.mac, this.props.currentAccount.email)
                .then(response => {
                    let message = "";

                    if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                        message = "Successfully deleted Asset!";
                        this.setState({ statusMessage: message, showDeleteConfirmation: false });
                        Event("Asset Delete", "User clicked delete asset button", "Delete Asset Clicked");

                        this.reloadAssets();
                    }
                    else {
                        message = "Failed to delete Asset!";
                        this.setState({ statusMessage: message, showDeleteConfirmation: false });
                    }
                }).catch(e => {
                    console.log(e);
                });
        }
        else {
            let message = "No selected Asset!";

            this.setState({ statusMessage: message, showDeleteConfirmation: false });
        }
    }

    updateAssetListInParent(currentAsset) {
        for (let i = 0; i < this.state.assetList.length; i++) {

            if (this.state.assetList[i].assetId === currentAsset.assetId) {
                let assets = [...this.state.assetList];

                let asset = { ...assets[i] };

                asset = currentAsset;

                assets[i] = asset;

                this.setState({ assetList: assets });
            }
        }
    }

    removeFromAssetList(currentAsset) {

        for (let i = 0; i < this.state.assetList.length; i++) {

            if (this.state.assetList[i].assetId === currentAsset.assetId) {
                var array = [...this.state.assetList];

                if (i !== -1) {
                    array.splice(i, 1);
                    this.setState({ assetList: array });
                }

            }
        }
    }

    addToAssetList(newAsset) {
        var assets = [...this.state.assetList];

        assets.push(newAsset);

        this.setState({ assetList: assets });
    }

    updateAssetList(response) {
        if (response) {
            if (this.state.assetList.length === 1) {
                this.setState({ assetList: [], currentAsset: jsAsset, showDeleteConfirmation: false });
            }
            else if (this.state.selectedAssetNumber > -1) {
                this.setState({ assetList: this.state.assetList, showDeleteConfirmation: false }, () => {
                    this.setState({ currentAsset: jsAsset, isSelected: -1, selectedAssetNumber: 0 });
                });
            }
            else {
                this.populateAssetData();
            }
        }
    }

    showGraphModal() {
        this.setState({
            showGraphModal: !this.state.showGraphModal
        }, () => {
                if (this.state.showGraphModal)
                    ModalView("assetRunGraph");
        });
        window.scrollTo(0, 0);
    }

    openGraphModal() {
        Event("Asset Fullscreen Click", "User clicked fullscreen run graph button", "Fullscreen Run Graph Clicked");
        this.showGraphModal();
    }

    showTimelineModal() {
        this.setState({
            showTimelineModal: !this.state.showTimelineModal
        }, () => {
                if (this.state.showTimelineModal)
                    ModalView("assetTimelineGraph");
        });
        window.scrollTo(0, 0);
    }

    openTimelineModal() {
        Event("Asset Fullscreen Click", "User clicked fullscreen timeline graph button", "Fullscreen Timeline Graph Clicked");
        this.showTimelineModal();
    }

    showDeleteAssetModal() {
        this.setState({
            showDeleteConfirmation: !this.state.showDeleteConfirmation
        }, () => {
                if (this.state.showDeleteConfirmation)
                    ModalView("deleteAssetConfirm");
        });
        window.scrollTo(0, 0);
    }

    openDeleteAssetModal() {
        this.setState({ modalMessage: "Are you sure you wish to delete this asset?", modalTitle: "Delete Asset" }, () => {
            this.showDeleteAssetModal();
        });
    }

    showUpdateAssetModal() {
        this.setState({
            showUpdateConfirmation: !this.state.showUpdateConfirmation
        }, () => {
                if (this.state.showUpdateConfirmation)
                    ModalView("updateAssetConfirm");
        });
        window.scrollTo(0, 0);
    }

    openUpdateAssetModal() {
        this.setState({ modalMessage: "Are you sure you wish to update this asset?", modalTitle: "Update Asset" }, () => {
            this.showUpdateAssetModal();
        });
    }

    showUpdateAssetClassModal() {
        this.setState({
            showUpdateAssetClass: !this.state.showUpdateAssetClass
        }, () => {
                if (this.state.showUpdateAssetClass)
                    ModalView("updateAssetClass");
        });
        window.scrollTo(0, 0);
    }
    
    showConfirmationModal = () => {
        this.setState({
            showConfirmation: !this.state.showConfirmation,
            modalTitle: !this.state.showConfirmation ? "Delete Assets" : "",
        }, () => {
            if (this.state.showConfirmation){
                this.checkAccountAssets(this.props.currentCustomerId);
                ModalView("deleteAssetsConfirm");
            }
        });
        window.scrollTo(0, 0);
    }
    
    checkAccountAssets(id) {
        this.setState({
            loading: true,
            waitDelete: true
        }, async ()=> {
            let fromAssetId = this.state.currentHVACSystem.id;
            await AssetService.getassethierarchy(id, fromAssetId).then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && Array.isArray(response.data) && response.data.length) {
                    this.setState({
                        userassethierarchy: response.data,
                        isDeleted: false,
                        isActiveBillingPlan: false
                    });
                } else {
                    this.setState({
                        message: "Failed to delete the system! Reason: " + response.data,
                        isActiveBillingPlan: false,
                        isDeleted: false,
                        loading: false
                    });
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                        waitDelete: false
                    });
                }, 3000);

            })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    cancelAccountDelete(){
        Event("Cancel Hvac system Delete", "User canceled Hvac system delete", "Cancel Hvac system Delete");
        this.setState({
            showCancelModal: false,
            showConfirmCancelModal: false,
            showConfirmation: false
        });
    }
    
    
   showPasswordConfirmationModal = () => {
        this.setState({
            showPasswordConfirmation: !this.state.showPasswordConfirmation,
            isDeleted: false,
            showCancelModal: false,
            showConfirmCancelModal: false,
            showConfirmation: false
        }, () => {
            if (this.state.showPasswordConfirmation){
                ModalView("ConfirmPasswordModal");
            }
        });
        window.scrollTo(0, 0);
    }

    onConfirmCancel(id) {
        Event("Cancelled Subscription(s)", "User cancelled billing subscription(s)", "Cancelled Subscription(s)");
        this.setState({ loading: true, waitDelete: true, showConfirmCancelModal: false }, async () => {
            AccountService.deletehvac(id)
                .then(response => {
                    if (response.status === 200 && response.data !== undefined && response.data !== null && response.data === "Success") {
                        this.setState({
                            message: "Successfully updated moved assets to DeletedAssets!",
                            isDeleted: true,
                            showMessage: true,
                            loading: false,
                            isActiveBillingPlan: false,
                            currentHVACSystem: assetHierarchy
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    message: "",
                                    showMessage: false
                                }, ()=>{this.showDeleteAccounModal(); this.setDevices();});
                            }, 3000);
                        });
                    }else if (response.status === 200 && response.data !== undefined && response.data !== null && response.data === "Active") {
                        this.setState({
                            message: "Need to cancel the subscription first before delete the system!",
                            isDeleted: false,
                            isActiveBillingPlan: true
                        });
                    } else {
                        this.setState({
                            message: "Failed to delete the system! Reason: " + response.data,
                            isDeleted: false,
                            showMessage: true,
                            loading: false,
                            isActiveBillingPlan: false
                        }, ()=>{this.showDeleteAccounModal();});
                    }
                    setTimeout(() => {
                        this.setState({
                            waitDelete: false
                        });
                    }, 3000);
                })
                .catch(e => {
                    console.log(e);
                })

            this.setState({
                loading: false
            });
        });
    }

    showDeleteAccounModal = e =>{
        this.setState({
            showDeleteAccount: !this.state.showDeleteAccount,
            message: !this.state.showDeleteAccount ? "Would you like to delete the HVAC system?" : "",
            showPasswordConfirmation: false,

        }, () => {
            if (this.state.showDeleteAccount) {
                ModalView("/confirmDeleteAccount");
            }
        });
        window.scrollTo(0, 0);
    }
    
    async openUpdateAssetClassModal() {
        await AssetService.getappliedclasses(this.state.currentAsset.assetId).then(response => {
            this.setState({ appliedClasses: response.data }, async () => {
                await AssetService.getunappliedclasses(this.state.currentAsset.assetId).then(response2 => {
                    this.setState({ unappliedClasses: response2.data });
                })
            })
        })
            .then(this.showUpdateAssetClassModal)
            .catch(e => {
                console.log(e);
            })

    }

    handleApply(e) {
        const classId = e.target.value;

        if (e.target.checked) {
            this.state.applyList.push(classId)
        }
    }

    handleRemove(e) {
        const classId = e.target.value;

        if (e.target.checked) {
            this.state.removeList.push(classId)
        }
    }

    async updateClasses() {
        let message = "";
        var startTime = performance.now();

        this.setState({
            updateClassesLoading: true
        }, async () => {
            await AssetService.applyclasses(this.state.currentAsset.assetId, this.state.applyList).then(response => {
                if (response.statusCode === 200) {
                    message = "Successfully applied Asset classes!";
                }
                else {
                    message = "Failed to apply Asset classes!";
                }

                this.setState({
                    statusMessage: message
                }, async () => {
                    await AssetService.removeclasses(this.state.currentAsset.assetId, this.state.removeList).then(response2 => {
                        if (response2.statusCode === 200) {
                            message = "Successfully updated Asset classes!";
                        }
                        else {
                            message = "Failed to remove Asset classes!";
                        }
                        this.setState({
                            showUpdateAssetClass: !this.state.showUpdateAssetClass,
                            statusMessage: message,
                            updateClassesLoading: false
                        }, () => {
                                var elapsedTime = performance.now() - startTime;
                                Timing("Loading Updated Asset Classes", "updateClassesLoading", elapsedTime, "Loading Updated Asset Classes");
                        });

                        Event("Asset Update Classes", "User clicked update asset class button", "Update Asset Class Clicked");
                    });
                });
            });
        });
    }

    /* COMMENTS */
    showAssetCommentsModal() {
        this.setState({ showCommentsModal: !this.state.showCommentsModal }, () => {
            if (this.state.showCommentsModal)
                ModalView('assetComments');
        })
    }

    openAssetCommentsModal() {
        this.setState({ showCommentsModal: true }, async () => {
            ModalView('assetComments');
            
            let assetId = this.state.currentAsset.assetId;
                await commentsService.getallassetcomments(parseInt(assetId))
                    .then(async response => {
                        if (this._isMounted) {
                            let assetComments = response.data;
                            let commentDict = {}; //response.data; TODO?
                            const alertTypes = assetComments != null && assetComments !== undefined && assetComments.length ? assetComments.map((comment) => comment.alertTypeName) : [];//Object.keys(commentDict);
                            if (!alertTypes.includes("Asset")) {
                                alertTypes.push("Asset");
                            }

                            const currentType = alertTypes[0];

                            if (!assetComments.length) {
                                await AlertService.getassetconvoid(assetId)
                                    .then(response => {
                                        const commentObj = {
                                            id: 0,
                                            assetId: parseInt(assetId),
                                            alertTypeId: 0, //asset type
                                            alertTypeName: 'Asset',
                                            convoId: parseInt(response.data),
                                            hasNewComment: false,
                                            hasQuestion: false,
                                            hasAnswer: false
                                        };
                                        assetComments = assetComments.concat(commentObj);

                                        if (response.status === 200 && response.data !== undefined && response.data !== null) {
                                            this.setState({ 
                                                commentDict: commentDict, assetComments: assetComments, alertTypes: alertTypes, 
                                                currentType: currentType, conversationId: response.data, alertComment: commentObj
                                            }, async () => {
                                                if (this.state.conversationId !== null) {
                                                    AlertService.getconversationlist(this.state.conversationId)
                                                        .then(response2 => {
                                                            if (response2.status === 200 && response2.data !== undefined && response2.data !== null && response2.data !== false) {
                                                                let typeString = currentType + " - ";
                                                                let assetConvo = response2.data;//.filter(c => c.message.includes(typeString));
                                                                let typeConvo = assetConvo.filter(c => c.message.includes(typeString));
                                                                this.setState({ assetConversation: assetConvo, currentTypeConversation: typeConvo }, async () => {
                                                                });
                                                            }
                                                        })
                                                        .catch(e => {
                                                            console.log(e);
                                                        });
                                                }
                                            })
                                        }
                                        else {
                                            this.setState({ 
                                                commentDict: commentDict, assetComments: assetComments, alertTypes: alertTypes, 
                                                currentType: currentType, currentTypeConversation: [], conversationId: null, alertComment: commentObj
                                            }, async () => {

                                            })
                                        }
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            this.setState({ commentsLoading: false, loading: false })
                                        })
                            }
                            else {
                                const conversationId = assetComments[0].convoId;
                                const alertComment = assetComments[0];
                                if (assetComments.find(c => c.alertTypeId == 0) === undefined) {
                                    let commentObj = {
                                        id: 0,
                                        assetId: parseInt(assetId),
                                        alertTypeId: 0, //asset type
                                        alertTypeName: 'Asset',
                                        convoId: conversationId,
                                        hasNewComment: false,
                                        hasQuestion: false,
                                        hasAnswer: false
                                    };
                                    assetComments = assetComments.concat(commentObj);
                                }
                                this.setState({ commentDict: commentDict, assetComments: assetComments, alertTypes: alertTypes, 
                                    currentType: currentType, conversationId: conversationId, alertComment: alertComment 
                                }, () => {
                                    AlertService.getconversationlist(this.state.conversationId)
                                        .then(response3 => {
                                            if (response3.status === 200 && response3.data !== undefined && response3.data !== null && response3.data !== false) {
                                                    let typeString = this.state.currentType + " - ";
                                                    let assetConvo = response3.data;//.filter(c => c.message.includes(typeString));
                                                    let typeConvo = assetConvo.filter(c => c.message.includes(typeString));
                                                    this.setState({ assetConversation: assetConvo, currentTypeConversation: typeConvo, }, async () => {
                                                        
                                                    });
                                                    // todo remove new comment dot here?
                                            }
                                        })
                                            .catch(e => {
                                                console.log(e);
                                            });
                                })
                            }
                            
                        }
                    })
                        .catch(e => {
                            console.log(e);
                            this.setState({ loading: false });
                        })
                            
        });
            
 /*           let assetComments = this.state.assetComments;
            let alertComment = this.state.alertComment;
            if (alertComment.hasAnswer || alertComment.hasNewComment) {
                alertComment.hasNewComment = false;
                alertComment.hasAnswer = false;
                
                await commentsService.updatecomment(alertComment)
                    .then(response4 => {
                        if (response4.status === 200 && response4.data !== undefined && response4.data !== null && response4.data !== false) {
                            alertComment = response4.data;

                            let commentIndex = assetComments.indexOf(c => c.id == alertComment.id);
                            assetComments[commentIndex] = alertComment;

                            this.setState({ alertComment: alertComment, assetComments: assetComments, commentsLoading: false });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
            else {
                this.setState({ alertComment: alertComment, commentsLoading: false });
            }
        })*/
    }

    onChangeCommentAlertType(e) {
        var type = e.target.value;
        var typeString = type + ' - ';
        var typeConvo = this.state.assetConversation.filter(c => c.message.includes(typeString));
        this.setState({ currentType: type, currentTypeConversation: typeConvo });
    }

    addConversationToAsset() {

    }

    updateAlertComment(updatedComment, alertType, callAPI = true) {
        this.setState({ commentsLoading: true }, async () => {
            let dict = this.state.commentDict;
            let type = alertType != null ? alertType : this.state.currentType; 
            var assetComments = this.state.assetComments;//commentDict[type];
            var index = assetComments.findIndex(c => c.assetId === updatedComment.assetId && c.alertTypeId === updatedComment.alertTypeId);//0; 
            
            if (updatedComment.convoId == null) {
                updatedComment.convoId = this.state.conversationId !== null ? this.state.conversationId : null;
            }
            
            if (callAPI) {
                await commentsService.updatecomment(updatedComment)
                .then(response => {
                    if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                        updatedComment = response.data;

                        if (index === -1 || index < 0)
                            assetComments = assetComments.concat(updatedComment);
                        else
                            assetComments[index] = updatedComment;

                        //dict[type] = assetComments;
                        this.setState({ 
                            alertComment: updatedComment, 
                            commentDict: {},//dict
                            assetComments: assetComments
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ commentsLoading: false });
                });
            }
            else {
                if (index === -1 || index < 0)
                    assetComments = assetComments.concat(updatedComment);
                else
                    assetComments[index] = updatedComment;
                
                //dict[type] = assetComments;
                this.setState({ 
                    alertComment: updatedComment, 
                    commentDict: {},//dict,
                    assetComments: assetComments,
                    commentsLoading: false
                });
            }
        });
    }

    /**
     * begin input event handlers
     */

    onHVACSystemSelect(e) {
        if (e !== undefined){
            this.setState({
                currentHVACSystem: e
            }, async () => {
                await this.setDevices(this.state.currentHVACSystem);
            });
        }else{
            this.setState({loading: false });
        }
    }

    setTechnicianInfo() {
        
    }

    reloadAssets() {
        this.setState({
            assetLoading: true
        }, async () => {
            await AssetService.get(this.state.currentHVACSystem.assetId).then(response => {
                if (this._isMounted) {
                    let newAssetList = response.data;
                    if (newAssetList !== null && newAssetList !== undefined && newAssetList.length && this.props.hasServiceTier && this.props.serviceTier < 3)
                        newAssetList = newAssetList.filter(n => n.type.trim() === "Thermostat");
                    this.setState({
                        assetList: newAssetList, currentAsset: jsAsset, isSelected: -1, assetSelected: false, acSelected: false, thermostatSelected: false, assetLoading: false, thermDataLoaded: false, thermData: ThermostatPocData, currentThermMac: "", selectedRun: 0, selectedAssetNumber: 0
                    });
                }
            });
        });
    }

    onAssetSelect(e) {
        this.setState({
            currentAsset: this.state.assetList[e.target.value], selectedAssetNumber: parseInt( e.target.value)
        });
    }

    onUnparentedAssetSelect(e) {
        this.setState({
            unparentedAsset: this.state.unparentedAssets[e.target.value]
        });
    }

    openInfoModal() {
        Event("Asset Graph Info Click", "User clicked graph options info button", "Graph Options Info Clicked");
        this.setState({
            modalMessage: "The graph will show the runs for the asset and time period selected. If the asset is still in analytics training, the runs will show up as UNTRAINED. This means LifeWhere has not collected enough run data for the asset to apply full analytics monitoring. Once the asset is trained, LifeWhere will display BAD RUN for any runs where LifeWhere detects problems. In the meantime, check UNTRAINED runs and the Last 24 Hours tab to see the raw asset data collected from the LifeWhere devices.",
            modalTitle: "Asset Graph Info"
        }, () => {
            this.showInfoModal();
        });
    }

    showInfoModal() {
        this.setState({
            showInfoModal: !this.state.showInfoModal
        }, () => {
                if (this.state.showInfoModal)
                    ModalView("graphInfo");
        });
        window.scrollTo(0, 0);
    }

    openFavoriteModal() {
        this.setState({ modalMessage: "Are you sure you wish to add this asset to your favorites?", modalTitle: "Add Asset to Favorites" }, () => {
            this.showFavoriteModal();
        });
    }

    showFavoriteModal() {
        this.setState({
            showFavoriteModal: !this.state.showFavoriteModal
        }, () => {
            if (this.state.showFavoriteModal)
                ModalView("assetFavorite");
        });
        window.scrollTo(0, 0);
    }

    updateAssetFavorites() {

    }

    //TEST SYSTEM SECTION:

    onTestSystemClick() {
        this.openTestSystemModal();
    }

    openTestSystemModal() {
        this.showTestSystemModal();
    }

    showTestSystemModal() {
        this.setState({
            showTestSystem: !this.state.showTestSystem
        }, () => {
            if (this.state.showTestSystem)
                ModalView("testSystemModal");
        });
        window.scrollTo(0, 0);
    }

    onSystemSelect(e) {
        const newSystemId = e.target.value;

        this.setState({selectedSystemId: newSystemId});
    }

    async onRunRoutine() {
        Event("Submit System Test Clicked", "User clicked submit in the assets Test System Modal", "Submit System Test Clicked");
        this.setState({ systemTestLoading: true, systemTestResult: "" }, async () => {
            let currentTherm = this.state.assetList.find(a => a.type.trim() === "Thermostat");
            let currThermMac = currentTherm.mac.split('-')[0];

            if (this.state.currentThermMac !== "" && this.state.currentThermMac === currThermMac && this.state.thermDataLoaded) {
                await ThermostatService.getdeviceinfo(this.state.thermData.apiKey, this.state.thermData.deviceId, this.state.thermData.locationId)
                    .then(response2 => {
                        var devInfo = response2.data;
                        var devVals = devInfo !== null && devInfo !== undefined && devInfo.changeableValues !== undefined ? devInfo.changeableValues : {};
                        let prevSetpoint = {
                            mode: devVals.mode,
                            heatSetpoint: devVals.heatSetpoint,
                            coolSetpoint: devVals.coolSetpoint,
                            thermostatSetpointStatus: devVals.thermostatSetpointStatus,
                            autoChangeoverActive: devVals.autoChangeoverActive,
                            nextPeriodTime: devVals.nextPeriodTime,
                            heatCoolMode: devVals.heatCoolMode
                        };
                        this.setState({
                            previousSetpoint: prevSetpoint
                        }, async () => {
                                let updateSP = this.state.thermData.updateSP;
                                let selectedAsset = this.state.assetList.find(a => a.assetId === this.state.selectedSystemId);
                                let selectedAssetType = selectedAsset !== undefined ? selectedAsset.type.trim() : this.state.assetList.filter(a => a.type.trim() !== "Thermostat")[0].type; //"AirConditioner";
                                
                                if (selectedAssetType === "AirConditioner") {
                                    updateSP.mode = "Cool";
                                    updateSP.heatCoolMode = "Cool";
                                }
                                    
                            await ThermostatService.updateSetpoint(this.state.thermData.apiKey, this.state.thermData.deviceId, this.state.thermData.locationId, updateSP)
                                .then(response3 => {
                                    var test3 = response3.data;
                                    if (test3) {
                                        setTimeout(() => {
                                            this.setState({
                                                systemTestResult: "Success",
                                                systemTestLoading: false
                                            }, async () => {
                                                await ThermostatService.updateSetpoint(this.state.thermData.apiKey, this.state.thermData.deviceId, this.state.thermData.locationId, this.state.previousSetpoint)
                                                    .then(response4 => {
                                                        if (response4.data) {
                                                            this.setState({ systemTestResult: "Success, returned to original setpoint" });
                                                        }
                                                        else {
                                                            this.setState({ systemTestResult: "Update succeeded, failed to return to original setpoint" });
                                                        }

                                                        setTimeout(() => {
                                                            this.setState({
                                                                systemTestResult: ""
                                                            });
                                                        }, 3000);
                                                    })
                                                    .catch(e => {
                                                        console.log(e);
                                                    });
                                            })
                                        }, 60000);
                                    }
                                    else {
                                        this.setState({
                                            systemTestResult: "Failure",
                                            systemTestLoading: false,
                                            showTestSystem: !this.state.showTestSystem
                                        });

                                        setTimeout(() => {
                                            this.setState({
                                                systemTestResult: ""
                                            });
                                        }, 3000);
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        });
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
            else {
                await ThermostatService.getlocations(this.state.thermData.apiKey)
                    .then(response => {
                        var locations = response.data;

                        if (locations !== null && locations !== undefined && locations.length) {
                            let testDev = locations[0].devices.find(d => d.macID === currThermMac);
                            this.setState(prevState => ({
                                currentThermMac: currThermMac,
                                thermDataLoaded: true,
                                thermData: {
                                    ...prevState.thermData,
                                    locationId: locations[0].locationID,
                                    deviceId: testDev.deviceID,
                                }
                            }), async () => {
                                    await ThermostatService.getdeviceinfo(this.state.thermData.apiKey, this.state.thermData.deviceId, this.state.thermData.locationId)
                                        .then(response2 => {
                                            var devInfo = response2.data;
                                            var devVals = devInfo !== null && devInfo !== undefined && devInfo.changeableValues !== undefined ? devInfo.changeableValues : {};
                                            let prevSetpoint = {
                                                mode: devVals.mode,
                                                heatSetpoint: devVals.heatSetpoint,
                                                coolSetpoint: devVals.coolSetpoint,
                                                thermostatSetpointStatus: devVals.thermostatSetpointStatus,
                                                autoChangeoverActive: devVals.autoChangeoverActive,
                                                nextPeriodTime: devVals.nextPeriodTime,
                                                heatCoolMode: devVals.heatCoolMode
                                            };
                                            this.setState({
                                                previousSetpoint: prevSetpoint
                                            }, async () => {
                                                    let updateSP = this.state.thermData.updateSP;
                                                    let selectedAsset = this.state.assetList.find(a => a.assetId === this.state.selectedSystemId);
                                                    let selectedAssetType = selectedAsset !== undefined ? selectedAsset.type.trim() : this.state.assetList.filter(a => a.type.trim() !== "Thermostat")[0].type; //"AirConditioner";
                                                    
                                                    if (selectedAssetType === "AirConditioner") {
                                                        updateSP.mode = "Cool";
                                                        updateSP.heatCoolMode = "Cool";
                                                    }
                                                await ThermostatService.updateSetpoint(this.state.thermData.apiKey, this.state.thermData.deviceId, this.state.thermData.locationId, updateSP)
                                                    .then(response3 => {
                                                        var test3 = response3.data;
                                                        if (test3) {
                                                            setTimeout(() => {
                                                                this.setState({
                                                                    systemTestResult: "Success",
                                                                    systemTestLoading: false
                                                                }, async () => {
                                                                    await ThermostatService.updateSetpoint(this.state.thermData.apiKey, this.state.thermData.deviceId, this.state.thermData.locationId, this.state.previousSetpoint)
                                                                        .then(response4 => {
                                                                            if (response4.data) {
                                                                                this.setState({ systemTestResult: "Success, returned to original setpoint" });
                                                                            }
                                                                            else {
                                                                                this.setState({ systemTestResult: "Update succeeded, failed to return to original setpoint" });
                                                                            }

                                                                            setTimeout(() => {
                                                                                this.setState({
                                                                                    systemTestResult: ""
                                                                                });
                                                                            }, 8000);
                                                                        })
                                                                        .catch(e => {
                                                                            console.log(e);
                                                                        });
                                                                })
                                                            }, 60000);
                                                        }
                                                        else {
                                                            this.setState({
                                                                systemTestResult: "Failure",
                                                                systemTestLoading: false,
                                                                showTestSystem: !this.state.showTestSystem
                                                            });

                                                            setTimeout(() => {
                                                                this.setState({
                                                                    systemTestResult: ""
                                                                });
                                                            }, 8000);
                                                        }
                                                    })
                                                    .catch(e => {
                                                        console.log(e);
                                                    });
                                            });
                                        })
                                        .catch(e => {
                                            console.log(e);
                                        });
                            });
                        }

                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        })

        
    }
    //TEST SYSTEM SECTION END

    onTurnOffSystemClick() {
        this.openTurnOffSystemModal();
    }

    openTurnOffSystemModal() {
        this.showTurnOffSystemModal();
    }

    showTurnOffSystemModal() {
        this.setState({
            showTurnOffSystem: !this.state.showTurnOffSystem
        }, () => {
                if (this.state.showTurnOffSystem)
                    ModalView("turnOffSystemModal");
        });
    }

    onTurnOffSystem() {
        Event("System Turned Off", "User turned off system in Assets page", "System Turned Off");
        this.setState({ systemTurnOffLoading: true }, async () => {
            let currentTherm = this.state.assetList.find(a => a.type.trim() === "Thermostat");
            let currThermMac = currentTherm.mac.split('-')[0];

            if (this.state.currentThermMac !== "" && this.state.currentThermMac === currThermMac && this.state.thermDataLoaded) {
                let prevSetpoint = this.state.previousSetpoint;
                prevSetpoint.mode = "Off";
                await ThermostatService.updateSetpoint(this.state.thermData.apiKey, this.state.thermData.deviceId, this.state.thermData.locationId, prevSetpoint)
                    .then(response => {
                        var updateResult = response.data;
                        this.setState({
                            showTurnOffMessage: true,
                            turnOffSuccess: updateResult ? true : false,
                            turnOffMessage: updateResult ? "Successfully turned off HVAC System!" : "Failed to turn off HVAC System",
                            systemTurnOffLoading: false,
                            showTurnOffSystem: false
                        });

                        setTimeout(() => {
                            this.setState({
                                showTurnOffMessage: false,
                                turnOffMessage: ""
                            });
                        }, 6000);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
            else {
                await ThermostatService.getlocations(this.state.thermData.apiKey)
                    .then(response => {
                        var locations = response.data;

                        if (locations !== null && locations !== undefined && locations.length) {
                            let testDev = locations[0].devices.find(d => d.macID === currThermMac);
                            this.setState(prevState => ({
                                currentThermMac: currThermMac,
                                thermDataLoaded: true,
                                thermData: {
                                    ...prevState.thermData,
                                    locationId: locations[0].locationID,
                                    deviceId: testDev.deviceID,
                                }
                            }), async () => {
                                let prevSetpoint = this.state.thermData.updateSP;
                                    prevSetpoint.mode = "Off";
                                    await ThermostatService.updateSetpoint(this.state.thermData.apiKey, this.state.thermData.deviceId, this.state.thermData.locationId, prevSetpoint)
                                        .then(response => {
                                            var updateResult = response.data;
                                            this.setState({
                                                showTurnOffMessage: true,
                                                turnOffSuccess: updateResult ? true : false,
                                                turnOffMessage: updateResult ? "Successfully turned off HVAC System!" : "Failed to turn off HVAC System",
                                                systemTurnOffLoading: false,
                                                showTurnOffSystem: false
                                            });

                                            setTimeout(() => {
                                                this.setState({
                                                    showTurnOffMessage: false,
                                                    turnOffMessage: ""
                                                });
                                            }, 6000);
                                        })
                                        .catch(e => {
                                            console.log(e);
                                        });
                            });
                        }

                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        });
    }
    //TURN OFF SYSTEM END

    async onChangeRun(e) {
        Event("Run Dropdown Click", "User selected a run from the dropdown", "Run Drowndown Clicked");
        let index = parseInt(e.target.value);
        let canLeft = index > 0 ? true : false;
        let canRight = index < this.state.numRuns - 1 ? true : false;

        //todo switch graph data depending on if AC or not

        this.setState({
            currentRun: this.state.graphData.equipmentRuns[index],
            currentRunName: this.state.graphData.runNamesList[index],
            currentSource: this.state.graphData.timeSeriesDataSources[index],
            currentHighchartSource: this.state.graphData.highchartsDataSources[index],
            currentPredictions: this.state.acSelected ? this.state.graphData.predictions[index] : null,
            currentMax: this.state.acSelected ? this.state.graphData.runMaxes[index] : 0,
            clearPreviusState: true,
            selectedRun: index,
            canLeftClick: canLeft,
            canRightClick: canRight
        }, () => {
                var runChar = this.state.currentRunName.charAt(0);
                if (runChar === 'g' || runChar === 'r')
                    this.setState({ untrainedRun: false });
                else
                    this.setState({ untrainedRun: true });
        });
    }

    async onLeftRunClick() {
        Event("Run Left Click", "User clicked left arrow to change run", "Left Run Clicked");
        let index = this.state.selectedRun - 1;
        let canLeft = index > 0 ? true : false;
        let canRight = index < this.state.numRuns - 1 ? true : false;

        this.setState({
            currentRun: this.state.graphData.equipmentRuns[index],
            currentRunName: this.state.graphData.runNamesList[index],
            currentSource: this.state.graphData.timeSeriesDataSources[index],
            currentHighchartSource: this.state.graphData.highchartsDataSources[index],
            currentPredictions: this.state.acSelected ? this.state.graphData.predictions[index] : null,
            currentMax: this.state.acSelected ? this.state.graphData.runMaxes[index] : 0,
            clearPreviusState: true,
            selectedRun: index,
            canLeftClick: canLeft,
            canRightClick: canRight
        }, () => {
            var runChar = this.state.currentRunName !== undefined && this.state.currentRunName !== null ? this.state.currentRunName.charAt(0) : '';
            if (runChar === 'g' || runChar === 'r')
                this.setState({ untrainedRun: false });
            else
                this.setState({ untrainedRun: true });
        });
    }

    async onRightRunClick() {
        Event("Run Right Click", "User clicked right arrow to change run", "Right Run Clicked");
        let index = this.state.selectedRun + 1;
        let canLeft = index > 0 ? true : false;
        let canRight = index < this.state.numRuns - 1 ? true : false;

        this.setState({
            currentRun: this.state.graphData.equipmentRuns[index],
            currentRunName: this.state.graphData.runNamesList[index],
            currentSource: this.state.graphData.timeSeriesDataSources[index],
            currentHighchartSource: this.state.graphData.highchartsDataSources[index],
            currentPredictions: this.state.acSelected ? this.state.graphData.predictions[index] : null,
            currentMax: this.state.acSelected ? this.state.graphData.runMaxes[index] : 0,
            clearPreviusState: true,
            selectedRun: index,
            canLeftClick: canLeft,
            canRightClick: canRight
        }, () => {
            var runChar = this.state.currentRunName !== undefined && this.state.currentRunName !== null ? this.state.currentRunName.charAt(0) : '';
            if (runChar === 'g' || runChar === 'r')
                this.setState({ untrainedRun: false });
            else
                this.setState({ untrainedRun: true });
        });

    }

    /*CurrentAsset Change*/   
    onChangeAssetType(e) {
        const type = e.target.value;

        this.setState(prevState => ({
            currentAsset: {
                ...prevState.currentAsset,
                type: type
            }
        }));
    }

    onChangeNickName(e) {
        const nickname = e.target.value;

        this.setState(prevState => ({
            currentAsset: {
                ...prevState.currentAsset,
                nickname: nickname
            }
        }));
    }

    onChangeMake(e) {
        const make = e.target.value;

        this.setState(prevState => ({
            currentAsset: {
                ...prevState.currentAsset,
                make: make
            }
        }));
    }

    onChangeModel(e) {
        const model = e.target.value;

        this.setState(prevState => ({
            currentAsset: {
                ...prevState.currentAsset,
                model: model
            }
        }));
    }

    onChangeSerialNumber(e) {
        const serialnumber = e.target.value;

        this.setState(prevState => ({
            currentAsset: {
                ...prevState.currentAsset,
                serialNumber: serialnumber
            }
        }));
    }

    onChangeMFGDate(e) {
        const date = e.target.value;

        this.setState(prevState => ({
            currentAsset: {
                ...prevState.currentAsset,
                mfgDate: date
            }
        }));
    }

    onChangeCompressorRla(e) {
        const newValue = e.target.value;

        this.setState(prevState => ({
            currentAsset: {
                ...prevState.currentAsset,
                compressorRatedLoadAmps: newValue
            }
        }));
    }
    onChangeCompressorLra(e) {
        const newValue = e.target.value;

        this.setState(prevState => ({
            currentAsset: {
                ...prevState.currentAsset,
                compressorLockedRotorAmps: newValue
            }
        }));
    }
    onChangeFanFla(e) {
        const newValue = e.target.value;

        this.setState(prevState => ({
            currentAsset: {
                ...prevState.currentAsset,
                fanFullLoadAmps: newValue
            }
        }));
    }

    onChangeFanLra(e) {
        const newValue = e.target.value;

        this.setState(prevState => ({
            currentAsset: {
                ...prevState.currentAsset,
                fanLockedRotorAmps: newValue
            }
        }));
    }

    onChangeStart(e) {
        var start = moment(e).toDate();
        var startStr = moment(e).utc().format();
        this.setState({ dpStartDateTime: start, startDateTimeStrPlus: startStr });
    }

    onChangeEnd(e) {
        var end = moment(e).toDate();
        var endStr = moment(e).utc().format();
        this.setState({ dpEndDateTime: end, endDateTimeStrPlus: endStr });
    }

    handleCheckbox(e) {
        const checkedVal = e.target.value;

        if (e.target.checked) {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: true
                }
            }));
        } 
        else {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: false
                }
            }));
        }
    }

    handleCTCheckbox(e) {
        const checkedVal = e.target.value;

        if (e.target.checked) {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: true
                }
            }));
        }
        else {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: false
                }
            }));
        }
    }

    async onViewRuns() {

        var startTime = performance.now();
        Event("Asset View Runs Click", "User clicked view runs button", "View Runs Clicked");
        this.setState({ assetLoading: true, selectedRun: 0 }, async () => {
            if (!this.state.acSelected && !this.state.thermostatSelected) {
                await AlertService.getrundata(this.state.startDateTimeStrPlus, this.state.endDateTimeStrPlus, this.state.currentAsset)
                    .then(response => {
                        if (response.status === 200 && this._isMounted) {
                                this.setState({ graphData: response.data }, async () => {
                                    if (this.state.graphData.equipmentRuns !== null) {
                                        this.setState({
                                            currentRun: this.state.graphData.equipmentRuns[0],
                                            currentRunName: this.state.graphData.runNamesList[0],
                                            currentSource: this.state.graphData.timeSeriesDataSources[0],
                                            currentHighchartSource: this.state.graphData.highchartsDataSources[0],
                                            currentTimeline: this.state.graphData.highchartsTimeseries,
                                            currentPredictions: null,
                                            currentMax: 0,
                                            runList: this.state.graphData.equipmentRuns,
                                            numRuns: this.state.graphData.timeSeriesDataSources.length,
                                            canRightClick: this.state.graphData.timeSeriesDataSources.length > 1,
                                            canLeftClick: false,
                                            assetLoading: false,
                                            graphConstants: this.state.graphData.constantValues
                                        }, () => {
                                            var elapsedTime = performance.now() - startTime;
                                            Timing("New Run List Loading", "assetLoading", elapsedTime, "New Run List Loading");
                                        });
                                    }
                                    else {
                                        this.setState({ assetLoading: false });
                                    }
                                });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ assetLoading: false });
                    });
            }
            else if (this.state.acSelected) {
                await AlertService.getacrundata(this.state.startDateTimeStrPlus, this.state.endDateTimeStrPlus, this.state.isSelected, this.state.assetList)
                    .then(response => {
                        if (response.status === 200 && this._isMounted) {
                            let processedData = this.processACData(response.data);
                            
                                this.setState({ graphDataAC: processedData, graphData: processedData }, async () => {
                                    if (this.state.graphDataAC.equipmentRuns !== null) {
                                        this.setState({
                                            currentRun: this.state.graphDataAC.equipmentRuns[0],
                                            currentRunName: this.state.graphDataAC.runNamesList[0],
                                            currentSource: this.state.graphDataAC.timeSeriesDataSources[0],
                                            currentHighchartSource: this.state.graphData.highchartsDataSources[0],
                                            currentTimeline: this.state.graphData.highchartsTimeseries,
                                            currentPredictions: this.state.graphDataAC.predictions[0],
                                            currentMax: this.state.graphData.runMaxes[0],
                                            runList: this.state.graphDataAC.equipmentRuns,
                                            numRuns: this.state.graphDataAC.timeSeriesDataSources.length,
                                            canRightClick: this.state.graphData.timeSeriesDataSources.length > 1,
                                            canLeftClick: false,
                                            assetLoading: false,
                                            graphConstants: {}
                                        }, () => {
                                            var elapsedTime = performance.now() - startTime;
                                            Timing("New Run List Loading", "assetLoading", elapsedTime, "New Run List Loading");

                                            let rlaMin = processedData.rla - 5;
                                            if (this.state.currentMax >= rlaMin)
                                                this.setState(prevState => ({
                                                    visibility: {
                                                        ...prevState.visibility,
                                                        rla: true
                                                    }
                                                }));
                                        });
                                    }
                                    else {
                                        this.setState({ assetLoading: false });
                                    }
                                });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ assetLoading: false });
                    });
            }
            else if (this.state.thermostatSelected) {
                await AlertService.getthermostatdata(this.state.startDateTimeStrPlus, this.state.endDateTimeStrPlus, this.state.currentAsset)
                    .then(response => {
                        if (this._isMounted && response.status === 200)
                            this.setState({ graphData: response.data }, async () => {
                                this.setState({
                                    currentRun: null,
                                    currentRunName: "",
                                    currentSource: jsSource,
                                    currentHighchartSource: {},
                                    currentTimeline: this.state.graphData.highchartsTimeseries,
                                    currentPredictions: null,
                                    currentMax: 0,
                                    runList: [],
                                    numRuns: 0,
                                    canRightClick: false,
                                    canLeftClick: false,
                                    assetLoading: false,
                                    graphConstants: {}
                                }, () => {
                                    var elapsedTime = performance.now() - startTime;
                                    Timing("New Run List Loading", "assetLoading", elapsedTime, "New Run List Loading");
                                });
                            });
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ assetLoading: false });
                    });
            }
        })
    }

    onChangeGraph(index) {
        let x = index;
        var type = "";
        //var disableBtns = false;

        if (x === 0) {
            type = "Graph";
        }
        else if (x === 1) {
            type = "Timeline";
            //disableBtns = true;
        }

        this.setState({ tabIndex: x, graphType: type });
    }

    showGraphAnnotations(e) {
        Event("Asset Show Analytics", "User clicked show analytics button in assets screen", "Show Asset Graph Analytics");
        this.setState(prevState => ({
            visibility: {
                ...prevState.visibility,
                analytics: !this.state.visibility.analytics,
                annotations: !this.state.visibility.annotations
            }
        }))
    }

    async onAssetClick(index) {
        let x = index;
        let startTime = performance.now();
        Event("Asset Click", "User clicked on an asset in the table", "Customer Asset Clicked");
        let assets = this.state.assetList.length > 0 ? this.state.assetList[x] : this.state.assetList[x];
        let visibility = this.state.visibility;
        visibility.analytics = false;
        visibility.annotations = false;
        this.setState({
            currentAsset: assets, currentSource: jsSource, currentHighchartSource: {}, currentTimeline: {}, currentPredictions: null, isSelected: x, assetLoading: true,
            assetSelected: true, selectedRun: 0, visibility: visibility
        }, async () => {
            await this.getAssetData(x, startTime);
        });
    }

    async getAssetData(x, startTime) {
        if (this.state.currentAsset.type.trim() !== "AirConditioner" && this.state.currentAsset.type.trim() !== "HeatPump" && this.state.currentAsset.type.trim() !== "Thermostat") {
            this.setState({ acSelected: false, thermostatSelected: false });
            await AlertService.getrundata(this.state.startDateTimeStrPlus, this.state.endDateTimeStrPlus, this.state.currentAsset)
                .then(response => {
                    if (response.status === 200 && this._isMounted) {
                        this.setState({ graphData: response.data, graphDataAC: response.data }, async () => {
                            try {
                                if (this.state.graphData.equipmentRuns !== null) {
                                        this.setState({
                                            currentRun: this.state.graphData.equipmentRuns[0],
                                            currentRunName: this.state.graphData.runNamesList[0],
                                            currentSource: this.state.graphData.timeSeriesDataSources[0],
                                            currentHighchartSource: this.state.graphData.highchartsDataSources !== undefined && this.state.graphData.highchartsDataSources !== null ? this.state.graphData.highchartsDataSources[0] : {},
                                            currentTimeline: this.state.graphData.highchartsTimeseries,
                                            currentPredictions: null,
                                            currentMax: 0,
                                            runList: this.state.graphData.equipmentRuns,
                                            numRuns: this.state.graphData.timeSeriesDataSources.length,
                                            canRightClick: this.state.graphData.timeSeriesDataSources.length > 1,
                                            canLeftClick: false,
                                            graphConstants: this.state.graphData.constantValues
                                        }, async () => {
                                            this.setState({
                                                untrainedRun: this.state.currentRunName !== undefined ? (this.state.currentRunName.charAt(0) === 'g' ? false : this.state.currentRunName.charAt(0) === 'r' ? false : true) : false,
                                                timelineGraph: this.state.graphData,
                                                timelineData: this.state.graphData.timeseries,
                                                timelineDataAC: jsACGraphData,
                                                timelineDataAnnotated: this.state.graphData.timeseries,
                                                timelineAnnotations: this.state.graphData.timeseries !== null ? this.state.graphData.timeseries.graphAnnotations : [],
                                                timelineArrowAnnotations: this.state.graphData.timeseries !== null ? this.state.graphData.timeseries.graphArrowAnnotations : [],
                                                assetLoading: false
                                            });
                                        });
                                    }
                                    else {
                                        this.setState({
                                            timelineGraph: this.state.graphData,
                                            timelineData: this.state.graphData.timeseries,
                                            timelineDataAC: jsACGraphData,
                                            timelineDataAnnotated: this.state.graphData.timeseries,
                                            timelineAnnotations: this.state.graphData.timeseries !== null ? this.state.graphData.timeseries.graphAnnotations : [],
                                            timelineArrowAnnotations: this.state.graphData.timeseries !== null ? this.state.graphData.timeseries.graphArrowAnnotations : [],
                                            assetLoading: false
                                        });
                                    }
                            }
                            catch (e) {
                                console.log(e);
                                this.setState({ assetLoading: false });
                            };

                        });
                    }
                    else {
                        this.setState({ assetLoading: false });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ assetLoading: false });
                });
        }
        else if (this.state.currentAsset.type.trim() === Equipment.AIR_CONDITIONER || this.state.currentAsset.type.trim() === Equipment.HEAT_PUMP) {
            /* AC AND HEAT PUMP GRAPH STUFF HERE */

            this.setState({ acSelected: true, thermostatSelected: false, graphData: jsACAssetsGraphData })
            await AlertService.getacrundata(this.state.startDateTimeStrPlus, this.state.endDateTimeStrPlus, x, this.state.assetList)
                .then(response => {
                    if (response.status === 200 && this._isMounted) {
                        var processedData = this.processACData(response.data);
                            this.setState({ graphDataAC: processedData, graphData: processedData }, async () => {
                                if (this.state.graphDataAC.equipmentRuns !== null) {
                                    this.setState({
                                        currentRun: this.state.graphDataAC.equipmentRuns[0],
                                        currentRunName: this.state.graphDataAC.runNamesList[0],
                                        currentSource: this.state.graphDataAC.timeSeriesDataSources[0],
                                        currentHighchartSource: this.state.graphData.highchartsDataSources[0],
                                        currentTimeline: this.state.graphData.highchartsTimeseries,
                                        currentPredictions: this.state.graphDataAC.predictions !== undefined ? this.state.graphDataAC.predictions[0] : null,
                                        currentMax: this.state.graphData.runMaxes[0],
                                        runList: this.state.graphDataAC.equipmentRuns,
                                        numRuns: this.state.graphDataAC.timeSeriesDataSources.length,
                                        canRightClick: this.state.graphData.timeSeriesDataSources.length > 1,
                                        canLeftClick: false,
                                        graphConstants: {}
                                    }, async () => {
                                        let visibility = this.state.visibility;
                                        if (this.state.acSelected) {
                                            let rlaMin = processedData.rla - 5;
                                            if (this.state.currentMax >= rlaMin)
                                                visibility.rla = true;
                                        }
                                        this.setState({
                                            untrainedRun: this.state.currentRunName !== undefined ? (this.state.currentRunName.charAt(0) === 'g' ? false : this.state.currentRunName.charAt(0) === 'r' ? false : true) : false,
                                            timelineGraphAC: this.state.graphDataAC,
                                            timelineData: this.state.graphDataAC.timeSeries,
                                            timelineDataAC: this.state.graphDataAC.timeSeries,
                                            timelineDataAnnotated: this.state.graphDataAC.timeSeries,
                                            timelineAnnotations: this.state.graphDataAC.timeSeries !== null && this.state.graphDataAC.timeSeries !== undefined && this.state.graphDataAC.timeSeries.graphAnnotations !== undefined ? this.state.graphDataAC.timeSeries.graphAnnotations : [],
                                            timelineArrowAnnotations: this.state.graphDataAC.timeSeries !== null ? this.state.graphDataAC.timeSeries.graphArrowAnnotations : [],
                                            assetLoading: false,
                                            visibility: visibility
                                        });
                                    });
                                }
                            });
                    }
                    else {
                        this.setState({ assetLoading: false });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ assetLoading: false });
                });
        }
        else if (this.state.currentAsset.type.trim() === Equipment.THERMOSTAT) {
            this.setState({ acSelected: false, thermostatSelected: true, graphData: jsACAssetsGraphData, graphConstants: {} });
            await AlertService.getthermostatdata(this.state.startDateTimeStrPlus, this.state.endDateTimeStrPlus, this.state.currentAsset)
                .then(response => {
                    if (this._isMounted && response.status === 200)
                        this.setState({ graphData: response.data }, async () => {
                            this.setState({
                                currentRun: null,
                                currentRunName: "",
                                currentSource: jsSource,
                                currentHighchartSource: {},
                                currentTimeline: this.state.graphData.highchartsTimeseries,
                                currentPredictions: null,
                                currentMax: 0,
                                runList: [],
                                numRuns: 0,
                                canRightClick: false,
                                canLeftClick: false,
                                assetLoading: false,
                                graphConstants: {}
                            }, () => {
                                var elapsedTime = performance.now() - startTime;
                                Timing("New Run List Loading", "assetLoading", elapsedTime, "New Run List Loading");
                            });
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        }

        await AssetService.getappliedclasses(this.state.currentAsset.assetId)
            .then(response => {
                this.setState({ appliedClasses: response.data }, () => {
                    let isTraining = this.state.appliedClasses != [] && this.state.appliedClasses.length && this.state.appliedClasses.findIndex(c => c.name === "Training") > -1 ? true : false; // get if asset in in Training or not for messaging.
                    this.setState({ isTraining: isTraining });
                });
            })
            .catch(e => {
                console.log(e);
            });
    }
    /**
     * End input event handlers
     */

    processACData(respData) {
        let data = {};
        let runNamesList = [];
        let equipmentRuns = {};
        let dataSources = [];
        //let timeseries = {};
        let hcDataSources = [];
        let runMaxes = [];
        let predicts = [];

        data.deviceId = respData.DeviceIdAc;
        data.deviceIdFurnace = respData.DeviceIdFurnace;
        data.endTime = respData.EndTime;
        data.endTimeStr = respData.EndTimeStr;
        data.startTime = respData.StartTime;
        data.startTimeStr = respData.StartTimeStr;
        data.furnaceMac = respData.FurnaceMac;
        data.peerType = respData.PeerType;
        data.offHigh = respData.OffHigh;
        data.offHighAc = respData.OffHighAc;
        data.timeStr = respData.TimeStr;
        data.lra = respData.Lra;
        data.rla = respData.Rla;

        let hcData = this.getHighchartsACDataSource(respData.TimeSeries.DataArray);
        
        let dataArray = respData.TimeSeries.DataArray.map((point) => (
            {
                Timestamp: point["Timestamp"],
                Value: point["Value"],
                FurnaceValue: point["furnace_current"],
                IndoorTemp: point["furnace_temp"],
                OutdoorTemp: point["ac_temp"],
                ac_current: point["Value"],
                ac_temp: point["ac_temp"],
                furnace_current: point["furnace_current"],
                furnace_temp: point["furnace_temp"],
                battery_voltage: point["battery_voltage"]
            }
        ));

        let timeSeries = {
            count: 0,
            dataArray: dataArray,
            dataSource: null,
            maxX: 0,
            maxY: respData.TimeSeries.MaxY,
            minX: 0,
            minY: 0,
            propertiesForAnalytics: respData.TimeSeries.propertiesForAnalytics,
            timeString: respData.TimeStr,
            graphAnnotations: respData.TimeSeries.graphAnnotations,
            graphArrowAnnotations: respData.TimeSeries.graphArrowAnnotations,
            predictions: []
        };

        for (let i = 0; i < respData.TimeSeriesDataSources.length; i++) {
            let currentSrc = respData.TimeSeriesDataSources[i];
            let currentName = currentSrc.Name;
            let srcData = dataArray.slice(currentSrc.DataRange[0], currentSrc.DataRange[1]);
            let currentPredicts = respData.Predictions[i];

            runNamesList = runNamesList.concat(currentName);
            equipmentRuns[currentName] = srcData;

            let newSrc = {
                count: 0,
                dataArray: srcData,
                dataSource: null,
                maxX: currentSrc.DataRange[1],
                maxY: respData.TimeSeries.MaxY,
                minX: 0,
                minY: 0,
                propertiesForAnalytics: respData.TimeSeries.propertiesForAnalytics,
                timeString: respData.TimeStr,
                graphAnnotations: currentSrc.GraphAnnotations,
                graphArrowAnnotations: currentSrc.GraphArrowAnnotations,
                predictions: currentPredicts
            };
            dataSources = dataSources.concat(newSrc);
            runMaxes = runMaxes.concat(newSrc.maxY);

            let newHcSrc = {
                data: hcData.data.slice(currentSrc.DataRange[0], currentSrc.DataRange[1]),
                furnaceData: hcData.furnaceData.slice(currentSrc.DataRange[0], currentSrc.DataRange[1]),
                indoorTempData: hcData.indoorTempData.slice(currentSrc.DataRange[0], currentSrc.DataRange[1]),
                outdoorTempData: hcData.outdoorTempData.slice(currentSrc.DataRange[0], currentSrc.DataRange[1]),
                batteryData: hcData.batteryData.slice(currentSrc.DataRange[0], currentSrc.DataRange[1])
            };
            hcDataSources = hcDataSources.concat(newHcSrc);
        }

        data.equipmentRuns = equipmentRuns;
        data.runNamesList = runNamesList;
        data.highchartsDataSources = hcDataSources;
        data.highchartsTimeseries = hcData;
        data.timeSeriesDataSources = dataSources;
        data.timeSeries = timeSeries;
        data.runMaxes = runMaxes;
        data.predictions = respData.Predictions;
        
        return data;
    }

    getHighchartsACDataSource(data) {
        let hcDataSrc = {};
        let hcData = [];
        let furnaceData = [];
        let indoorTempData = [];
        let outdoorTempData = [];
        let batteryData = [];

        for (let i = 0; i < data.length; i++) {
            let point = data[i];

            let dataPoint = [point["Timestamp"], point["Value"]];
            hcData[i] = dataPoint;

            let furnPoint = [point["Timestamp"], point["furnace_current"]];
            furnaceData[i] = furnPoint; 

            let indoorPoint = [point["Timestamp"], point["furnace_temp"]];
            indoorTempData[i] = indoorPoint; 

            let outdoorPoint = [point["Timestamp"], point["ac_temp"]];
            outdoorTempData[i] = outdoorPoint; 

            let batteryPoint = [point["Timestamp"], point["battery_voltage"]];
            batteryData[i] = batteryPoint;
        }

        hcDataSrc = {
            blowerData: null, coolSetpointData: null, heatSetpointData: null, humidityData: null, inducerData: null, name: null,
            data: hcData,
            furnaceData: furnaceData,
            indoorTempData: indoorTempData,
            outdoorTempData: outdoorTempData,
            batteryData: batteryData
        };

        return hcDataSrc;
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    getRunName(run) {
        let status = run.charAt(0) === 'g' ? "" : run.charAt(0) === 'y' ? "" : run.charAt(0) === 'r' ? "BAD RUN: " : "";
        let firstChar = run.charAt(0);
        let date = "";
        if (firstChar >= 0 && firstChar <= 9)
            date = run;
        else
            date = run.substr(1);

        let name = moment(date, 'MM/DD/YYYY h:mm:ss a').add(moment().utcOffset(), "minutes");
        
        let dateTimeName = `${status} ${name.format("l h:mm:ss A")}`;
        return dateTimeName;
    }

    getTimestring(run) {
        let runTimestring = "";

        if (run !== "" && run !== undefined && run != null) {
            let firstChar = run.charAt(0);
            let date = "";
            if (firstChar >= 0 && firstChar <= 9) {
                date = run;
            }
            else {
                date = run.substr(1);
            }

            runTimestring = moment(date).format("dddd, D MMMM YYYY");
        }

        return runTimestring;
    }

    getDisableAnalytics() {
        let runChar = ' ';
        if (this.state.currentRunName !== "" && this.state.currentRunName !== null && this.state.currentRunName !== undefined) {
            runChar = this.state.currentRunName.charAt(0);
        }

        if (runChar !== 'g' && runChar !== 'r') {
            return true;
        }
        else if (!this.state.assetSelected) {
            return true;
        }
        else if (this.state.disableGraphOptions) {
            return true;
        }
        else {
            return false;
        }
    }

    test(isClear) {
        this.setState({
            clearPreviusState: isClear
        });
    }
    
    getDataObj(name, dataArr, color, pointStart, yAxis = 0) {
        return {
            name: name,
            data: dataArr,
            color: color,
            type: 'line',
            zIndex: 2,
            pointStart: pointStart,
            pointInterval: 1000,
            yAxis: yAxis
        };
    }
    
    getDashedDataObj(name, value, color, pointStartMoment, pointLastMoment, isDashed = true) {
        let dataObj = {
            name: name,
            data: [[pointStartMoment, value], {
                x: pointLastMoment,
                y: value,
                dataLabels: {enabled: true, format: name}
            }],
            color: color,
            type: 'line',
            zIndex: 2,
            yAxis: 0,
            visible: true
        };
        if (isDashed) {
            dataObj.dashStyle = 'longDash'
        }
        return dataObj;
    }
    
    getAnnotationsAC(dataList, annotation, isBlower) {
        if (annotation !== undefined) {
            let bandStart = moment(annotation["startTime"]);
            let bandEnd = moment(annotation["endTime"]);
            if (this.state.acSelected) {
                let start = moment(annotation["startTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                let startDT = new Date(start);
                bandStart = Date.UTC(startDT.getFullYear(), startDT.getMonth(), startDT.getDate(), startDT.getHours(), startDT.getMinutes(), startDT.getSeconds(), startDT.getMilliseconds());

                let end = moment(annotation["endTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                let endDT = new Date(end);
                bandEnd = Date.UTC(endDT.getFullYear(), endDT.getMonth(), endDT.getDate(), endDT.getHours(), endDT.getMinutes(), endDT.getSeconds(), endDT.getMilliseconds());
            }

            let minPoint = isBlower ? 0 : 1;
            let maxPoint = isBlower ? 1 : 2;
            let annotationData ={
                name: isBlower ? "Blower" : "Compressor",
                type: 'polygon',
                color: annotation["color"] + "45",
                zIndex: 1,
                data: [
                    [bandStart, minPoint],
                    [bandEnd, minPoint],
                    [bandEnd, maxPoint],
                    [bandStart, maxPoint]
                ]
            };
            dataList = dataList.concat(annotationData);
        }
        return dataList;
    }
    
    getHighchartsData2(source, annotations, isTimeline = false) {
        let dataList = [];

        if (!this.state.thermostatSelected && source !== null && source !== undefined && source.data !== null && source.data !== undefined && source.data[0] !== undefined) {
            let dataArr = source.data.map((item) => item[1]);
            let pointMoment = moment(source.data[0][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A")
            let dt = new Date(pointMoment);
            let pointStartMoment = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());
            let lastMoment = moment(source.data[source.data.length-1][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A");
            let dtLast = new Date(lastMoment);
            let pointLastMoment = Date.UTC(dtLast.getFullYear(), dtLast.getMonth(), dtLast.getDate(), dtLast.getHours(), dtLast.getMinutes(), dtLast.getSeconds(), dtLast.getMilliseconds());

            if (this.state.acSelected) {
                if (annotations !== undefined && annotations !== null && annotations.length && (this.state.showAnnotations || this.state.visibility.analytics)) {
                    let blowerAnnotation = annotations.find(a => a.state === "Blower");
                    let compressorAnnotation = annotations.find(a => a.state === "Compressor");

                    if (blowerAnnotation !== undefined || compressorAnnotation !== undefined) {
                        // note - for AC annotations, the UTC offset calculation is necessary for release but needs to be removed to show correctly when developing on local
                        dataList = this.getAnnotationsAC(dataList, blowerAnnotation, true);
                        dataList = this.getAnnotationsAC(dataList, compressorAnnotation, false);
                    }
                }
            }
            
            let dataName = this.state.acSelected ? (this.state.currentAsset.type.trim() === "HeatPump" ? 'Heat Pump Current' : 'AC Current') : 'Current';
            let chartData = this.getDataObj(dataName, dataArr, '#176a95', pointStartMoment);
            dataList = dataList.concat(chartData);

            if (this.state.acSelected) {
                let isPeerFurnace = this.state.graphDataAC.peerType === Equipment.FURNACE;
                if (source.furnaceData !== null && this.state.visibility.furnace) {
                    let furnArr = source.furnaceData.map((item) => item[1]);
                    let furnData = this.getDataObj(isPeerFurnace ? 'Furnace Current' : "Air Handler Current", furnArr, '#6f42c1', pointStartMoment); 
                    dataList = dataList.concat(furnData);
                }

                if (this.state.visibility.batteryVoltage) {
                    if (source.batteryData !== null) {
                        let batteryArr = source.batteryData.map((item) => item[1]);
                        let batteryData = this.getDataObj('Battery Voltage', batteryArr, '#8b0000', pointStartMoment);
                        dataList = dataList.concat(batteryData);
                    }
                }

                if (this.state.visibility.temp) {
                    if (source.indoorTempData !== null) {
                        let indoorArr = source.indoorTempData.map((item) => item[1]);
                        let indoorData = this.getDataObj('Indoor Hub Temp', indoorArr, '#d399e0', pointStartMoment, 1);
                        dataList = dataList.concat(indoorData);
                    }

                    if (source.outdoorTempData !== null) {
                        let outdoorArr = source.outdoorTempData.map((item) => item[1]);
                        let outdoorData = this.getDataObj('Outdoor Hub Temp', outdoorArr, '#7fc6f5', pointStartMoment, 1);
                        dataList = dataList.concat(outdoorData);
                    }
                }

                if (this.state.visibility.rla) {
                    let rlaData = this.getDashedDataObj('RLA', this.state.graphDataAC.rla, '#f0b169', pointStartMoment, pointLastMoment);
                    dataList = dataList.concat(rlaData);
                }

                if (this.state.visibility.lra) {
                    let lraData = this.getDashedDataObj('LRA', this.state.graphDataAC.lra, '#dc3545', pointStartMoment, pointLastMoment); 
                    dataList = dataList.concat(lraData);
                }
            }

            let graphConstants = this.state.graphConstants;
            if (this.state.currentAsset.type.trim() === Equipment.AIR_HANDLER) {
                if (this.state.visibility.analytics || this.state.showGraphAnnotations) {
                    if (graphConstants !== null && graphConstants !== undefined) {
                        if (graphConstants["blowerLowStageAvg"] !== null && graphConstants["blowerLowStageAvg"] !== undefined && graphConstants["blowerLowStageAvg"] > 0) {
                            let blowerLowAvg = graphConstants["blowerLowStageAvg"];
                            if (graphConstants["blowerLowStdDev"] !== null && graphConstants["blowerLowStdDev"] !== undefined && graphConstants["blowerLowStdDev"] > 0) {
                                let blowerLowStdDev = graphConstants["blowerLowStdDev"];
                                let blowerLowMin = blowerLowAvg - blowerLowStdDev;
                                let blowerLowMax = blowerLowAvg + blowerLowStdDev;
                                let lowStageMinData = this.getDashedDataObj('Expected Low Stage Min', blowerLowMin, '#dc3545', pointStartMoment, pointLastMoment); 
                                dataList = dataList.concat(lowStageMinData);

                                let lowStageMaxData = this.getDashedDataObj('Expected Low Stage Max', blowerLowMax, '#dc3545', pointStartMoment, pointLastMoment);
                                dataList = dataList.concat(lowStageMaxData);
                            } 
                            else {
                                let lowStageData = this.getDashedDataObj('Expected Low Stage Avg', blowerLowAvg, '#dc3545', pointStartMoment, pointLastMoment);
                                dataList = dataList.concat(lowStageData);
                            }
                        }

                        if (graphConstants["blowerHighStageAvg"] !== null && graphConstants["blowerHighStageAvg"] !== undefined && graphConstants["blowerHighStageAvg"] > 0) {
                            let blowerHighAvg = graphConstants["blowerHighStageAvg"];
                            if (graphConstants["blowerHighStdDev"] !== null && graphConstants["blowerHighStdDev"] !== undefined && graphConstants["blowerHighStdDev"] > 0) {
                                let blowerHighStdDev = graphConstants["blowerHighStdDev"];
                                let blowerHighMin = blowerHighAvg - blowerHighStdDev;
                                let blowerHighMax = blowerHighAvg + blowerHighStdDev;
                                let highStageMinData = this.getDashedDataObj('Expected High Stage Min', blowerHighMin, '#dc3545', pointStartMoment, pointLastMoment);
                                dataList = dataList.concat(highStageMinData);

                                let highStageMaxData = this.getDashedDataObj('Expected High Stage Max', blowerHighMax, '#dc3545', pointStartMoment, pointLastMoment);
                                dataList = dataList.concat(highStageMaxData);
                            } 
                            else {
                                let highStageData = this.getDashedDataObj('Expected High Stage Avg', blowerHighAvg, '#dc3545', pointStartMoment, pointLastMoment);
                                dataList = dataList.concat(highStageData);
                            }
                        }

                        if (graphConstants["blowerLowAverage"] !== null && graphConstants["blowerLowAverage"] !== undefined && graphConstants["blowerLowAverage"] > 0) {
                            let blowerLowAvg = graphConstants["blowerLowAverage"];
                            let lowStageData = this.getDashedDataObj('Blower Low Avg', blowerLowAvg, 'teal', pointStartMoment, pointLastMoment, false);
                            dataList = dataList.concat(lowStageData);
                        }

                        if (graphConstants["blowerHighAverage"] !== null && graphConstants["blowerHighAverage"] !== undefined && graphConstants["blowerHighAverage"] > 0) {
                            let blowerHighAvg = graphConstants["blowerHighAverage"];
                            let highStageData = this.getDashedDataObj('Blower High Avg', blowerHighAvg, 'teal', pointStartMoment, pointLastMoment, false);
                            dataList = dataList.concat(highStageData);
                        }
                    }
                }
            }

            if (this.state.currentAsset.isMultiCT) {
                if (this.state.visibility.blowerCurrent) {
                    if (source.blowerData !== null) {
                        let blowerArr = source.blowerData.map((item) => item[1]);
                        let blowerData = this.getDataObj('Blower Current', blowerArr, '#8b0000', pointStartMoment);
                        dataList = dataList.concat(blowerData);
                    }
                }

                if (this.state.visibility.inducerCurrent) {
                    if (source.inducerData !== null) {
                        let inducerArr = source.inducerData.map((item) => item[1]);
                        let inducerData = this.getDataObj('Inducer Current', inducerArr, '#556b2f', pointStartMoment);
                        dataList = dataList.concat(inducerData);
                    }
                }
            }
        }
        else if (isTimeline && this.state.thermostatSelected && source !== null && source !== undefined && source.indoorTempData !== null && source.indoorTempData !== undefined && source.indoorTempData[0] !== undefined) {
            let timelineArr = source.indoorTempData.map((item) => (item[1]));

            let pointMoment = moment(source.indoorTempData[0][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A");
            let dtTherm = new Date(pointMoment);
            let pointStartThermostat = Date.UTC(dtTherm.getFullYear(), dtTherm.getMonth(), dtTherm.getDate(), dtTherm.getHours(), dtTherm.getMinutes(), dtTherm.getSeconds(), dtTherm.getMilliseconds());

            let timelineData = this.getDataObj('Indoor Temp', timelineArr, '#176a95', pointStartThermostat);
            dataList = dataList.concat(timelineData);

            if (source.outdoorTempData !== null) {
                let outdoorArr = source.outdoorTempData.map((item) => item[1]);
                let outdoorData = this.getDataObj('Outdoor Temp', outdoorArr, '#6f42c1', pointStartThermostat); 
                dataList = dataList.concat(outdoorData);
            }

            if (this.state.visibility.coolSetpoint) {
                if (source.coolSetpointData !== null) {
                    let coolArr = source.coolSetpointData.map((item) => item[1]);
                    let coolData = this.getDataObj('Cool Setpoint', coolArr, '#d399e0', pointStartThermostat); 
                    dataList = dataList.concat(coolData);
                }
            }

            if (this.state.visibility.heatSetpoint) {
                if (source.heatSetpointData !== null) {
                    let heatArr = source.heatSetpointData.map((item) => item[1]);
                    let heatData = this.getDataObj('Heat Setpoint', heatArr, '#7fc6f5', pointStartThermostat); 
                    dataList = dataList.concat(heatData);
                }
            }
        }

        return dataList;
    }

    getHighchartsAnnotations(graphAnnotations) {
        let plotBands = [];

        if (graphAnnotations !== null && graphAnnotations !== undefined && graphAnnotations.length) {
            for (let i = 0; i < graphAnnotations.length; i++) {
                let annotation = graphAnnotations[i];
                if (!this.state.acSelected || (annotation["state"] !== "Blower" && annotation["state"] !== "Compressor" && !annotation["state"].includes("Predicted")))  {
                    // note - for AC annotations, the UTC offset calculation is necessary for release but needs to be removed to show correctly when developing on local
                    let bandStart = moment(annotation["startTime"]);
                    let bandEnd = moment(annotation["endTime"]);
                    if (this.state.acSelected) {
                        let start = moment(annotation["startTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                        let startDT = new Date(start);
                        bandStart = Date.UTC(startDT.getFullYear(), startDT.getMonth(), startDT.getDate(), startDT.getHours(), startDT.getMinutes(), startDT.getSeconds(), startDT.getMilliseconds()); 
                        
                        let end = moment(annotation["endTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A"); 
                        let endDT = new Date(end);
                        bandEnd = Date.UTC(endDT.getFullYear(), endDT.getMonth(), endDT.getDate(), endDT.getHours(), endDT.getMinutes(), endDT.getSeconds(), endDT.getMilliseconds()); 
                    }

                    let band = {
                        color: annotation["color"] + "45", /* opacity */
                        from: bandStart,
                        to: bandEnd,
                        label: {
                            text: annotation["state"],
                            rotation: -90,
                            textAlign: 'left',
                            verticalAlign: 'top',
                            y: 90
                        },
                        zIndex: 1
                    };
                    
                    plotBands = plotBands.concat(band);
                }
                
            }
        }
        
        return plotBands;
    }
    
    getHighchartsArrowAnnotations(arrowAnnotations) {
        let plotLines = [];
        /* to show the arrow annotation lines correctly when developing on local, must remove the '.add(moment().utcOffset()*-1, 'minutes')' from time, but is needed to show right when deployed */
        if (arrowAnnotations !== null && arrowAnnotations !== undefined && arrowAnnotations.length) {
            for (let i = 0; i < arrowAnnotations.length; i++) {
                let annotation = arrowAnnotations[i];
                let time = moment(annotation["time"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                let timeDT = new Date(time);
                let lineTime = Date.UTC(timeDT.getFullYear(), timeDT.getMonth(), timeDT.getDate(), timeDT.getHours(), timeDT.getMinutes(), timeDT.getSeconds(), timeDT.getMilliseconds());
                let line = {
                    color: '#dc3545',
                    value: lineTime,
                    width: 2,
                    label: {
                        text: annotation["errorMessage"],
                        verticalAlign: 'top',
                        textAlign: 'left',
                        rotation: 0,
                        y: 15
                    }
                };
                plotLines = plotLines.concat(line);
            }
        }
        
        return plotLines;
    }

    renderGraphButtons() {
        let blowerCheckbox = this.state.currentAsset.isMultiCT ?
                <div>
                    <input type="checkbox" value="blowerCurrent" checked={this.state.visibility.blowerCurrent} id="gdo" onChange={this.handleCheckbox} disabled={!this.state.currentAsset.isMultiCT} />
                    &nbsp; Add Blower Current &nbsp;&nbsp;
                            </div>
            : null;

        let inducerCheckbox = this.state.currentAsset.isMultiCT ?
            <div>
                <input type="checkbox" value="inducerCurrent" checked={this.state.visibility.inducerCurrent} id="gdo" onChange={this.handleCheckbox} disabled={!this.state.currentAsset.isMultiCT} />
                    &nbsp; Add Inducer Current &nbsp;&nbsp;
                            </div>
            : null;

        let checkboxes = this.state.acSelected ?
            <div className="graph-radio-container radio-container">
                <div>
                    <input type="checkbox" value="analytics" checked={this.state.visibility.analytics || this.state.visibility.annotations} id="gdo" onChange={this.handleCheckbox} disabled={!this.state.currentAsset.hasAnalytics} />
                    &nbsp; Analytics &nbsp;&nbsp;
                            </div>
                <div>
                    <input type="checkbox" value="furnace" checked={this.state.visibility.furnace} id="gdo" onChange={this.handleCheckbox} />
                    &nbsp; {this.state.graphDataAC.peerType === "Furnace" ? "Furnace Current" : "Air Handler Current"} &nbsp;&nbsp;
                            </div>
                <div>
                    <input type="checkbox" value="temp" checked={this.state.visibility.temp} id="gdo" onChange={this.handleCheckbox} />
                    &nbsp; Temperature &nbsp;&nbsp;
                            </div>
                <div>
                    <input type="checkbox" value="rla" checked={this.state.visibility.rla} id="gdo" onChange={this.handleCheckbox} />
                    &nbsp; RLA &nbsp;&nbsp;
                            </div>
                <div>
                    <input type="checkbox" value="lra" checked={this.state.visibility.lra} id="gdo" onChange={this.handleCheckbox} />
                    &nbsp; LRA &nbsp;&nbsp;
                            </div>

                <div>
                    <input type="checkbox" value="batteryVoltage" checked={this.state.visibility.batteryVoltage} id="gdo" onChange={this.handleCheckbox} />
                    &nbsp; Battery Voltage &nbsp;&nbsp;
                            </div>

                {blowerCheckbox}
                {inducerCheckbox}
            </div>

            : this.state.thermostatSelected ?
                <div className="graph-radio-container radio-container">
                    <div>
                        <input type="checkbox" value="coolSetpoint" checked={this.state.visibility.coolSetpoint} id="gdo" onChange={this.handleCheckbox} />
                        &nbsp; Cool Setpoint &nbsp;&nbsp;
                                </div>
                    <div>
                        <input type="checkbox" value="heatSetpoint" checked={this.state.visibility.heatSetpoint} id="gdo" onChange={this.handleCheckbox} />
                        &nbsp; Heat Setpoint &nbsp;&nbsp;
                                </div>
                </div>
                : <div className="graph-radio-container radio-container">
                <div>
                    <input type="checkbox" value="analytics" checked={this.state.visibility.analytics || this.state.visibility.annotations} id="gdo" onChange={() => this.showGraphAnnotations()} disabled={!this.state.currentAsset.hasAnalytics} />
                    &nbsp; Analytics &nbsp;&nbsp;
                            </div>
                {blowerCheckbox}
                {inducerCheckbox}
            </div>;

        let viewRunsButton = !this.state.thermostatSelected ?
            <div className="row centered">
                <button className="margin-top-10 secondary-btn btn-small" onClick={this.onViewRuns} disabled={!(this.state.assetSelected || !this.state.disableGraphOptions)}>View Runs</button>
            </div>
            : null;

        return (
            <div>
                {viewRunsButton}
                <hr />
                <div className="lastItem">
                    <div className="detailTitle">Graph Display Options</div>
                    {checkboxes}
                </div>
            </div>
        );
    }

    calculateMonthlyData(data) {
        // Initialize an empty object to store summarized data
        const summarizedData = {};

        // Iterate over each data entry
        data.forEach(entry => {
            // Extract necessary information
            const { deviceName, assetId, customerName, homeName, hvacName, assetType, runDate, amps, watts, kilowattHours, runs, cost, gasCost, timeRunning, alerts, equipmentAlerts, deviceAlerts } = entry;

            let date = new Date(runDate);
            const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;

            // If the device is not in the summarized data, initialize its entry
            if (!summarizedData[deviceName]) {
                summarizedData[deviceName] = {
                    customer: customerName,
                    homeName: homeName,
                    hvacName: hvacName,
                    assetType: assetType,
                    kilowattHours: 0,
                    amps: 0,
                    watts: 0,
                    runs: 0,
                    cost: 0,
                    gasCost: 0,
                    timeRunning: 0,
                    dayCount: 0,
                    alerts: 0,
                    deviceAlerts: 0,
                    equipmentAlerts: 0
                };
            }

            // Increment the count of entries for the device
            summarizedData[deviceName].dayCount++;

            // Sum up the values for each device
            summarizedData[deviceName].amps += amps;
            summarizedData[deviceName].watts += watts;
            summarizedData[deviceName].kilowattHours += kilowattHours;
            summarizedData[deviceName].runs += runs;
            summarizedData[deviceName].cost += cost;
            summarizedData[deviceName].gasCost += gasCost;
            summarizedData[deviceName].timeRunning += timeRunning;
            summarizedData[deviceName].alerts += alerts;
            summarizedData[deviceName].equipmentAlerts += equipmentAlerts;
            summarizedData[deviceName].deviceAlerts += deviceAlerts;
        });

        // Format Summary Data
        Object.keys(summarizedData).forEach(deviceName => {
            const { amps, watts, kilowattHours, cost, gasCost, count } = summarizedData[deviceName];
            summarizedData[deviceName].amps = amps.toFixed(2);
            summarizedData[deviceName].watts = watts.toFixed(2);
            summarizedData[deviceName].kilowattHours = kilowattHours.toFixed(2);
            summarizedData[deviceName].cost = cost.toFixed(2);
            summarizedData[deviceName].gasCost = gasCost.toFixed(2);
         });

        var summarizedDataList = Object.entries(summarizedData).map(([key, value]) => ({
            deviceName: key,
            ...value
        }));

        return summarizedDataList;
    }

    populateEnergyData(date, assetIds) {
            this.setState({ energyLoading: true }, () => {
                let yearSelected = date.getFullYear();
                let monthSelected = date.getMonth() + 1;
                DailyStatisticsService.monthlyAssetData(monthSelected, yearSelected, assetIds).then(response2 => {
                    if (this._isMounted) {
                        var calculatedData = this.calculateMonthlyData(response2.data);
                        this.setState({ monthlyDataObj: response2.data, summarizedData: calculatedData, energyLoading: false, assetIds: assetIds });
                    }
                    else {
                        this.setState({ energyLoading: false })
                    }
                });
            });
    }
    handleContextMenu= (e, id) => {
        e.preventDefault();
        this.setState({ selectedRowIdForContextMenu: id });
        if (this.contextMenuRef.current) {
            this.contextMenuRef.current.show({
                    event: e
                }
            );
        }
    }
    onContextClick = (option) => {
        const {selectedRowIdForContextMenu} = this.state;
        if (selectedRowIdForContextMenu !== null) {
            this.setState({ devTTUrl: "https://app.lifewhere.com/twins/detail/" + selectedRowIdForContextMenu })
            this.openTTConfirmModal();
        }
    }

    showTTConfirmModal() {
        this.setState({ showTTConfirm: !this.state.showTTConfirm });
    }

    openTTConfirmModal() {
        this.setState({ modalMessage: "You are about to leave the LifeWhere portal and be taken to the Device Properties page in TwinThread. Do you wish to continue?", modalTitle: "Leave LifeWhere Portal" }, () => {
            this.showTTConfirmModal();
        });
    }

    onTTConfirm() {
        Event("View Device Properties Clicked", "User clicked view device properties and is being taken to TwinThread Properties view", "View Device Properties Clicked");
        window.open(this.state.devTTUrl, '_blank'); // opens new tab with TwinThread twins detail page
        this.showTTConfirmModal(); // closes modal
    }

    renderDatePicker() {

        const handleChange = async date => {
            this.setState({ dateSelected: date, energyLoading: true });
            await this.populateEnergyData(date, this.state.assetIds);
        };

        return (
            <div className={"text-center"}>
                <div className={"margin-left-10"}>Data From:</div>
                <DatePicker
                    customInput={<input className="txt-detail asset-datepicker"  />}
                    selected={this.state.dateSelected}
                    onChange={handleChange}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    popperPlacement="auto"
                    popperProps={{
                        positionFixed: true
                    }}
                />
            </div>
        );
    }

    getCommentIndicator(assetComments) {
        if (assetComments !== null && assetComments !== undefined && assetComments.length) {
            if (assetComments.find(c => c.hasAnswer) !== undefined) {
                return (
                    <CircleIcon className={"pad-5 color-green"} />
                );
            } 
            else if (assetComments.find(c => c.hasQuestion) !== undefined) {
                return (
                    <CircleIcon className={"pad-5 color-red"} />
                );
            }
            else if (assetComments.find(c => c.hasNewComment) !== undefined) {
                return (
                    <CircleIcon className={"pad-5 color-cornflowerblue"} />
                );
            }
        }

        return(
            <span className={"pad-5"}> </span>
        )
    }

    renderGraph() {

        let runRow = !this.state.thermostatSelected ?
            <div className="row">
                <div className="col-sm-2 col-md-6 col-lg-3 col-xl-2">
                    <div className={"margin-left-10"}>Runs:</div>
                </div>
                <div className="col-sm-9 col-md-12 col-lg-9 centered">
                    <button className="btn-detail btn-25" onClick={this.onLeftRunClick} disabled={!this.state.canLeftClick || this.state.disableGraphOptions} > {"<"}</button>
                    <select className="txt-detail asset-run-select" onChange={this.onChangeRun} value={this.state.selectedRun} >
                        {this.state.graphData.runNamesList !== null && this.state.graphData.runNamesList !== undefined ?
                            this.state.graphData.runNamesList.map((run, index) =>
                                <option key={`${index}-${run}`} id={`${index}-${run}`} value={index}> {this.getRunName(run)} &nbsp; &nbsp; &nbsp;</option>
                            )
                            : <option value="">No Runs</option>
                        }
                    </select>
                    <button className="btn-detail btn-25" onClick={this.onRightRunClick} disabled={!this.state.canRightClick || this.state.disableGraphOptions} >{">"}</button>
                </div>
            </div>
            : null;

        let rightCol =
            <div className="col-sm-12 col-md-4">
                <div className="overflow-visible grey-container">
                    <h5 className="sectionTitle">
                        Graph Options
                        <span className={"margin-left-10 pad-top-0 pad-bottom-5"}>
                            <IconButton onClick={this.openInfoModal} edge="start" color="inherit"  >
                                <InfoOutlinedIcon />
                            </IconButton>
                        </span>
                    </h5>

                    {runRow}

                    <div className="row">
                        <div className="col-sm-2 col-md-6 col-lg-3 col-xl-2">
                            <div className={"margin-left-10"} >Start:</div>
                        </div>
                        <div className="col-sm-9 col-md-12 col-lg-9 centered text-center">
                            <DatePicker
                                customInput={<input className="txt-detail asset-select-datepicker" disabled={this.state.disableGraphOptions} />}
                                id="startDate"
                                name="startDate"
                                selected={this.state.dpStartDateTime}
                                onChange={date => { Event("Run Start Date Click", "User clicked start date picker to change it", "Run Start Date Changed"); this.onChangeStart(date); }}
                                dateFormat="MM/dd/yyyy h:mm:ss aa"
                                timeIntervals={15}
                                showTimeSelect
                                popperPlacement="top-end"
                                popperProps={{
                                    positionFixed: true
                                }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-2 col-md-6 col-lg-3 col-xl-2">
                            <div className={"margin-left-10"} >End:</div>
                        </div>
                        <div className="col-sm-9 col-md-12 col-lg-9 centered text-center">
                            <DatePicker
                                customInput={<input className="txt-detail asset-select-datepicker" disabled={this.state.disableGraphOptions} />}
                                id="endDate"
                                name="endDate"
                                selected={this.state.dpEndDateTime}
                                onChange={date => { Event("Run End Date Click", "User clicked end date picker to change it", "Run End Date Changed"); this.onChangeEnd(date); }}
                                dateFormat="MM/dd/yyyy h:mm:ss aa"
                                timeIntervals={15}
                                showTimeSelect
                                popperPlacement="top-end"
                                minDate={this.state.dpStartDateTime}
                                maxDate={addHours(this.state.dpStartDateTime, 48)} 
                                popperProps={{
                                    positionFixed: true
                                }}
                            />
                        </div>
                    </div>
                    {this.renderGraphButtons()}
                </div>
                <br /><br />
            </div>;

        let timelineGraph = (this.state.currentTimeline !== null && this.state.currentTimeline !== undefined && this.state.currentTimeline.data !== null && this.state.currentTimeline.data !== undefined && this.state.currentTimeline.data[0] !== null ) ?
            <GraphComponent graphType={GraphTypes.ASSET} chartData={this.getHighchartsData2(this.state.currentTimeline, this.state.timelineAnnotations, true)} key={`${this.state.currentAsset.assetId}-${this.state.graphData}-${this.state.visibility}-timeline`}
                assetType={this.state.acSelected ? Equipment.AIR_CONDITIONER : (this.state.thermostatSelected ? Equipment.THERMOSTAT : Equipment.FURNACE)} isTimeline={true} flaValue={null} title={this.getTimestring(this.state.currentRunName)}
                visibility={this.state.visibility} lraValue={this.state.graphDataAC.lra} hasAnalytics={this.state.currentAsset.hasAnalytics} 
                dataMax={this.state.currentMax} arrowAnnotations={this.getHighchartsArrowAnnotations(this.state.timelineArrowAnnotations)}
                graphAnnotations={this.getHighchartsAnnotations(this.state.timelineAnnotations)} 
                pointStart={this.state.currentTimeline.data[0]} secondAxisType={this.state.acSelected ? AxisTypes.TEMP : AxisTypes.NONE} predictions={null}/>
            : null;

        let timelinePanel = (this.state.timelineData !== null && this.state.timelineData !== undefined) ?
            <div className="row">
                <div className="col-sm-12 col-md-8">
                    <div className={"margin-top-10 margin-right-15"}>
                        {timelineGraph}
                        <br /><br />
                    </div>
                </div>
                {rightCol}
            </div>
            : <div className="row">
                <div className="col-sm-12 col-md-8">
                    <div className={"margin-top-10 margin-left-15 margin-right-25"}>
                        No data within selected time frame. The unit is either not running or not sending data. Check the Devices page to verify the status of the LifeWhere devices.
                                    <br /><br />
                    </div>
                </div>
                {rightCol}
            </div>;

        let graph = (this.state.currentHighchartSource !== null && this.state.currentHighchartSource !== undefined && this.state.currentHighchartSource.data !== null && this.state.currentHighchartSource.data !== undefined && this.state.currentHighchartSource.data[0] !== null && this.state.currentHighchartSource.data[0] !== undefined && this.state.currentSource !== null && this.state.currentSource !== undefined && this.state.graphData.equipmentRuns !== null) ?
            <div className={"graph-assets"}>

                <GraphComponent graphType={GraphTypes.ASSET} chartData={this.getHighchartsData2(this.state.currentHighchartSource, this.state.currentSource.graphAnnotations)} key={`${this.state.currentAsset.assetId}-${this.state.currentSource.dataArray.length}-${this.state.currentRunName}-${this.state.visibility}`}
                                assetType={this.state.acSelected ? Equipment.AIR_CONDITIONER : (this.state.thermostatSelected ? Equipment.THERMOSTAT : Equipment.FURNACE)} rlaValue={this.state.graphDataAC.rla} flaValue={null} title={this.getTimestring(this.state.currentRunName)}
                    visibility={this.state.visibility} lraValue={this.state.graphDataAC.lra} hasAnalytics={this.state.currentAsset.hasAnalytics} arrowAnnotations={this.getHighchartsArrowAnnotations(this.state.currentSource.graphArrowAnnotations)}
                    graphAnnotations={this.getHighchartsAnnotations(this.state.currentSource.graphAnnotations)} 
                    pointStart={this.state.currentHighchartSource.data[0]} secondAxisType={this.state.acSelected ? AxisTypes.TEMP : AxisTypes.NONE} predictions={this.state.currentPredictions} /> 

            </div>
            : <div className={"graph-assets-noData"}>
                {this.state.isTraining ?
                    "There is no data for this unit."
                    : "No runs within selected time frame. The unit is either not running or not sending data. Check the Devices page to verify the status of the LifeWhere devices."
                }
                <br /><br />
            </div>;

        let thermoGraph = (this.state.thermostatSelected && this.state.currentTimeline !== null && this.state.currentTimeline !== undefined && this.state.currentTimeline.indoorTempData !== null && this.state.currentTimeline.indoorTempData !== undefined && this.state.currentTimeline.indoorTempData[0] !== null) ?
            <div className={"graph-assets"}>

                <GraphComponent graphType={GraphTypes.ASSET} chartData={this.getHighchartsData2(this.state.currentTimeline, this.state.currentSource.graphAnnotations, true)} key={`${this.state.currentAsset.assetId}-${this.state.currentSource.dataArray.length}-${this.state.currentRunName}-${this.state.visibility}`}
                                assetType={this.state.acSelected ? Equipment.AIR_CONDITIONER : (this.state.thermostatSelected ? Equipment.THERMOSTAT : Equipment.FURNACE)} rlaValue={this.state.graphDataAC.rla} flaValue={null} title={this.getTimestring(this.state.currentRunName)}
                    visibility={this.state.visibility} lraValue={this.state.graphDataAC.lra} hasAnalytics={this.state.currentAsset.hasAnalytics}
                    graphAnnotations={this.getHighchartsAnnotations(this.state.currentSource.graphAnnotations)} 
                    pointStart={this.state.currentTimeline.indoorTempData[0]} secondAxisType={AxisTypes.NONE} predictions={null}/>

            </div>
            : <div className={"graph-assets-noData"}>
                {this.state.isTraining ?
                    "There is no data for this unit."
                    : "No runs within selected time frame. The unit is either not running or not sending data. Check the Devices page to verify the status of the LifeWhere devices."
                }
                <br /><br />
            </div>;

        let thermostatPanel = (this.state.thermostatSelected && this.state.currentTimeline != null && true && this.state.currentTimeline.indoorTempData != null && true && this.state.currentTimeline.indoorTempData[0] !== null) ?
            <div className="row">
                <div className="col-sm-12 col-md-8">
                    <div className={"graph-assets"}>
                        {thermoGraph}
                        <br /><br />
                    </div>
                </div>
                {rightCol}
            </div>
            : <div className="row">
                <div className="col-sm-12 col-md-8">
                    <div className={"graph-assets-noData"}>
                        No data within selected time frame. The unit is either not running or not sending data. Check the Devices page to verify the status of the LifeWhere devices.
                                    <br /><br />
                    </div>
                </div>
                {rightCol}
            </div>;


        if (!this.state.thermostatSelected && this.state.currentSource !== null && this.state.currentSource !== undefined) {

            return (
                <Tabs>
                    <TabList className={"graph-tabs graph-assets"}>
                        <Tab>Run</Tab>
                        <Tab>Timeline</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="row">
                            <div className="col-sm-12 col-md-8">
                                {graph}
                            </div>
                            {rightCol}
                        </div>

                    </TabPanel>

                    <TabPanel>
                        {timelinePanel}
                    </TabPanel>
                </Tabs>
                
            );
        }
        else if (this.state.thermostatSelected) {
            return (
                <Tabs>
                    <TabList className={"graph-tabs graph-assets"}>
                        <Tab>Timeline</Tab>
                    </TabList>

                    <TabPanel>
                        {thermostatPanel}
                    </TabPanel>
                </Tabs>
            );
        }
        else {
            return (
                <Tabs>
                    <TabList className={"graph-tabs graph-assets"}>
                        <Tab>Run</Tab>
                        <Tab>Timeline</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="row">
                            <div className="col-sm-12 col-md-8">
                                <div className={"graph-assets-noData"}>
                                    {this.state.isTraining ?
                                        "There is no data for this unit." 
                                        : "No runs within selected time frame. The unit is either not running or not sending data. Check the Devices page to verify the status of the LifeWhere devices."
                                    }
                                    <br /><br />
                                </div>
                            </div>
                            {rightCol}
                        </div>
                    </TabPanel>

                    <TabPanel>
                        {timelinePanel}
                    </TabPanel>
                </Tabs>
                );
        }
    }

    renderAssets(assetList) {
        const { classes } = this.props;
        if (assetList.length) {
            let graphLoader = this.state.assetLoading
                ? <Loader />
                : <div>{null}</div>;
            let tableContent = assetList.length ?
                <tbody>
                    {assetList.map((asset, index) =>
                        <tr id={asset.assetId} key={asset.assetId} onContextMenu={(event) => this.handleContextMenu(event, asset.assetId)}>
                            <td onClick={async () => { await this.onAssetClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>{asset.type}</td>
                            <td onClick={async () => { await this.onAssetClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>{asset.mac}</td>
                            <td onClick={async () => { await this.onAssetClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>{asset.nickname}</td>
                        </tr>
                    )}
                </tbody>
                : <tbody>
                    <tr><td>No Assets!</td></tr>
                </tbody>;
            let assetTable =
                <div className="table-container-2 overflow-auto height-245">
                    <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                        <thead>
                            <tr >
                                <th>Type</th>
                                <th>MAC</th>
                                <th>Nickname</th>
                            </tr>
                        </thead>
                        {tableContent}
                    </table>
                    <ContextMenu onItemClick={this.onContextClick} ref={this.contextMenuRef} />
                </div>;

            let datePicker = this.renderDatePicker();

            let energyDataTable = <div className="overflow-auto min-height-100p white-container">
                    <h5 className="sectionTitle">Energy Data</h5>
                    {datePicker}
                    <EnergyMetricsTable isLoading={this.state.energyLoading} monthlyDataObj={this.state.monthlyDataObj} summarizedData={this.state.summarizedData} dateSelected={this.state.dateSelected}/>
                </div>;

            let favoriteBtn = this.state.assetSelected ?
                (this.props.favoriteAssets.some(f => f.assetId === this.state.currentAsset.assetId) ?
                    <span className={"margin-left-10 pad-top-0"}>
                        <IconButton onClick={this.openFavoriteModal} edge="start" color="inherit">
                            <StarIcon />
                        </IconButton>
                    </span>
                    : <span className={"margin-left-10 pad-top-0"}>
                        <IconButton onClick={this.openFavoriteModal} edge="start" color="inherit">
                            <StarOutlineIcon />
                        </IconButton>
                    </span>)
                : <div>{null}</div>;

            let testSystemButton = (this.props.serviceTier === 4) ?
                <div className="margin-top-10 margin-left-15 row no-gutters lastItem">
                    <button className="secondary-btn btn-small" onClick={this.onTestSystemClick}>Test System</button>
                </div>
                : null;

            let systemButtons = (this.props.serviceTier === 5) ?
                <div>
                    <div className="margin-top-10 margin-left-15 row no-gutters lastItem">
                        <button className="secondary-btn btn-small" onClick={this.onTestSystemClick}>Change Setpoint</button>
                        <button className="secondary-btn btn-small btn-red margin-left-5" onClick={this.onTurnOffSystemClick} >Turn Off System</button>
                    </div>
                </div>
                : null;

            //if partnerAdmin -> Billing Info, else if tech/sched -> null, else if admin && !isActive -> can delete.  
            let deleteButton = this.props.isAdmin && !this.props.isActive ?
                <button className="secondary-btn btn-small"  onClick={this.openDeleteAssetModal}>Delete Asset</button>
                : (this.props.isPartnerAdmin ?
                    <button className="secondary-btn btn-small" onClick={this.routeToBilling}>Delete Asset</button>
                    : null);

            let selectType = this.state.currentAsset !== null && this.state.currentAsset !== undefined && this.state.currentAsset.type.trim() === "Thermostat" ?
                <select className="txt-detail" value={"Thermostat"} onChange={this.onChangeAssetType} disabled>
                    <option disabled value="Thermostat">Thermostat</option>
                </select>
            : <select className="txt-detail" value={this.state.currentAsset !== null && this.state.currentAsset !== undefined ? this.state.currentAsset.type.trim() : "Furnace"} onChange={this.onChangeAssetType}>
                    <option hidden disabled defaultValue value=""> </option>
                    <option key="1" id="1" value="AirConditioner">AirConditioner</option>
                    <option key="2" id="2" value="AirHandler">AirHandler</option>
                    <option key="3" id="3" value="Boiler">Boiler</option>
                    <option key="4" id="4" value="Furnace">Furnace</option>
                    <option key="5" id="5" value="HeatPump">HeatPump</option>
                    <option key="6" id="6" value="WaterHeater">WaterHeater</option>
                </select>;
            
            return (
                <div>
                   
                    <div>
                        <div className="row display-flex">
                            <div className="col-sm-12 col-md-8">
                                <div className="overflow-auto min-height-100p white-container">
                                    <h5 className="sectionTitle">Asset List</h5>
                                    {assetTable}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="overflow-auto min-height-100p grey-container">
                                    <h5 className="sectionTitle">
                                        Asset Details
                                        {favoriteBtn}
                                    </h5>
                                    <div>
                                        <div className="row no-gutters">
                                            <div className="col-5">
                                                <p className="semibold">Asset Type:</p>
                                            </div>
                                            <div className="col">
                                                {selectType}
                                            </div>
                                        </div>
                                        <div className="row no-gutters">
                                            <div className="col-5">
                                                <p className="semibold">Nickname:</p>
                                            </div>
                                            <div className="col">
                                                <input 
                                                    type="text"
                                                    id="nickname"
                                                    className="txt-detail"
                                                    value={(this.state.currentAsset === null || this.state.currentAsset === undefined) ? "" : this.state.currentAsset.nickname}
                                                    onChange={this.onChangeNickName}
                                                    />
                                            </div>
                                        </div>
                                        <div className="row no-gutters">
                                            <div className="col-5">
                                                <p className="semibold">Make:</p>
                                            </div>
                                            <div className="col">
                                                <input 
                                                    type="text"
                                                    id="make"
                                                    className="txt-detail"
                                                    value={this.state.currentAsset === null || this.state.currentAsset === undefined ? "" : this.state.currentAsset.make}
                                                    onChange={this.onChangeMake}
                                                />
                                            </div>
                                        </div>
                                        <div className="row no-gutters">
                                            <div className="col-5">
                                                <p className="semibold">Model:</p>
                                            </div>
                                            <div className="col">
                                                <input 
                                                    type="text"
                                                    id="model"
                                                    className="txt-detail"
                                                    value={this.state.currentAsset === null || this.state.currentAsset === undefined ? "" : this.state.currentAsset.model}
                                                    onChange={this.onChangeModel}
                                                />
                                            </div>
                                        </div>
                                        <div className="row no-gutters">
                                            <div className="col-5">
                                                <p className="semibold">Serial Number:</p>
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    id="serialNumber"
                                                    className="txt-detail"
                                                    value={this.state.currentAsset === null || this.state.currentAsset === undefined ? "" : this.state.currentAsset.serialNumber}
                                                    onChange={this.onChangeSerialNumber}
                                                />
                                            </div>
                                        </div>
                                        <div className="row no-gutters">
                                            <div className="col-5">
                                                <p className="semibold">Manufacturing Date:</p>
                                            </div>
                                            <div className="col">
                                                <input 
                                                    type="text"
                                                    id="mfgDate"
                                                    className="txt-detail" 
                                                    value={this.state.currentAsset === null || this.state.currentAsset === undefined ? "" : this.state.currentAsset.mfgDate}
                                                    onChange={this.onChangeMFGDate}
                                                />
                                            </div>
                                        </div>
                                        {(this.state.currentAsset !== null && this.state.currentAsset !== undefined) && this.state.currentAsset.type.trim() === "AirConditioner" && <>
                                        <div className="row no-gutters" >
                                            <div className="col-5">
                                                <p className="semibold">Compressor RLA:</p>
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    id="compressorRla"
                                                        className="txt-detail"
                                                        value={this.state.currentAsset.compressorRatedLoadAmps !== null && this.state.currentAsset.compressorRatedLoadAmps !== undefined ? this.state.currentAsset.compressorRatedLoadAmps : ""}
                                                    onChange={this.onChangeCompressorRla}
                                                />
                                            </div>
                                        </div >
                                        <div className="row no-gutters">
                                            <div className="col-5">
                                                <p className="semibold">Compressor LRA:</p>
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    id="compressorLra"
                                                        className="txt-detail"
                                                        value={this.state.currentAsset.compressorLockedRotorAmps !== null && this.state.currentAsset.compressorLockedRotorAmps !== undefined ? this.state.currentAsset.compressorLockedRotorAmps : ""}
                                                    onChange={this.onChangeCompressorLra}
                                                />
                                            </div>
                                        </div>
                                        <div className="row no-gutters">
                                            <div className="col-5">
                                                <p className="semibold">Fan FLA:</p>
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    id="fanFla"
                                                        className="txt-detail"
                                                        value={this.state.currentAsset.fanFullLoadAmps !== null && this.state.currentAsset.fanFullLoadAmps !== undefined ? this.state.currentAsset.fanFullLoadAmps : ""}
                                                    onChange={this.onChangeFanFla}
                                                />
                                            </div>
                                        </div>
                                        <div className="row no-gutters">
                                            <div className="col-5">
                                                <p className="semibold">Fan LRA:</p>
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    id="fanLra"
                                                        className="txt-detail"
                                                        value={this.state.currentAsset.fanLockedRotorAmps !== null && this.state.currentAsset.fanLockedRotorAmps !== undefined ? this.state.currentAsset.fanLockedRotorAmps : ""}
                                                    onChange={this.onChangeFanLra}
                                                />
                                            </div>
                                        </div>
                                        </> }
                                        <div className="row no-gutters lastItem margin-top-5">
                                            <button className="secondary-btn btn-small" onClick={this.openUpdateAssetModal}>Update Asset</button>
                                            <button className="secondary-btn btn-small" onClick={this.openUpdateAssetClassModal}>Update Asset Classes</button>
                                            {deleteButton}
                                            <CommentButton assetId={this.state.currentAsset.assetId}  disabled={this.state.isSelected === -1} onClick={this.openAssetCommentsModal}/>
                                        </div>
                                    </div>
                                </div>

                                {testSystemButton}
                                {systemButtons}
                            </div>
                        </div>

                        <p className="status-msg">{this.state.statusMessage}</p>
                        <br />
                        {this.renderGraph()}
                        {graphLoader}
                        {energyDataTable}
                    </div>

                    <AddUnparentedModal unparentedAssets={this.state.unparentedAssets} onClick={this.showAddUnparentedAssetModal} show={this.state.showAddUnparentedAsset}
                        onSave={this.addUnparentedAsset} onChange={this.onUnparentedAssetSelect} >
                    </AddUnparentedModal>

                    <UpdateAssetClassModal onClick={this.showUpdateAssetClassModal} show={this.state.showUpdateAssetClass} appliedClasses={this.state.appliedClasses} unappliedClasses={this.state.unappliedClasses}
                        onUpdateClasses={this.updateClasses} handleApply={this.handleApply} handleRemove={this.handleRemove} updateLoading={this.state.updateClassesLoading} >
                    </UpdateAssetClassModal>

                    <TestSystemModal onClick={this.showTestSystemModal} show={this.state.showTestSystem} onSystemSelect={this.onSystemSelect} onSave={this.onRunRoutine} systems={this.state.assetList} systemTestLoading={this.state.systemTestLoading} systemTestResult={this.state.systemTestResult} />

                    <TurnOffSystemModal onClick={this.showTurnOffSystemModal} show={this.state.showTurnOffSystem} onTurnOff={this.onTurnOffSystem} systemTurnOffLoading={this.state.systemTurnOffLoading} />
                    <Snackbar open={this.state.showTurnOffMessage} message={this.state.turnOffMessage} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} />
                    <ConfirmationModal show={this.state.showDeleteConfirmation} onYes={this.deleteAsset} onClick={this.showDeleteAssetModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>

                    <ConfirmationModal show={this.state.showUpdateConfirmation} onYes={this.updateAsset} onClick={this.showUpdateAssetModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>

                    <ConfirmationModal show={this.state.showInfoModal} onClick={this.showInfoModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>

                    <ConfirmationModal show={this.state.showFavoriteModal} onYes={this.updateAssetFavorites} onClick={this.showFavoriteModal} message={this.state.modalMessage} title={this.state.modalTitle}> </ConfirmationModal>

                    <CommentsModal show={this.state.showCommentsModal} onClick={this.showAssetCommentsModal} conversation={this.state.currentTypeConversation} alert={null} asset={this.state.currentAsset} message={this.state.modalMessage} 
                        tabIndex={null} addConversationToAlert={this.addConversationToAlert} addTagToAlert={this.addTagToAlert} conversationId={this.state.conversationId} assetConversation={this.state.assetConversation} currentAlertType={this.state.currentType} alertTypes={this.state.alertTypes}
                        userOrg={this.props.userOrg} resetConvo={this.resetConvo} addConversationToAsset={this.addConversationToAsset} alertComment={this.state.alertComment} updateAlertComment={this.updateAlertComment} commentDict={this.state.commentDict} assetComments={this.state.assetComments}
                        commentAlert={this.state.commentAlert} commentType={"ASSET"} source={"ASSETS"}  userName={this.props.userName} updateAssetComments={comments => { this.setState({ assetComments: comments }); }} ></CommentsModal>
                </div>
            );
        }
        else if (this.props.currentCustomerId === -1) {
            return (
                <div>
                    <p className="status-msg">No customer asset information to display.</p>
                </div>
            );
        }
        else {
            return (<div className={"margin-left-10"}><br />No Assets Located!</div>);
        }
    }

    render() {
        const {classes} = this.props;
        let assets = this.state.assetList.length > 0 ? this.state.assetList : this.state.assetList;
        let assetContents = this.state.loading
            ? <Loader />
            : this.renderAssets(assets);
        let technicianInfo = this.state.currentHVACSystem.hasOwnProperty('children') && this.state.currentHVACSystem.children.length ? <div className="white-container">
            <div className="select-container-3">
                <TechnicianView
                    macId={this.state.currentHVACSystem.children[0].name}  />
            </div>
        </div> : <div></div>

        let
            runGraph = (this.state.currentHighchartSource != null && true && this.state.currentHighchartSource.data !== null && this.state.currentHighchartSource.data !== undefined && this.state.currentHighchartSource.data[0] !== null && this.state.currentHighchartSource.data[0] !== undefined && this.state.currentSource != null && true && this.state.graphData.equipmentRuns !== null) ?
                <div>
                    <GraphComponent graphType={GraphTypes.ASSET}
                                    chartData={this.getHighchartsData2(this.state.currentHighchartSource, this.state.currentSource.graphAnnotations)}
                                    key={`${this.state.currentAsset.assetId}-${this.state.currentSource.dataArray.length}-${this.state.currentRunName}-${this.state.visibility}`}
                                    assetType={this.state.acSelected ? Equipment.AIR_CONDITIONER : this.state.thermostatSelected ? Equipment.THERMOSTAT : Equipment.FURNACE}
                                    visibility={this.state.visibility}
                                    rlaValue={this.state.graphDataAC.rla} flaValue={null}
                                    title={this.getTimestring(this.state.currentRunName)}
                                    lraValue={this.state.graphDataAC.lra}
                                    hasAnalytics={this.state.currentAsset.hasAnalytics}
                                    graphAnnotations={this.getHighchartsAnnotations(this.state.currentSource.graphAnnotations)}
                                    predictions={this.state.currentPredictions}
                                    pointStart={this.state.currentHighchartSource.data[0]}
                                    secondAxisType={this.state.acSelected ? AxisTypes.TEMP : AxisTypes.NONE}/>
                </div>
                : <div></div>;

        let timelineGraph = (this.state.currentTimeline != null && true && this.state.currentTimeline.data !== null && this.state.currentTimeline.data !== undefined && this.state.currentTimeline[0] !== null) ?
            <GraphComponent graphType={GraphTypes.ASSET} chartData={this.getHighchartsData2(this.state.currentTimeline, this.state.timelineAnnotations, true)}
                            key={`${this.state.currentAsset.assetId}-${this.state.graphData.length}-${this.state.visibility}`}
                            assetType={this.state.acSelected ? Equipment.AIR_CONDITIONER : this.state.thermostatSelected ? Equipment.THERMOSTAT : Equipment.FURNACE}
                            visibility={this.state.visibility} rlaValue={this.state.graphDataAC.rla}
                            flaValue={null} title={this.getTimestring(this.state.currentRunName)}
                            lraValue={this.state.graphDataAC.lra}
                            hasAnalytics={this.state.currentAsset.hasAnalytics}
                            graphAnnotations={this.getHighchartsAnnotations(this.state.timelineAnnotations)}
                            pointStart={this.state.currentTimeline.data[0]}
                            secondAxisType={this.state.acSelected ? AxisTypes.TEMP : AxisTypes.NONE} predictions={null} />
            : <div></div>;

        let fullScreenChartModal = (this.state.currentSource != null && this.state.currentSource !== undefined) ?
            <FullScreenChartModal show={this.state.showGraphModal} onClick={this.showGraphModal} title={"Graph"}
                                  graph={runGraph}/>
            : <div></div>;

        let fullScreenTimelineModal = (this.state.timelineData != null && this.state.timelineData !== undefined) ?

            <FullScreenChartModal show={this.state.showTimelineModal} onClick={this.showTimelineModal}
                                  title={"Last 24 Hours"} graph={timelineGraph}/>
            : <div></div>;
        return (
            <div sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
                <div className='infoPage'>
                    <div className="row no-gutters">
                        <div className="col">
                            <h3 className="pageTitle" id="tabelLabel">Assets</h3>
                        </div>
                        <div className="col pageTitle">
                            <div className="btn-right">
                                <button className="secondary-btn btn-large width-160"
                                        onClick={this.openAddUnparentedAssetModal}>+ Unparented Asset
                                </button>
                            </div>
                        </div>
                    </div>
                    <AssetHierarchyView accountId={this.props.currentCustomerId} fromAssetId={this.state.isDeleted ? 0: undefined} onHVACSystemSelect={this.onHVACSystemSelect}>
                        {(!this.state.isCustomerSupport) && <button
                            type="submit"
                            className="secondary-btn btn-small margin-left-5"
                            onClick={() => this.showConfirmationModal()}
                        >
                            Delete HVAC
                        </button>}
                    </AssetHierarchyView>
                    {technicianInfo}
                    {assetContents}

                    {fullScreenChartModal}
                    {fullScreenTimelineModal}

                    <ConfirmationModal
                        show={this.state.showConfirmation}
                        onYes={this.showPasswordConfirmationModal}
                        onClick={this.cancelAccountDelete}
                        message={<UserAssetList data={this.state.userassethierarchy}/>}
                        loading={this.state.loading}
                        title={this.state.modalTitle} />
                    
                    {<ConfirmPasswordModal
                        show={this.state.showPasswordConfirmation}
                        onCancelClick={this.showPasswordConfirmationModal}
                        onYes={this.showDeleteAccounModal}
                        onLogOff={this.props.onLogOff}/>}

                    <ConfirmDeleteAccountModal
                        title="System"
                        onClick={this.showDeleteAccounModal}
                        show={this.state.showDeleteAccount}
                        onConfirm={this.onConfirmCancel}
                        accountId={this.props.currentCustomerId}
                        messageBody={this.state.message}
                        loading={this.state.waitDelete}
                        isDeleted={this.state.isDeleted}
                        isActiveBillingPlan={this.state.isActiveBillingPlan}
                        onCancelClick={this.cancelAccountDelete}
                    />

                    <ConfirmationModal 
                        show={this.state.showTTConfirm} 
                        onYes={this.onTTConfirm}
                        onClick={this.showTTConfirmModal} 
                        message={this.state.modalMessage}
                        title={this.state.modalTitle} />
                </div>
            </div>
        );
    }

}

export default withRouter(Assets);
