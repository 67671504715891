/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";

import {Event} from "../GoogleAnalytics";

import SelectPartner from "./SelectPartner";
import SelectCustomer from "./SelectCustomer";
import SearchCustomer from "./SearchCustomer";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {allRoleList, roles} from "../../componentObjects";
import IconButton from "@mui/material/IconButton";

const StyledSideButton = styled.div`
  height: 50px;
  width: 100%; /* width must be same size as NavBar to center */
  text-align: left; /* Aligns <a> inside of NavIcon div */
  margin-bottom: 0;   /* Puts space between NavItems */
  margin-left: 10px;
  a {
    font-size: 1.2em;
    color: black;
    :hover {
      opacity: 0.7;
      text-decoration: none; /* Gets rid of underlining of icons */
      color: blue;
    }  
  }
`;

const StyledNavItem = styled.div`
  height: 40px;
  width: 100%; /* width must be same size as NavBar to center */
  text-align: left; /* Aligns <a> inside of NavIcon div */
  margin-bottom: 0;   /* Puts space between NavItems */
  margin-left: 10px;
  a {
    font-size: 1em;
    color: ${(props) => props.active ? "#0033a0" : "black"};
    :hover {
      opacity: 0.7;
      text-decoration: none; /* Gets rid of underlining of icons */
      color: red;
    }  
  }
`;


/* This defines the actual bar going down the screen */
const StyledSideNav = styled.div`
  position: fixed;     /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
  height: 100%;
  width: 230px;     /* Set the width of the sidebar */
  z-index: 4;       /* Stay on top of everything */

  background-color: #ffffff;
  overflow-x: hidden;     /* Disable horizontal scroll */
  overflow-y: scroll;
  padding-top: 50px;
  padding-bottom: 30px;
  border-right: 1px solid #ebeef0;
`;

const SectionTitles = {
    MONITOR: "Monitor",
    OPERATIONS: "Operations",
    SCHEDULER: "Scheduler",
    TECHNICIAN: "Technician",
    CUSTOMER: "Customer",
    PARTNER: "Partner",
    TOOLS: "Tools"
};
const SectionTitleList = [] 

class SideNav extends React.Component {

    constructor(props) {
        super(props);

    /* LAST KEY USED: 63 */

        this.onExpandClick = this.onExpandClick.bind(this);

        this.state = {
            activePath: this.props.activePath,
            expanded: [],
            monitorExpanded: false,
            operationsExpended: false,
            schedulerExpanded: false,
            technicianExpanded: false,
            customerExpanded: false,
            partnerExpanded: false,
            toolsExpanded: false,
            
            acExpanded: false,
            furnaceExpanded: false,
            airHandlerExpanded: false,
            triageExpanded: false,
            assetsExpanded: false,
            items: [
                {
                    path: '#',
                    name: 'Monitor',
                    text: 'Monitor',
                    css: '',
                    allowedRoles: allRoleList, 
                    key: 1,
                    icon: 'fa-solid fa-chart-line',
                    isSection: true,
                    hasChildren: true,
                    children: [
                        {
                            path: '/monitoringDashboard',
                            name: 'Monitoring Dashboard',
                            text: 'Monitoring Dashboard',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin, roles.customerSupport],
                            key: 25
                        },
                        {
                            path: '/triageAlerts',
                            name: 'Triage Alerts',
                            text: 'Triage Alerts',
                            css: 'fas fa-hashtag',
                            allowedRoles: allRoleList,
                            key: 3
                        },
                        {
                            path: '/alertHistory',
                            name: 'Alert History',
                            text: 'Alert History',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.customerSupport, roles.operations, roles.partnerAdmin, roles.scheduler],
                            key: 4
                        },
                        {
                            path: '/alertQuestions',
                            name: 'Alert Questions',
                            text: 'Alert Questions',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin,  roles.operations],
                            key: 31
                        }
                    ]
                },
                // {
                //     path: '/map',
                //     name: 'Map',
                //     text: 'Map',
                //     css: 'fas fa-hashtag',
                //     allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin, roles.customerSupport],
                //     key: 5
                // },
                {
                    path: '#',
                    name: 'Operations',
                    text: 'Operations',
                    css: '',
                    allowedRoles: [roles.superAdmin, roles.admin, roles.operations],//, roles.partnerAdmin],
                    key: 32,
                    icon: 'fa-solid fa-temperature-arrow-up',
                    isSection: true,
                    hasChildren: true,
                    children: [
                        {
                            path: '/operationalDashboard',
                            name: 'Operational Dashboard',
                            text: 'Operational Dashboard',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                            key: 38
                        },
                        {
                            path: '#',
                            name: 'Alert Triage',
                            text: 'Alert Triage',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                            key: 46,
                            hasChildren: true,
                            children: [
                                {
                                    path: '/lwTriageAlerts',
                                    name: 'LifeWhere Triage Alerts',
                                    text: 'LifeWhere Triage Alerts',
                                    css: 'fas fa-hashtag',
                                    allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                                    key: 2
                                },
                                {
                                    path: '/alertTriageDashboard',
                                    name: 'Alert Triage Dashboard',
                                    text: 'Alert Triage Dashboard',
                                    css: 'fas fa-hashtag',
                                    allowedRoles: [roles.superAdmin, roles.supportAdmin],
                                    key: 47
                                },
                                {
                                    path: '/alertTriageDetails',
                                    name: 'Alert Triage Details',
                                    text: 'Alert Triage Details',
                                    css: 'fas fa-hashtag',
                                    allowedRoles: [roles.superAdmin, roles.supportAdmin],
                                    key: 48
                                }
                            ]
                        }
                    ]
                },
                
                /*{
                    path: '#',
                    name: 'AC Onboarding',
                    text: 'AC Onboarding',
                    css: 'fas fa-hashtag',
                    allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                    key: 38,
                    hasChildren: true,
                    children: [
                        {
                            path: '/airConditionerOnboarding',
                            name: 'Dashboard', //'Air Conditioner Onboarding',
                            text: 'Dashboard', //'Air Conditioner Onboarding',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                            key: 33
                        },
                        {
                            path: '/acAssetTrainingReview',
                            name: 'Asset Training Review',
                            text: 'Asset Training Review',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                            key: 34
                        },
                        {
                            path: '/acTrainingAlertCountReview',
                            name: 'Training Alert Count Review',
                            text: 'Training Alert Count Review',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                            key: 35
                        },
                    ]
                },
                {
                    path: '#',
                    name: 'Furnace Onboarding',
                    text: 'Furnace Onboarding',
                    css: 'fas fa-hashtag',
                    allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                    key: 39,
                    hasChildren: true,
                    children: [
                        {
                            path: '/furnaceOnboarding',
                            name: 'Dashboard',//'Furnace Onboarding',
                            text: 'Dashboard',//'Furnace Onboarding',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                            key: 40
                        },
                        {
                            path: '/furnaceAssetTrainingReview',
                            name: 'Asset Training Review',
                            text: 'Asset Training Review',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                            key: 41
                        },
                        {
                            path: '/furnaceTrainingAlertCountReview',
                            name: 'Training Alert Count Review',
                            text: 'Training Alert Count Review',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                            key: 42
                        },
                    ]
                },
                {
                    path: '#',
                    name: 'Air Handler Onboarding',
                    text: 'Air Handler Onboarding',
                    css: 'fas fa-hashtag',
                    allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                    key: 58,
                    hasChildren: true,
                    children: [
                        {
                            path: '/airHandlerOnboarding',
                            name: 'Dashboard',//'Furnace Onboarding',
                            text: 'Dashboard',//'Furnace Onboarding',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                            key: 59
                        },
                        {
                            path: '/airHandlerAssetTrainingReview',
                            name: 'Asset Training Review',
                            text: 'Asset Training Review',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                            key: 60
                        },
                        {
                            path: '/airHandlerTrainingAlertCountReview',
                            name: 'Training Alert Count Review',
                            text: 'Training Alert Count Review',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.operations],
                            key: 61
                        },
                    ]
                },*/
                {
                    path: '#',
                    name: 'Scheduler',
                    text: 'Scheduler',
                    css: '',
                    allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin, roles.customerSupport, roles.scheduler],
                    key: 6,
                    icon: 'fa-regular fa-calendar',
                    isSection: true,
                    hasChildren: true,
                    children: [
                        {
                            path: '/scheduleRepairs',
                            name: 'Schedule Repairs',
                            text: 'Schedule Repairs',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin, roles.customerSupport, roles.scheduler],
                            key: 7
                        }
                    ]
                },
                
                {
                    path: '#',
                    name: 'Technician',
                    text: 'Technician',
                    css: '',
                    allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin, roles.customerSupport, roles.technician],
                    key: 8,
                    icon: 'fa-solid fa-screwdriver-wrench',
                    isSection: true,
                    hasChildren: true,
                    children: [
                        {
                            path: '/currentJob',
                            name: 'Current Job',
                            text: 'Current Job',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin, roles.technician, roles.customerSupport],
                            key: 9
                        },
                        {
                            path: '/training',
                            name: 'Training',
                            text: 'Training',
                            css: 'fas fa-hashtag',
                            allowedRoles: allRoleList,
                            key: 62
                        }
                    ]
                },
                
                {
                    path: '#', /* null path indicates section label*/
                    name: 'Customer', /*previously 'Configuration'*/
                    text: 'Customer',
                    css: '',
                    allowedRoles: [roles.superAdmin, roles.admin, roles.customerSupport, roles.operations, roles.partnerAdmin, roles.technician, roles.scheduler],
                    key: 10,
                    icon: 'fa-solid fa-house-chimney-user',
                    isSection: true,
                    hasChildren: true,
                    children: [
                        {
                            path: '/account', /* path is used as id to check which NavItem is active basically */
                            name: 'Account',
                            text: 'Account',
                            css: 'fa fa-fw fa-home',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.customerSupport, roles.operations, roles.partnerAdmin, roles.technician, roles.scheduler],
                            key: 11 /* Key is required, else console throws error. */
                        },
                        {
                            path: '/customerAlerts',
                            name: 'Alerts',
                            text: 'Alerts',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.customerSupport, roles.operations, roles.partnerAdmin, roles.technician, roles.scheduler],
                            key: 29
                        },
                        {
                            path: '/home',
                            name: 'Home',
                            text: 'Home',
                            css: 'fa fa-fw fa-clock',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin, roles.technician, roles.scheduler, roles.customerSupport],
                            key: 12
                        },
                        {
                            path: '/assets',
                            name: 'Assets',
                            text: 'Assets',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.customerSupport, roles.operations, roles.partnerAdmin, roles.technician, roles.scheduler],
                            key: 13
                        },
                        {
                            path: '/devices',
                            name: 'Devices',
                            text: 'Devices',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.customerSupport, roles.operations, roles.partnerAdmin, roles.technician, roles.scheduler],
                            key: 14
                        },
                        {
                            path: '/installCheck',
                            name: 'InstallationCheck',
                            text: 'Installation Check',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.customerSupport, roles.operations, roles.partnerAdmin, roles.technician, roles.scheduler],
                            key: 27
                        },
                        {
                            path: '/billingInfo',
                            name: 'Billing Info',
                            text: 'Billing Info',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin, roles.customerSupport],
                            key: 36
                        }
                    ]
                },
                
                {
                    path: '#',
                    name: 'Partner', /*previously 'Account Management'*/
                    text: 'Partner',
                    css: '',
                    allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin, roles.customerSupport],
                    key: 17,
                    icon: 'fa-solid fa-people-roof',
                    isSection: true,
                    hasChildren: true,
                    children: [
                        {
                            path: '/accountmanagement',
                            name: 'Account',
                            text: 'Account',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin, roles.customerSupport],
                            key: 18
                        },
                        {
                            path: '/subscriptions',
                            name: 'Subscriptions',
                            text: 'Subscriptions',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin, roles.customerSupport],
                            key: 37
                        },
                        {
                            path: '/installReport',
                            name: 'Install Report',
                            text: 'Install Report',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin],
                            key: 53
                        },
                        {
                            path: '/failedInstallsReport',
                            name: 'Failed Installs Report',
                            text: 'Failed Installs Report',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin],
                            key: 63
                        },
                        {
                            path: '/customizeAlerts',
                            name: 'Customize Alerts',
                            text: 'Customize Alerts',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin],
                            key: 56
                        },
                        {
                            path: '/users',
                            name: 'Users',
                            text: 'Users',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin, roles.customerSupport],
                            key: 19
                        },
                        {
                            path: '/inviteHomeowner',
                            name: 'Invite Homeowner',
                            text: 'Invite Homeowner',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin, roles.scheduler, roles.customerSupport],
                            key: 43
                        },
                        {
                            path: '/energyMetrics',
                            name: 'Energy Metrics',
                            text: 'Energy Metrics',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin],
                            key: 45
                        },
                        {
                            path: '/fleet',
                            name: 'Fleet',
                            text: 'Fleet',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.partnerAdmin],
                            key: 57
                        },
                        {
                            path: '#',
                            name: 'Deleted Assets',
                            text: 'Deleted Assets',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.operations, roles.partnerAdmin],
                            key: 52,
                            hasChildren: true,
                            children: [
                                {
                                    path: '/deletedAccounts',
                                    name: 'Deleted Accounts', //'Air Conditioner Onboarding',
                                    text: 'Deleted Accounts', //'Air Conditioner Onboarding',
                                    css: 'fas fa-hashtag',
                                    allowedRoles: [roles.superAdmin, roles.admin, roles.operations, roles.partnerAdmin],
                                    key: 50
                                },
                                {
                                    path: '/deletedSystems',
                                    name: 'Deleted Systems',
                                    text: 'Deleted Systems',
                                    css: 'fas fa-hashtag',
                                    allowedRoles: [roles.superAdmin, roles.admin, roles.operations, roles.partnerAdmin],
                                    key: 54
                                },
                            ]
                        }
                    ]
                },
                {
                    path: '#',
                    name: 'Tools',
                    text: 'Tools',
                    css: '',
                    allowedRoles: [roles.superAdmin, roles.admin],
                    key: 20,
                    icon: 'fa-solid fa-toolbox',
                    isSection: true,
                    hasChildren: true,
                    children: [
                        {
                            path: '/provisioning',
                            name: 'Provisioning',
                            text: 'Provisioning',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.admin],
                            key: 16
                        },
                        {
                            path: '/emailConfirmation',
                            name: 'Email Confirmation',
                            text: 'Email Confirmation',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin],
                            key: 28
                        },
                        {
                            path: '/emailStatus',
                            name: 'Email Status',
                            text: 'Email Status',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin],
                            key: 44
                        },
                        {
                            path: '/inviteAdmins',
                            name: 'Invite Admins',
                            text: 'Invite Admins',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin],
                            key: 49
                        },
                        {
                            path: '/iottest',
                            name: 'IotTest',
                            text: 'IotTest',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin],
                            key: 21
                        },
                        {
                            path: '/accessKeyTool',
                            name: 'AccessKeyTool',
                            text: 'AccessKeyTool',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin],
                            key: 22
                        },
                        {
                            path: '/otaLogs',
                            name: 'OTA Logs',
                            text: 'OTA Logs',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin],
                            key: 51
                        },
                        {
                            path: '/manageAssets',
                            name: 'ManageAssets',
                            text: 'ManageAssets',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin, roles.qa],
                            key: 23
                        },
                        // {
                        //     path: '/usermanagement',
                        //     name: 'User management',
                        //     text: 'User management',
                        //     css: 'fas fa-hashtag',
                        //     allowedRoles: [roles.superAdmin, roles.admin],
                        //     key: 24
                        // },
                        {
                            path: '/addPartner',
                            name: 'Add Partner',
                            text: 'Add Partner',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin],
                            key: 30
                        },
                        {
                            path: './alertMessaging',
                            name: 'Alert Messaging',
                            text: 'Alert Messaging',
                            css: 'fas fa-hashtag',
                            allowedRoles: [roles.superAdmin, roles.admin],
                            key: 55
                        }
                    ]
                },
                
                {
                    path: '/',
                    name: 'Version',
                    text: 'Version',
                    css: 'fas fa-hashtag',
                    allowedRoles: allRoleList,
                    key: 26,
                    icon: 'fa-solid fa-gear'
                },
            ],
            customerSelect: true,
            partnerSelect: true
        }
    }

    onItemClick = (path) => {
        Event("Sidebar Click", "Sidebar item clicked with path of " + path, "Sidebar Menu Item Clicked");
        let unsavedChanges = localStorage.getItem("unsavedChanges");
        unsavedChanges = unsavedChanges !== null ? unsavedChanges : false;
        if (unsavedChanges === 'true') {
            console.log(`sideNav onItemClick() - unsavedChanges: ${unsavedChanges}`)
            this.props.setActivePath(path, true);
        }
        else {
            this.setState({ activePath: path }, () => {
                this.props.setActivePath(this.state.activePath, true);
            }); /* Sets activePath which causes rerender which causes CSS to change */
        }
    }

    onExpandLabelClick = (e) => {
        
    }

    onExpandClick(name) { 
        if (name === SectionTitles.MONITOR) {
            this.setState({ monitorExpanded: !this.state.monitorExpanded });
        }
        else if (name === SectionTitles.OPERATIONS) {
            this.setState({ operationsExpanded: !this.state.operationsExpanded });
        }
        else if (name === SectionTitles.SCHEDULER) {
            this.setState({ schedulerExpanded: !this.state.schedulerExpanded });
        }
        else if (name === SectionTitles.TECHNICIAN) {
            this.setState({ technicianExpanded: !this.state.technicianExpanded });
        }
        else if (name === SectionTitles.CUSTOMER) {
            this.setState({ customerExpanded: !this.state.customerExpanded });
        }
        else if (name === SectionTitles.PARTNER) {
            this.setState({ partnerExpanded: !this.state.partnerExpanded });
        }
        else if (name === SectionTitles.TOOLS) {
            this.setState({ toolsExpanded: !this.state.toolsExpanded });
        }
        else if (name === "AC Onboarding") {
            this.setState({ acExpanded: !this.state.acExpanded });
        }
        else if (name === "Furnace Onboarding") {
            this.setState({ furnaceExpanded: !this.state.furnaceExpanded });
        }
        else if (name === "Air Handler Onboarding") {
            this.setState({ airHandlerExpanded: !this.state.airHandlerExpanded });
        }
        else if (name === "Alert Triage") {
            this.setState({ triageExpanded: !this.state.triageExpanded });
        }
        else if (name === "Deleted Assets") {
            this.setState({ assetsExpanded: !this.state.assetsExpanded });
        }
    }

    checkIfEssential(name) {
        let isEssential = false;
        switch (name) {
            case 'Monitor':
                isEssential = true;
                break;
            case 'Dashboard':
                isEssential = true;
                break;
            case 'Triage Alerts':
                isEssential = true;
                break;
            case 'Customer':
                isEssential = true;
                break;
            case 'Account':
                isEssential = true;
                break;
            case 'Home':
                isEssential = true;
                break;
            case 'Assets':
                isEssential = true;
                break;
            case 'Devices':
                isEssential = true;
                break;
            case 'Partner':
                isEssential = true;
                break;
            default:
                isEssential = false;
                break;
        }

        return isEssential;
    }
    
    returnChildren(children) {
        return (<div className={"pad-left-10"}>
            {children.map((child) => {
                if (child.hasChildren) {
                    let isExpanded = this.getIsExpanded(child.name);

                    let arrowIcon = isExpanded ?
                        <ExpandLessIcon className="sidebar-expand"/>
                        : <ExpandMoreIcon className="sidebar-expand"/>

                    return (
                        <div key={child.key}>
                            <div className={child.isSection ? 'sidebar-section' : 'sidebar-container'} onClick={() => {
                                this.onExpandClick(child.name)
                            }}>

                                <div className={`sidebar-item ${child.name.replace(/\s/g, "").toLowerCase()}-unselectable`} >
                                    {this.getIcon(child.icon)}
                                    <div className="unselectable sidebar-child-item" key={child.key}>
                                        {child.name}
                                    </div>

                                </div>
                                <IconButton
                                    aria-label="arrow button"
                                    className={"sidebar-child-item"}
                                    onClick={() => {
                                        this.onExpandClick(child.name)
                                    }}
                                >
                                    {arrowIcon}
                                </IconButton>
                            </div>

                            {this.getChildren(child)}
                        </div>
                    );
                }
                else {
                    return (<NavItem path={child.path} name={child.name} text={child.text} onItemClick={this.onItemClick}
                             active={child.path === this.props.activePath} key={child.key}/>);
                }
            })}
        </div>)
    }

    getChildren(item) {
        let children = <div>{null}</div>;

        if (item.name === SectionTitles.MONITOR) {
            if (this.state.monitorExpanded) {
                children = this.returnChildren(item.children);
            }
        } else if (item.name === SectionTitles.OPERATIONS) {
            if (this.state.operationsExpanded) {
                children = this.returnChildren(item.children);
            }
        }
        else if (item.name === SectionTitles.SCHEDULER) {
            if (this.state.schedulerExpanded) {
                children = this.returnChildren(item.children);
            }
        }
        else if (item.name === SectionTitles.TECHNICIAN) {
            if (this.state.technicianExpanded) {
                children = this.returnChildren(item.children);
            }
        }
        else if (item.name === SectionTitles.CUSTOMER) {
            if (this.state.customerExpanded) {
                children = this.returnChildren(item.children);
            }
        }
        else if (item.name === SectionTitles.PARTNER) {
            if (this.state.partnerExpanded) {
                children = this.returnChildren(item.children);
            }
        }
        else if (item.name === SectionTitles.TOOLS) {
            if (this.state.toolsExpanded) {
                children = this.returnChildren(item.children);
            }
        }
        else if (item.name === "AC Onboarding") {
            if (this.state.acExpanded) {
                children = this.returnChildren(item.children);
            }
        }
        else if (item.name === "Furnace Onboarding"){
            if (this.state.furnaceExpanded) {
                children = this.returnChildren(item.children);
            }
        }
        else if (item.name === "Air Handler Onboarding"){
            if (this.state.airHandlerExpanded) {
                children = this.returnChildren(item.children);
            }
        }
        else if (item.name === "Alert Triage") {
            if (this.state.triageExpanded) {
                children = this.returnChildren(item.children);
            }
        }
        else if (item.name === "Deleted Assets") {
            if (this.state.assetsExpanded) {
                children = this.returnChildren(item.children);
            }
        }

        return (children);
    }
    
    getIcon(icon, isVersion = false) {
        if (icon === null || icon === undefined) {
            return null;
        }
        else {
            let className = isVersion ? icon + " sidebar-icon-version" : icon + " sidebar-icon";
            return(
                <i key={icon} className={className}></i>
            )
        }
    }
    
    getIsExpanded(name) {
        return name === SectionTitles.MONITOR ? this.state.monitorExpanded :
            name === SectionTitles.OPERATIONS ? this.state.operationsExpanded :
                name === SectionTitles.SCHEDULER ? this.state.schedulerExpanded :
                    name === SectionTitles.TECHNICIAN ? this.state.technicianExpanded :
                        name === SectionTitles.CUSTOMER ? this.state.customerExpanded :
                            name === SectionTitles.PARTNER ? this.state.partnerExpanded :
                                name === SectionTitles.TOOLS ? this.state.toolsExpanded :
                                    name === "AC Onboarding" ? this.state.acExpanded :
                                        name === "Furnace Onboarding" ? this.state.furnaceExpanded :
                                            name === "Air Handler Onboarding" ? this.state.airHandlerExpanded :
                                                name === "Alert Triage" ? this.state.triageExpanded :
                                                    name === "Deleted Assets" ? this.state.assetsExpanded : false;
    }

    render() {
        const { classes, activePath } = this.props;
        
        let partnerSelect = this.state.partnerSelect ?
            <SelectPartner partners={this.props.partners} selectedPartner={this.props.selectedPartner} onPartnerSelect={this.props.onPartnerSelect} inFaveModal={false}/>
            : null;

        let customerSelect = this.state.customerSelect ?
            <SelectCustomer customers={this.props.customers} selectedCustomer={this.props.selectedCustomer} onCustomerSelect={this.props.onCustomerSelect} onAddToFavorites={this.props.onAddToFavorites}/>
            : null;

        const { items } = this.state;

        // Get the user roles in a usable array form: 
        let roles;
        try {
            if (!Array.isArray(this.props.roles)) {
                roles = JSON.parse(this.props.roles.replace(/'/g, '"'));
            }
            else {
                roles = this.props.roles;
            }
        } catch (error) {
            roles = [];
        }

        // Filter the list of sidebar items
        // If a user contains any of the roles needed to view a particular page, add the page to the filtered list.
        let filteredLinks = items.filter(link => {
            if (roles.some(role => link.allowedRoles.includes(role))) {
                if (link.hasChildren) {
                    link.children = link.children.filter(child => roles.some(role => child.allowedRoles.includes(role)))
                }
                return link;
            }
        });
        if (!this.props.hasBilling)
            filteredLinks = filteredLinks.filter(link => link.name !== "Billing Info" && link.name !== "Subscriptions");

        // If the users org is the Essentials Tier (i.e. serviceTier === "1"), limit the pages to only the essential pages.
        if (this.props.serviceTier === "1") {
            filteredLinks = filteredLinks.filter(link => this.checkIfEssential(link.name));
        }


        return (
            <StyledSideNav>
                <div>&nbsp;</div>
                {partnerSelect}
                {customerSelect}
                <SearchCustomer customers={this.props.customers} customerName={this.props.customerName}
                                onCustomerSearch={this.props.onCustomerSearch}
                                setCustomerList={this.props.setCustomerList} searchType={this.props.searchType}
                                setSearchType={this.props.setSearchType} emailLoaded={this.props.emailLoaded}
                                addressLoaded={this.props.addressLoaded}/>


                <div className="border-bottom">&nbsp;</div>
                {
                    filteredLinks.map((item) => {
                        if (item.path !== '#') {
                            if (item.name === "Version") {
                                return (
                                    <div
                                        className="sidebar-version-item"
                                        key={item.key}>
                                        {this.getIcon(item.icon, true)}
                                        <span className="margin-top-20">
                                            <NavItem path={item.path} name={item.name}
                                                                                   text={item.text}
                                                                                   onItemClick={this.onItemClick}
                                                                                   active={item.path === activePath}
                                                                                   key={item.key}/>
                                        </span>
                                    </div>
                                );
                            } else {
                                return (
                                    <div>
                                        <NavItem path={item.path} name={item.name} text={item.text}
                                                 onItemClick={this.onItemClick} active={item.path === activePath}
                                                 key={item.key}/>
                                    </div>
                                );
                            }
                        } 
                        else if (item.hasChildren) {
                            let isExpanded = this.getIsExpanded(item.name);

                            let arrowIcon = isExpanded ?
                                <ExpandLessIcon className="sidebar-expand"/>
                                : <ExpandMoreIcon className="sidebar-expand"/>
                            
                            let className = item.isSection ? 'sidebar-section-item' : 'sidebar-item';
                            className += ` ${item.name.replace(/\s/g, "").toLowerCase()}-unselectable`;

                            return (
                                <div key={item.key}>
                                    <div className={item.isSection ? "sidebar-section" : "sidebar-container"} tabIndex={0} onClick={() => {
                                        this.onExpandClick(item.name)
                                    }}>

                                        <div className={className}>
                                            {this.getIcon(item.icon)}
                                            <div className="unselectable sidebar-child-item" key={item.key}>
                                                {item.name}
                                            </div>

                                        </div>
                                        <IconButton
                                            aria-label="arrow button"
                                            className={"sidebar-child-item"}
                                            onClick={() => {
                                                this.onExpandClick(item.name)
                                            }}
                                        >
                                            {arrowIcon}
                                        </IconButton>
                                    </div>

                                    {this.getChildren(item)}
                                </div>
                            );
                        } else {
                            return (
                                <div className={"border-top"} onClick={() => {
                                    this.onExpandClick(item.name)
                                }}>
                                    {this.getIcon(item.icon)}
                                    <label key={item.key}>{item.text}</label>
                                </div>
                            );

                        }
                    })
                }
            </StyledSideNav>
        );
    }
}

export default SideNav;

class NavItem extends React.Component {

    handleClick = (e) => {
        const { text, path, onItemClick } = this.props;
        let unsavedChanges = localStorage.getItem("unsavedChanges");
        unsavedChanges = unsavedChanges !== null ? unsavedChanges === 'true' : false;
        
        if (unsavedChanges) {
            e.preventDefault();  
            localStorage.setItem("showUnsavedModal", true);
        }  
        onItemClick(path, text);        
    }

    render() {
        const { active } = this.props;

        return (
            <StyledNavItem active={active}>
                <Link to={this.props.path} className={this.props.css} onClick={e => this.handleClick(e)}>
                    {this.props.text}
                </Link>
            </StyledNavItem>
        );
    }
}