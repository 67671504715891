import React, {useState} from 'react';
import OnboardingDashboardCard from './OnboardingDashboardCard';


function TrainingFaultsCard(props) {
    const { assetType, onboardInfo, onTrainingFaultsClick } = props;
    const title = 'Asset Training Report (24 hours)';


    const getPercentage = (count) => {
        let total = onboardInfo.totalFaults;
        let percentage = (count / total) * 100;
        percentage = percentage.toFixed(2);

        return (percentage + "%");
    }

    let trainingFaultsContent = <div className="row no-gutters card-row text-center margin-8-0">
        <div className="col min-height-40">
            <div className={"margin-0-20"}>
                <table className={"width-100p margin-bottom-0"}>

                    <thead>
                        <tr className="alerts-table-row">
                            <th className="table-shrink table-header-left">
                                FAULT
                            </th>
                            <th className="table-shrink table-header-middle">
                                #
                            </th>
                            <th className="table-shrink table-header-right">
                                % (trained past 24hrs)
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {onboardInfo.trainingFaults !== undefined && onboardInfo.trainingFaults.map((fault, index) =>
                            <tr key={fault.failureId} data={index} className="alerts-table-row">
                                <td className={"table-data-left"}> {fault.name} </td>
                                <td className={"table-data-middle"}> {fault.count} </td>
                                <td className={"table-data-right"}> {getPercentage(fault.count)} </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    </div>;

    return (
        <div>
            <OnboardingDashboardCard title={`${assetType} Training Faults`} content={trainingFaultsContent} onClick={onTrainingFaultsClick} isGraphCard={false} />
        </div>
    );
}

export default TrainingFaultsCard;