/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import { Spinner } from 'react-bootstrap';
import Drawer from '@mui/material/Drawer';

const boolOptions = [
    {
        id: 0,
        value: 0,
        label: "false"
    },
    {
        id: 1,
        value: 1,
        label: "true"
    }
];

const propertyOptions = {
    blowerTypeName: [
        {
            id: 1,
            value: "PSC",
            label: "PSC"
        },
        {
            id: 2,
            value: "ECM",
            label: "ECM"
        },
        {
            id: 3,
            value: "Variable",
            label: "Variable"
        },
        {
            id: 4,
            value: "PSC-OnAtInduction",
            label: "PSC-OnAtInduction"
        },
        {
            id: 5,
            value: "ECM-OnAtInduction",
            label: "ECM-OnAtInduction"
        }
    ],
    blowerStageName: [
        {
            id: 1,
            value: "Single",
            label: "Single"
        },
        {
            id: 2,
            value: "Variable",
            label: "Variable"
        },
        {
            id: 3,
            value: "Dual",
            label: "Dual"
        }
    ],
    igniterTypeName: [
        {
            id: 1,
            value: "SiC",
            label: "SiC"
        },
        {
            id: 2,
            value: "SiN",
            label: "SiN"
        },
        {
            id: 3,
            value: "Spark",
            label: "Spark"
        },
        {
            id: 4,
            value: "HSP",
            label: "HSP"
        },
        {
            id: 5,
            value: "SiN-ShortWarmUp",
            label: "SiN-ShortWarmUp"
        },
        {
            id: 6,
            value: "SiN-DropFromInduction",
            label: "SiN-DropFromInduction"
        }
    ]
};

class FurnacePropertiesDrawer extends Component {

    constructor(props) {
        super(props);

        this.handleChangeProperty = this.handleChangeProperty.bind(this);
        this.handleSelectProperty = this.handleSelectProperty.bind(this);
        this.handleChangeIntegerProperty = this.handleChangeIntegerProperty.bind(this);

        this.onResetClick = this.onResetClick.bind(this);
        this.onApplyClick = this.onApplyClick.bind(this);

        this.state = {
            originalProps: this.props.assetProps,
            editProps: {},
            propsChanged: false,
            changedProps: [],
            selectedPropValues: {
                blowerTypeName: this.props.assetProps.blowerTypeName,
                blowerStageName: this.props.assetProps.blowerStageName,
                igniterTypeName: this.props.assetProps.igniterTypeName,
                supportsIgnitionFromBlowerOn: this.props.assetProps.supportsIgnitionFromBlowerOn,
                instantInduction: this.props.assetProps.instantInduction
            }
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onResetClick() {

    }

    onApplyClick() {

    }

    propertyCallback() {

    }

    handleChangeProperty = (prop) => (e) => {
        const newValue = e.target.value;
        const originalValue = this.props.assetProps.find(p => p.name == prop).value;

        let props = this.props.propsChanged ? this.props.editProps : this.props.assetProps;
        let propIndex = props.findIndex(p => p.name == prop);
        props[propIndex].value = parseFloat(newValue);

        let changedProps = this.props.changedProps;
        if (!changedProps.includes(prop) && originalValue !== newValue) {
            changedProps = changedProps.concat(prop);
        }
        else if (changedProps.includes(prop) && this.props.assetProps[propIndex].value == newValue) {
            changedProps = changedProps.filter(p => p !== prop);
        }

        this.setState({
            propsChanged: true,
            editProps: props,
            changedProps: changedProps
        }, () => {
            this.props.changeProperties(true, changedProps, props);
        });
    }

    handleChangeIntegerProperty = (prop) => (e) => {
        const newValue = e.target.value;
        const originalValue = this.props.assetProps.find(p => p.name == prop).value;
        let regex = /^[0-9]*$/;

        let changedProps = this.props.changedProps;
        let props = this.props.propsChanged ? this.props.editProps : this.props.assetProps;
        let propIndex = props.findIndex(p => p.name == prop);

        if (regex.test(newValue)) {
            props[propIndex].value = parseInt(e.target.value);

            if (!changedProps.includes(prop) && originalValue !== newValue) {
                changedProps = changedProps.concat(prop);
            }
            else if (changedProps.includes(prop) && this.props.assetProps[propIndex].value == newValue) {
                changedProps = changedProps.filter(p => p !== prop);
            }
        }

        this.setState({
            propsChanged: true,
            editProps: props,
            changedProps: changedProps
        }, () => {
            this.props.changeProperties(true, changedProps, props);
        });
    }

    handleSelectProperty = (prop) => (e) => {
        const newValue = e.target.value;
        const originalValue = this.props.assetProps.find(p => p.name == prop).value;

        let propType = this.props.propertyTypeList.find(p => p.name == prop).type;
        let props = this.props.propsChanged ? this.props.editProps : this.props.assetProps;
        let propIndex = props.findIndex(p => p.name == prop);
        props[propIndex].value = propType == "BOOL" ? parseInt(e.target.value) : e.target.value;

        let changedProps = this.props.changedProps;
        if (!changedProps.includes(prop) && originalValue !== newValue) {
            changedProps = changedProps.concat(prop);
        }
        else if (changedProps.includes(prop) && this.props.assetProps[propIndex].value == newValue) {
            changedProps = changedProps.filter(p => p !== prop);
        }

        let selectedProps = this.state.selectedPropValues;
        selectedProps[prop] = propType == "BOOL" ? parseInt(e.target.value) : e.target.value;

        this.setState({
            propsChanged: true,
            editProps: props,
            changedProps: changedProps,
            selectedPropValues: selectedProps
        }, () => {
                this.props.changeProperties(true, changedProps, props);
        });
    }

    getPropertyInput(prop, propertyTypeList) {
        let propType = propertyTypeList.find(p => p.name == prop.name) !== undefined ? propertyTypeList.find(p => p.name == prop.name).type : "STATIC";
        let propChanged = this.props.changedProps.includes(prop.name);
        let className = propChanged ? "edited-prop txt-detail" : "txt-detail";

        let propInput = <input 
            type="text"
            id={`${prop.name}`}
            className={className}
            value={prop.value}
            onChange={this.handleChangeProperty(prop.name)}
        />;

        if (propType !== null && propType !== undefined && propType !== "") {
            if (propType == "STATIC") {
                propInput = <input
                    type="text"
                    id={`${prop.name}`}
                    className="disabled-prop txt-detail"
                    value={prop.value}
                    onChange={this.handleChangeProperty(prop.name)}
                    disabled={true}
                />;
            }
            else if (propType == "BOOL") {
                let value = this.props.propsChanged ? this.props.editProps.find(p => p.name == prop.name).value : this.props.assetProps.find(p => p.name == prop.name).value;
                propInput = <select className={className} onChange={this.handleSelectProperty(prop.name)} value={value/*this.state.selectedPropValues[prop.name]*/}>
                    {boolOptions.map((option) =>
                        <option key={option.value} id={option.value} value={option.value}>{option.label}</option>
                    )}
                </select>;
            }
            else if (propType == "SELECT") {
                let value = this.props.propsChanged ? this.props.editProps.find(p => p.name == prop.name).value : this.props.assetProps.find(p => p.name == prop.name).value;
                propInput = <select className={className} onChange={this.handleSelectProperty(prop.name)} value={value}>
                    {propertyOptions[prop.name].map((option) =>
                        <option key={option.value} id={option.value} value={option.value}>{option.label}</option>
                    )}
                </select>;
            }
            else if (propType == "INTEGER") {
                propInput = <input
                    type="text"
                    id={`${prop.name}`}
                    className={className}
                    value={prop.value}
                    onChange={this.handleChangeIntegerProperty(prop.name)}
                />;
            }
            else {
                propInput = <input
                    type="number"
                    step="any"
                    id={`${prop.name}`}
                    className={className}
                    value={prop.value}
                    onChange={this.handleChangeProperty(prop.name)}
                />
            }
        }

        return (propInput);
    }

    render() {
        let propertyList = this.props.propsChanged ? this.props.editProps : this.props.assetProps;
        let propertyTypeList = this.props.propertyTypeList;

        return (
            <Drawer className="width-300" anchor='right' open={this.props.show} onClose={this.props.onClick}>

                <div className="modal-header">
                    <h3>Properties</h3>
                    <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                </div>

                <div className="white-container property-drawer">
                    {propertyList.map((prop, index) =>
                        <div className="row no-gutters" key={`prop-${index}`}>
                            <div className="col-7 text-right pad-right-5">
                                {prop.name == "id" ? "PropertySetId" : prop.name}:
                            </div>
                            <div className="col-5 text-left sidebar-select-message">
                                {this.getPropertyInput(prop, propertyTypeList)}
                            </div>
                        </div>
                    )}
                </div>

                <div className="row no-gutters lastItem margin-top-5">
                    <button className="secondary-btn btn-small" onClick={this.props.onResetClick}>Reset</button>
                    <button className={this.props.showAnalytics ? "primary-btn btn-small" : "secondary-btn btn-small"} onClick={this.props.onApplyClick} disabled={this.props.analyticsDisabled}>Apply Analytics</button>
                {/*<button className={"primary-btn btn-small"} onClick={this.props.onApplyClick}>Apply Properties</button>*/}
                </div>

            </Drawer>
        );
    }
}

export default FurnacePropertiesDrawer;