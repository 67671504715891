import React, {useState} from 'react';
import OnboardingDashboardCard from './OnboardingDashboardCard';

function MultipleStatesCard(props) {
    const { assetType, multiStateAssets } = props;
    
    let content = <div className="row no-gutters card-row margin-8-0 text-center">
        <div className="col min-height-40">
            <div className={"onboarding-table margin-0-20"}>
                <table className={"width-100p margin-bottom-0"}>

                    <thead>
                    <tr className="alerts-table-row">
                        <th className="table-shrink">
                            ASSET ID
                        </th>
                        <th className="table-shrink">
                            MAC ID
                        </th>
                        <th className="table-shrink">
                            STATES
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    {multiStateAssets !== undefined && multiStateAssets !== null && multiStateAssets.length && multiStateAssets.map((asset, index) =>
                        <tr key={asset.assetId} data={index} className="alerts-table-row">
                            <td> {asset.assetId} </td>
                            <td> {asset.assetName} </td>
                            <td> {asset.classNames?.join(", ")} </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    </div>;

    return (
        <div>
            <OnboardingDashboardCard title={`${assetType}s in Multiple States`} content={content} isGraphCard={false} />
        </div>
    );
}

export default MultipleStatesCard;