/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/assettype";
const assettypeService = {
    getassettypes: async (partnerOrgId) => {
        const response = await http.get(`${controller}`);
        return response.data;
    }
};
export default assettypeService;