/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/alert";
class AlertService {

    async get(id) {
        const response = await http.get(`${controller}?orgId=${id}`);
        const data = response;
        return data;
    }

    async getalerttypes() {
        const response = await http.get(`${controller}/getalerttypes`);
        const data = response;
        return data;
    }

    async getalerttags() {
        const response = await http.get(`${controller}/getalerttags`);
        const data = response;
        return data;
    }

    async getassetcontenttab(id, contentType) {
        const response = await http.post(`${controller}/getassetcontenttab?assetId=${id}&contentType=${contentType}`);
        const data = response;
        return data;
    }

    async getassetconvoid(id) {
        const response = await http.post(`${controller}/getassetconvoid?assetId=${id}`);
        const data = await response;
        return data;
    }

    async closealert(id) {
        const response = await http.post(`${controller}/closealert?assetId=${id}`);
        const data = response;
        return data;
    }

    async silencealerts(ids, muteUntil) {
        const response = await http.post(`${controller}/silencealerts?alertIds=${ids}&muteUntilStr=${muteUntil}`);
        const data = response;
        return data;
    }

    async updatealert(alert) {
        const response = await http.post(`${controller}/updatealert`, alert);
        const data = response;
        return data;
    }

    async addalert(args) {
        const response = await http.post(`${controller}/addalert`, args);
        const data = response;
        return data;
    }

    async triagealerts(action, alerts) {
        const response = await http.post(`${controller}/triagealerts?action=${action}`, alerts);
        const data = response;
        return data;
    }

    async getalertcases(alertId) {
        const response = await http.post(`${controller}/getalertcases?alertId=${alertId}`);
        const data = response;
        return data;
    }

    async getalertdetails(alertId) {
        const response = await http.post(`${controller}/getalertdetails?alertId=${alertId}`);
        const data = response;
        return data;
    }

    async createjob(alertId, opportunity) {
        const response = await http.post(`${controller}/createjob?alertId=${alertId}`, opportunity);
        const data = response;
        return data;
    }

    async mutealert(alertId) {
        const response = await http.post(`${controller}/mutealert?alertId=${alertId}`);
        const data = response;
        return data;
    }

    async bulkalerttag(tagId, alerts, addingTag = false) {
        const response = await http.post(`${controller}/bulkalerttag?tagId=${tagId}&addingTag=${addingTag}`, alerts);
        const data = response;
        return data;
    }

    async getalertasset(alert) {
        const response = await http.post(`${controller}/getalertasset`, alert);
        const data = response;
        return data;
    }

    async getalerts(orgId, parentId, classIds) {
        const response = await http.post(`${controller}/getalerts?orgId=${orgId}&parentId=${parentId}&classIds=${classIds}`);
        const data = response;
        return data;
    }

    async getquestionalertlist(args) {
        const response = await http.post(`${controller}/getquestionalertlist`, args);
        const data = await response;
        return data;
    }

    async getalertlist(type, args) {
        const response = await http.post(`${controller}/getalertlist?alertType=${type}`, args);
        const data = response;
        return data;
    }

    async gettriagealertslist(start, end, orgId = "") {
        const response = await http.post(`${controller}/gettriagealertslist?startTime=${start}&endTime=${end}&organizationId=${orgId}`);
        const data = response;
        return data;
    }

    async gethistoricalalertslist(request) {
        const response = await http.post(`${controller}/gethistoricalalertslist`, request); 
        const data = response;
        return data;
    }

    async getexternalalertlist(type, args) {
        const response = await http.post(`${controller}/getexternalalertlist?alertType=${type}`, args);
        const data = response;
        return data;
    }

    async getuseralertlist(type, args) {
        const response = await http.post(`${controller}/getuseralertlist?alertType=${type}`, args);
        const data = response;
        return data;
    }

    async getdashboardalertinfo() {
        const response = await http.post(`${controller}/getdashboardalertinfo`);
        const data = response;
        return data;
    }

    async getuntriagedalerts(assetId = null) {
        let url = `${controller}/getuntriagedalerts`;
        if (assetId !== null) {
            url += `?assetId=${assetId}`;
        }
        const response = await http.post(url);
        const data = response;
        return data;
    }

    async getfiltereduntriagedalerts(assetId, triagedIds = null) {
        const response = await http.post(`${controller}/getfiltereduntriagedalerts?assetId=${assetId}`, triagedIds);
        const data = response;
        return data;
    }

    async getuntriagedqueue() {
        const response = await http.post(`${controller}/getuntriagedqueue`);
        const data = response;
        return data;
    }

    async getescalatedalerts(assetId) {
        const response = await http.post(`${controller}/getescalatedalerts?assetId=${assetId}`);
        const data = response;
        return data;
    }

    async getfurnacetrainingannotations(id, start, end, dataSeries) {
        const response = await http.post(`${controller}/getfurnacetrainingannotations?assetId=${id}&start=${start}&end=${end}`, dataSeries);
        const data = response;
        return data;
    }
    
    async getlast24hrsdata(id, type, now) {
        const response = await http.post(`${controller}/getlast24hrsdata?assetId=${id}&assetType=${type}&now=${now}`, null);
        const data = response;
        return data;
    }

    async getrunanalyticoutput(request) {
        const response = await http.post(`${controller}/getrunanalyticoutput`, request);
        const data = response;
        return data;
    }

    async gettrainingrunlistdata(id, isInitial, assetType, runs, isMultiCT = false) {
        const response = await http.post(`${controller}/gettrainingrunlistdata?assetId=${id}&isInitial=${isInitial}&assetType=${assetType}&isMultiCT=${isMultiCT}`, runs);
        const data = response;
        return data;
    }

    async getalertlistdata(id, isInitial, type, alerts) {
        const response = await http.post(`${controller}/getalertlistdata?assetId=${id}&isInitial=${isInitial}&alertType=${type}`, alerts);
        const data = response;
        return data;
    }

    async cachetriagedata(id, type, alerts) {
        const response = await http.post(`${controller}/cachetriagedata?assetId=${id}&alertType=${type}`, alerts);
        const data = response;
        return data;
    }

    async getalertrawdata(id, alerts) {
        const response = await http.post(`${controller}/getalertrawdata?assetId=${id}`, alerts);
        const data = response;
        return data;
    }

    async getlongalertdata(id, alert) {
        const response = await http.post(`${controller}/getlongalertdata?assetId=${id}`, alert);
        const data = response;
        return data;
    }

    async getgraphdata(offHigh, id, acPropId, furnPropId, alert) {
        const response = await http.post(`${controller}/getgraphdata?offHigh=${offHigh}&furnaceId=${id}&acPropId=${acPropId}&furnacePropId=${furnPropId}`, alert);
        const data = response;
        return data;
    }

    async getallgraphdata(offHigh, id, acPropId, furnPropId, alert, blowerPropId = null, inducerPropId = null) {
        const response = await http.post(`${controller}/getallgraphdata?offHigh=${offHigh}&furnaceId=${id}&acPropId=${acPropId}&furnacePropId=${furnPropId}&blowerPropId=${blowerPropId}&inducerPropId=${inducerPropId}`, alert);
        const data = response;
        return data;
    }

    async getextendeddata(offHigh, id, acPropId, furnPropId, alert, blowerPropId = null, inducerPropId = null, leftHours = 0, rightHours = 0) {
        const response = await http.post(`${controller}/getextendeddata?offHigh=${offHigh}&furnaceId=${id}&acPropId=${acPropId}&furnacePropId=${furnPropId}&blowerPropId=${blowerPropId}&inducerPropId=${inducerPropId}&leftHours=${leftHours}&rightHours=${rightHours}`, alert);
        const data = response;
        return data;
    }

    async getjobgraphdata(offHigh, id, acPropId, furnPropId, alert, cacheStart = false, blowerPropId = null, inducerPropId = null) {
        const response = await http.post(`${controller}/getjobgraphdata?offHigh=${offHigh}&furnaceId=${id}&acPropId=${acPropId}&furnacePropId=${furnPropId}&cacheStart=${cacheStart}&blowerPropId=${blowerPropId}&inducerPropId=${inducerPropId}`, alert);
        const data = response;
        return data;
    }

    async getsingleassetalertdata(offHigh, assetType, propId, alert) {
        const response = await http.post(`${controller}/getsingleassetalertdata?offHigh=${offHigh}&assetType=${assetType}&propId=${propId}`, alert);
        const data = response;
        return data;
    }

    async getassetjobalertdata(offHigh, assetType, propId, cacheStart, alert) {
        const response = await http.post(`${controller}/getassetjobalertdata?offHigh=${offHigh}&assetType=${assetType}&propId=${propId}&cacheStart=${cacheStart}`, alert);
        const data = response;
        return data;
    }

    async getthermostatalertdata(alert) {
        const response = await http.post(`${controller}/getthermostatalertdata`, alert);
        const data = response;
        return data;
    }

    async getnewgraphdata(id, acPropId, furnPropId, startTime, endTime, assetId, typeId) {
        const response = await http.post(`${controller}/getnewgraphdata?furnaceId=${id}&acPropId=${acPropId}&furnacePropId=${furnPropId}&newStartTime=${startTime}&newEndTime=${endTime}&alertAssetId=${assetId}&alertTypeId=${typeId}`);
        const data = response;
        return data;
    }
    
    getjobcachedstart(alertId) {
        const response = http.post(`${controller}/getjobcachedstart?alertId=${alertId}`, null);
        const data = response;
        return data;
    }

    clearjobcachedstart(alertId) {
        const response = http.post(`${controller}/clearjobcachedstart?alertId=${alertId}`, null);
        const data = response;
        return data;
    }

    async getacjobgraphdata(request/*id, startTime, endTime, hvacId, alertId, cacheStart, hvacSys*/) {
        const response = await http.post(`${controller}/getacjobgraphdata`, request); //?assetId=${id}&jobStart=${startTime}&jobEnd=${endTime}&hvacId=${hvacId}&alertId=${alertId}&cacheStart=${cacheStart}`, hvacSys);
        const data = response;
        return data;
    }

    async getacalertgraphdata(id, startTime, endTime, hvacId, hvacSys) {
        const response = await http.post(`${controller}/getacalertgraphdata?assetId=${id}&alertStart=${startTime}&alertEnd=${endTime}&hvacId=${hvacId}`, hvacSys);
        const data = response;
        return data;
    }

    async getacextendeddata(id, startTime, endTime, hvacId, hvacSys, leftHours, rightHours) {
        const response = await http.post(`${controller}/getacextendeddata?assetId=${id}&alertStart=${startTime}&alertEnd=${endTime}&hvacId=${hvacId}&leftHours=${leftHours}&rightHours=${rightHours}`, hvacSys);
        const data = response;
        return data;
    }

    async getmultiseriesgraphdata(item, hvacId = null) {
        const response = await http.post(`${controller}/getmultiseriesgraphdata?hvacId=${hvacId}`, item);
        const data = response;
        return data;
    }

    async getstringtrenddata(item) {
        const response = await http.post(`${controller}/getstringtrenddata`, item);
        const data = response;
        return data;
    }

    async getacassetgraphdata(furnaceId, asset) {
        const response = await http.post(`${controller}/getacassetgraphdata?furnaceId=${furnaceId}`, asset);
        const data = response;
        return data;
    }

    async getassetgraphdata(assetId) {
        const response = await http.post(`${controller}/getassetgraphdata?assetId=${assetId}`);
        const data = response;
        return data;
    }

    async getacrundata(start, end, acIndex, hvacSys) {
        const response = await http.post(`${controller}/getacrundata?startStr=${start}&endStr=${end}&acIndex=${acIndex}`, hvacSys);
        const data = response;
        return data;
    }

    async getrundata(start, end, asset) {
        const response = await http.post(`${controller}/getrundata?startStr=${start}&endStr=${end}`, asset);
        const data = response;
        return data;
    }

    async getthermostatdata(start, end, asset) {
        const response = await http.post(`${controller}/getthermostatdata?startStr=${start}&endStr=${end}`, asset);
        const data = response;
        return data;
    }

    async getpropertiesforanalytic(assetId) {
        const response = await http.post(`${controller}/getpropertiesforanalytic?assetId=${assetId}`);
        const data = response;
        return data;
    }

    async getassetpropertiesforanalytic(assetId, assetType) {
        const response = await http.post(`${controller}/getassetpropertiesforanalytic?assetId=${assetId}&assetType=${assetType}`);
        const data = response;
        return data;
    }

    async getunsetproperties(props) {
        const response = await http.post(`${controller}/getunsetproperties`, props);
        const data = response;
        return data;
    }

    async getunsetassetproperties(assetType, props) {
        const response = await http.post(`${controller}/getunsetassetproperties?assetType=${assetType}`, props);
        const data = response;
        return data;
    }

    async getgraphtimeseries(offHigh, currData) {
        const response = await http.post(`${controller}/getgraphtimeseries?offHigh=${offHigh}`, currData);
        const data = response;
        return data;
    }

    async getannotations(graph) {
        const response = await http.post(`${controller}/getannotations`, graph);
        const data = response;
        return data;
    }

    async getconversationlist(conversationId) {
        const response = await http.get(`${controller}/getconversationlist?conversationId=${conversationId}`);
        const data = response;
        return data;
    }

    async addconversationitem(comment) {
        const response = await http.post(`${controller}/addconversationitem`, comment);
        const data = response;
        return data;
    }

    async updateconversationitem(comment) {
        const response = await http.post(`${controller}/updateconversationitem`, comment);
        const data = response;
        return data;
    }

    async getdevicegraphdata(start, end,assetList) {
        const response = await http.post(`${controller}/getdevicedata?startStr=${start}&endStr=${end}`,assetList);
        const data = response;
        return data;
    }
}

export default new AlertService();