/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, {Component, createRef} from 'react';
import { Spinner } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { PageView, Event } from "../GoogleAnalytics";
import PartnerService from "../../services/partner.service";
import AlertService from "../../services/alerts.service";

import LongArrowUpIcon from '@mui/icons-material/ArrowUpward';
import LongArrowDownIcon from '@mui/icons-material/ArrowDownward';

import moment from "moment";
import PublishModal from './PublishModal';
import PublishResultModal from './PublishResultModal';

import {jsAsset, alertCustomer, Equipment, GraphTypes, TT_TWIN_URL} from "../../componentObjects";
import ContextMenu from "../ContextMenu/contextMenu";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Loader from "../Loader/Loader";
import GraphComponent from "../Graph/GraphComponent";
import IconTooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";

const COLORS = ['#0033a0', '#3ed3c2', '#ff7555', '#ff9900', '#7d8ee2'];

const initState = {
    loading: false,
    deviceTableLoading: false,
    selected: -1,
    deviceSelected: false,
    currentDevice: [],
    currentDeviceId: null,
    //currentRadio: "Failed",
    //trainingInfo: {},
    //trainingPage: {},
    //trainingList: [],

    columnList: [],
    columnAltered: false,
    columnSort: {
        enabled: false,
        type: null,
        reverse: false
    },
    showCustomerInfo: false,
    customerLoading: false,
    currentCustomer: alertCustomer,
    currentPartnerName: "",
    showAssetInfo: false,
    assetLoading: false,
    currentAssetInfo: jsAsset,
    showColorPicker: false,
    selectedColor: -1,
    runTableLoading: false,
    runTypeSelected: false,
    currentType: "",
    typeRunList: [],
    selectedRuns: [],
    graphedRuns: [],
    dataList: [],
    chartDataList: [],
    rawDataList: [],
    last150DataList: [],
    runData: {},
    allSelected: false,
    allGraphed: false,
    showAnalytics: false,
    showPublishModal: false,
    showPublishResultModal: false,
    assetProps: [],
    publishLoading: false,
    isPublishSuccess: null,
    ttAssetUrl: ''
}

function collect(props) {
    return props;
}

class AirHandlerAssetTrainingReview extends Component {
    static displayName = AirHandlerAssetTrainingReview.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.loadInfo = this.loadInfo.bind(this);
        this.populateInfo = this.populateInfo.bind(this);
        this.changeTableRadio = this.changeTableRadio.bind(this);
        this.onColumnClick = this.onColumnClick.bind(this);
        this.columnSort = this.columnSort.bind(this);
        this.onDeviceRowClick = this.onDeviceRowClick.bind(this);
        this.onShowAllClick = this.onShowAllClick.bind(this);

        this.onRunTypeClick = this.onRunTypeClick.bind(this);
        //this.onCustomerInfoClick = this.onCustomerInfoClick.bind(this);
        //this.onAssetInfoClick = this.onAssetInfoClick.bind(this);
        this.onBackClick = this.onBackClick.bind(this);

        this.onSelectAll = this.onSelectAll.bind(this);
        this.handleRunCheck = this.handleRunCheck.bind(this);
        this.onGraphAll = this.onGraphAll.bind(this);
        this.handleGraphRunCheck = this.handleGraphRunCheck.bind(this);

        this.handleColorClick = this.handleColorClick.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.closeColorPicker = this.closeColorPicker.bind(this);
        
        this.handleViewInTT = this.handleViewInTT.bind(this);
        this.openTTConfirmModal = this.openTTConfirmModal.bind(this);
        this.showTTConfirmModal = this.showTTConfirmModal.bind(this);
        this.onTTConfirm = this.onTTConfirm.bind(this);

        /*this.openPublishModal = this.openPublishModal.bind(this);
        this.showPublishModal = this.showPublishModal.bind(this);
        this.onContinuePublish = this.onContinuePublish.bind(this);
        this.openPublishResultModal = this.openPublishResultModal.bind(this);
        this.showPublishResultModal = this.showPublishResultModal.bind(this);*/

        //this.onApplyAnalyticsClick = this.onApplyAnalyticsClick.bind(this);

        this.state = {
            loading: false,

            /* all assets page */
            deviceTableLoading: false, 
            selected: -1,
            deviceSelected: false,
            currentDevice: [],
            currentDeviceId: null,
            currentAssetId: "",
            currentRadio: "Failed",

            trainingInfo: {},
            trainingPage: {},
            trainingList: [],

            columnList: [],
            columnAltered: false,
            columnSort: {
                enabled: false,
                type: null,
                reverse: false
            },

            orgId: 1,
            showAllTraining: true,//false,
            showShowAllBtn: false,

            /*showCustomerInfo: false,
            customerLoading: false,
            currentCustomer: alertCustomer,
            currentPartnerName: "",
            showAssetInfo: false,
            assetLoading: false,
            currentAssetInfo: jsAsset,*/

            /* device page */
            showColorPicker: false,
            selectedColor: -1,

            runTableLoading: false,
            runTypeSelected: false,
            currentType: "",
            typeRunList: [],

            selectedRuns: [],
            graphedRuns: [],
            dataList: [],
            chartDataList: [],
            rawDataList: [],
            last150DataList: [],
            longRunList: [],
            runData: {},
            allSelected: false,
            allGraphed: false,

            visibility: {
                rla: false,
                lra: false,
                fla: false
            },

            showAnalytics: false,
            showPublishModal: false,
            showPublishResultModal: false,
            assetProps: [],
            publishLoading: false,
            isPublishSuccess: null,
            ttAssetUrl: '',

            showTTConfirm: false,
            TTUrl: "",
            selectedRowIdForContextMenu: null
        };
        this.contextMenuRef = createRef();
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();
        this.loadInfo();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentPartnerId !== this.props.currentPartnerId) {
            this.loadInfo();
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

/** Asset Training Review **/

    loadInfo() {
            this.setState({ loading: true }, async () => {
                let orgId = this.props.currentPartnerId;
                let showBtn = true;
                let showAll = false;
                if (this.props.loggedInOrg !== "LifeWhere") {
                    showBtn = false;
                }
                else if (this.props.showAllTraining) {
                    showAll = true;
                    orgId = 1;
                }

                this.setState({
                    orgId: orgId,
                    showAllTraining: showAll,
                    showShowAllBtn: showBtn
                }, async () => {
                    await this.populateInfo();
                })
                
            });  
    }

    async populateInfo() {
        await PartnerService.getassettraininginfo(this.state.orgId, Equipment.AIR_HANDLER)
            .then(response => {
                if (this._isMounted) {
                    if (response != null && response !== {}) {
                        this.setState({ trainingInfo: response }, () => {
                            if (this.state.trainingInfo.trainingPageDict !== {} && this.state.trainingInfo.trainingPageDict["Failed"] !== undefined) {
                                this.setState({
                                    trainingPage: this.state.trainingInfo.trainingPageDict["Failed"],
                                    trainingList: this.state.trainingInfo.trainingPageDict["Failed"].trainingAssets,
                                    loading: false
                                });
                            }
                            else {
                                this.setState({
                                    trainingPage: {},
                                    trainingList: [],
                                    loading: false
                                });
                            }
                        });
                    }
                    else {
                        this.setState({ loading: false });
                    }
                }

            })
            .catch(e => {
                console.log(e);
            });
    }

    onShowAllClick() {
        Event("Show All Clicked", "User clicked Show All in AirHandler Asset Training Review", "Show All Clicked");
        this.setState({ loading: true, showAllTraining: !this.state.showAllTraining }, () => {
            this.props.setShowAllTraining(this.state.showAllTraining);
            this.setState({ orgId: this.state.showAllTraining ? 1 : this.props.currentPartnerId }, async () => {
                await this.populateInfo();
            });
        });
    }

    changeTableRadio(e) {
        Event("Air Handler Asset Training Review radio clicked", "User clicked radio in AirHandler Asset Training Review", "Radio Changed");
        let radio = e.target.value;

        if (this.state.trainingInfo !== null && this.state.trainingInfo !== undefined && this.state.trainingInfo.trainingPageDict !== null && this.state.trainingInfo.trainingPageDict !== undefined && this.state.trainingInfo.trainingPageDict[radio] !== undefined) {
            this.setState({
                currentRadio: radio,
                trainingPage: this.state.trainingInfo.trainingPageDict[radio],
                trainingList: this.state.trainingInfo.trainingPageDict[radio].trainingAssets
            });
        }
        else {
            this.setState({
                currentRadio: radio,
                trainingPage: {},
                trainingList: []
            })
        }
    }

    onColumnClick(selectedCol) {
        const colSort = this.state.columnSort;
        let newSort = colSort;
        const tableList = this.state.trainingList; 
        let trainingAssets = tableList;
        let colAssets = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colAssets = this.columnSort(trainingAssets, "DEVICEID", false); /* default sort by is DEVICEID */
                    colAltered = false;
                }
                else {
                    newSort.reverse = true;
                    colAssets = this.columnSort(trainingAssets, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colAssets = this.columnSort(trainingAssets, selectedCol, false); 
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colAssets = this.columnSort(trainingAssets, selectedCol, false); 
            colAltered = true;
        }

        this.setState({ columnSort: newSort, columnList: colAssets }, () => {
            this.setState({ columnAltered: colAltered });
        });
    }

    columnSort(list, col, isReverse) {
        let colAssets = [];
        let trainingAssets = list;
        
        if (col === "ORGANIZATION") {
            colAssets = trainingAssets.sort((a, b) => {
                const nameA = a.organization.toLocaleUpperCase(); 
                const nameB = b.organization.toLocaleUpperCase(); 

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "DEVICEID") {
            colAssets = trainingAssets.sort((a, b) => {
                const valA = a.deviceId.toLocaleUpperCase();
                const valB = b.deviceId.toLocaleUpperCase();

                return valA.localeCompare(valB, undefined, { numeric: true });
            });
        }
        else {
            colAssets = trainingAssets.sort((a, b) => {
                const valA = col === "ASSETID" ? a.assetId : (col === "TOTALRUNS" ? a.runCount : (col === "GOODRUNS" ? a.goodRunCount : a.badRunCount));
                const valB = col === "ASSETID" ? b.assetId : (col === "TOTALRUNS" ? b.runCount : (col === "GOODRUNS" ? b.goodRunCount : b.badRunCount));

                return valB - valA;
            });
        }

        if (isReverse) {
            colAssets = colAssets.reverse();
        }

        return colAssets;
    }

    async onDeviceRowClick(index) {
        let x = index;
        let device = this.state.trainingList[x];
        let deviceId = this.state.trainingList[x].deviceId;

        this.setState({
            currentDevice: device,
            currentAssetId: device.assetId,
            currentDeviceId: deviceId,
            selected: x,
            deviceSelected: true,
            runTypeSelected: false,
            deviceTableLoading: true
        }, () => {
                this.setState({
                    deviceTableLoading: false
                });
        });
    }

/** Device Training Review **/

    onBackClick() {
        Event("Back Clicked", "User clicked back button in AirHandler Asset Training Review", "Back Clicked");
        this.setState(initState);
    }

    onRunTypeClick(isGood) {
        this.setState({
            runTableLoading: true,
            runTypeSelected: true,
            currentType: isGood ? 'GOOD' : 'BAD'
        }, () => {
                this.setState({
                    typeRunList: this.state.currentType == "GOOD" ? this.state.currentDevice.goodRuns : this.state.currentDevice.badRuns
                }, async () => {
                    await AlertService.gettrainingrunlistdata(this.state.currentAssetId, true, Equipment.AIR_HANDLER, this.state.typeRunList)
                        .then(response => {
                            this.setState({
                                runTableLoading: false,
                                runData: response.data
                            });
                        })
                        .catch(e => {
                            console.log(e);
                            this.setState({ runTableLoading: false });
                        });
                });
        });
    }

    onSelectAll() {
        Event("Select All clicked", "User selected all in AirHandler Asset Training Review", "Select All Clicked");
        let selectedRuns = [];

        this.setState({ allSelected: !this.state.allSelected }, () => {
            if (this.state.allSelected)
                selectedRuns = this.state.typeRunList;

            this.setState({ selectedRuns: selectedRuns });
        });
    }

    handleRunCheck(e) {
        const index = e.target.value;
        const run = this.state.typeRunList[index];
        let selectedRuns = this.state.selectedRuns;

        if (e.target.checked) {
            selectedRuns = selectedRuns.concat(run);
            this.setState({ selectedRuns: selectedRuns });
        }
        else {
            selectedRuns = selectedRuns.filter(s => s.runId !== run.runId);
            this.setState({ selectedRuns: selectedRuns });
        }
    }

    onGraphAll() {
        Event("Graph All Clicked", "User clicked graph all in AirHandler Asset Training Review", "Graph All Clicked");
        let graphedRuns = [];
        let selectedRuns = [];
        let dataList = [];
        let chartDataList = [];
        let rawDataList = [];
        let last150DataList = [];
        let dataMax = 0;
        let rawMax = 0;

        this.setState({ allGraphed: !this.state.allGraphed }, () => {
            if (this.state.allGraphed) {
                selectedRuns = this.state.typeRunList;
                this.setState({ loading: true }, async () => {
                    await AlertService.gettrainingrunlistdata(this.state.currentAssetId, false, Equipment.AIR_HANDLER, this.state.typeRunList)
                        .then(response => {
                            if (this._isMounted)
                                this.setState({
                                    runData: response.data
                                }, () => {
                                    for (let i = 0; i < this.state.typeRunList.length; i++) {
                                        const run = this.state.typeRunList[i];
                                        let series = this.state.runData[run.runId];
                                        let seriesRuns = series.series;

                                        graphedRuns = graphedRuns.concat(run);
                                        dataList = dataList.concat(series);
                                        for (let i = 0; i < seriesRuns.length; i++) {
                                            if (seriesRuns[i].length > dataMax) {
                                                dataMax = seriesRuns[i].length;
                                            }
                                        }
                                        if (series.dataMax > rawMax) {
                                            rawMax = series.dataMax;
                                        }
                                        for (let j = 0; j < seriesRuns.length; j++) {
                                            let dataName = `${run.runId}`;
                                            let seriesRun = seriesRuns[j];
                                            chartDataList = this.props.getGraphData(chartDataList, dataName, seriesRun, series.color);
                                            last150DataList = this.props.getLastGraphData(last150DataList, dataName, seriesRun, series.color);
                                        }
                                    }

                                    this.setState({
                                        loading: false,
                                        graphedRuns: graphedRuns,
                                        selectedRuns: selectedRuns,
                                        dataList: dataList,
                                        chartDataList: chartDataList,
                                        rawDataList: rawDataList,
                                        last150DataList: last150DataList,
                                        dataMax: dataMax,
                                        rawMax: rawMax
                                    });
                                });
                        })
                        .catch(e => {
                            console.log(e);
                            this.setState({ loading: false, message: "Failed to fetch graph data for all runs" });

                            setTimeout(() => {
                                this.setState({
                                    message: ""
                                });
                            }, 3000);
                        });
                });
            }
            else {
                selectedRuns = this.state.selectedRuns;

                this.setState({
                    graphedRuns: graphedRuns,
                    selectedRuns: selectedRuns,
                    dataList: dataList,
                    dataMax: dataMax,
                    rawMax: rawMax,
                    chartDataList: chartDataList,
                    rawDataList: rawDataList,
                    last150DataList: last150DataList
                });
            }
        });
    }

    async handleGraphRunCheck(e) {
        const index = e.target.value;
        const run = this.state.typeRunList[index];
        const graphRunId = run.runId;

        let graphedRuns = this.state.graphedRuns;
        let selectedRuns = this.state.selectedRuns;
        let runData = this.state.runData;
        let dataList = this.state.dataList;
        let longRunList = this.state.longRunList;
        let chartDataList = this.state.chartDataList;
        let rawDataList = this.state.rawDataList;
        let last150DataList = this.state.last150DataList;

        let list = [];
        list = list.concat(run);

        if (e.target.checked) {
            this.setState({ loading: true }, async () => {
                await AlertService.gettrainingrunlistdata(this.state.currentAssetId, false, Equipment.AIR_HANDLER, list)
                    .then(response => {
                        if (this._isMounted) {
                            let responseData = response.data;
                            responseData[graphRunId].color = runData[graphRunId].color;
                            runData[graphRunId] = responseData[graphRunId];

                            let series = responseData[graphRunId];
                            longRunList = longRunList.concat(run);
                            graphedRuns = graphedRuns.concat(run);
                            selectedRuns = selectedRuns.concat(run);
                            dataList = dataList.concat(series);

                            let seriesRuns = series.series;
                            series.series = seriesRuns;

                            let seriesMax = 0;
                            for (let i = 0; i < seriesRuns.length; i++) {
                                let thisMax = seriesRuns[i].length;
                                if (thisMax > seriesMax) {
                                    seriesMax = thisMax;
                                }
                            }
                            for (let j = 0; j < seriesRuns.length; j++) {
                                let dataName = `${run.runId}`;
                                let seriesRun = seriesRuns[j];
                                chartDataList = this.props.getGraphData(chartDataList, dataName, seriesRuns, series.color);
                                last150DataList = this.props.getLastGraphData(last150DataList, dataName, seriesRuns, series.color);
                            }

                            let dataMax = this.state.dataMax;
                            let rawMax = this.state.rawMax;
                            if (seriesMax > dataMax) {
                                dataMax = seriesMax;
                            }
                            if (series.dataMax > rawMax) {
                                rawMax = series.dataMax;
                            }

                            this.setState({
                                loading: false,
                                longRunList: longRunList,
                                graphedRuns: graphedRuns,
                                selectedRuns: selectedRuns,
                                dataList: dataList,
                                dataMax: dataMax,
                                rawMax: rawMax,
                                chartDataList: chartDataList,
                                rawDataList: rawDataList,
                                last150DataList: last150DataList
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ loading: false, message: "Failed to load graph data for selected run!" });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    });
            })
        }
        else {
            longRunList = longRunList.filter(a => a.runId !== graphRunId);
            graphedRuns = graphedRuns.filter(a => a.runId !== graphRunId);
            dataList = dataList.filter(d => d.name !== graphRunId);
            chartDataList = chartDataList.filter(d => !d.name.includes(graphRunId));
            rawDataList = rawDataList.filter(d => !d.name.includes(graphRunId));
            last150DataList = last150DataList.filter(d => !d.name.includes(graphRunId));

            this.setState({ longRunList: longRunList, graphedRuns: graphedRuns, dataList: dataList, chartDataList: chartDataList, rawDataList: rawDataList, last150DataList: last150DataList });
        }
    }

    handleColorClick() {

    }

    handleColorChange() {

    }

    closeColorPicker() {

    }

    /*openPublishModal() {
        this.showPublishModal();
    }

    showPublishModal() {
        this.setState({ showPublishModal: !this.state.showPublishModal })
    }

    onContinuePublish() {
        this.setState({
            publishLoading: true,
            showPublishResultModal: true,
            showPublishModal: false,
            ttAssetUrl: "https://app.lifewhere.com/twins/detail/" + this.state.currentAssetId
        }, () => {
            this.setState({
                isPublishSuccess: true,
                publishLoading: false
            });
        });
    }

    openPublishResultModal() {
        this.showPublishResultModal();
    }

    showPublishResultModal() {
        this.setState({ showPublishResultModal: !this.state.showPublishResultModal });
    }

    onViewInTT() {
        window.open(this.state.ttAssetUrl, "_blank");
        this.setState({ showPublishResultModal: false });
    }

    onApplyAnalyticsClick() {
        if (this.state.graphedRuns.length == 1) {
            this.setState({
                showAnalytics: !this.state.showAnalytics
            })
        }
    }*/

    renderGraph() {
        return (
            <div className="row no-gutters">
                <div className="col-sm-12 col-md-10">

                    <GraphComponent graphType={GraphTypes.ALERT} chartData={this.state.chartDataList} key={`${this.state.currentAssetId}-${this.state.chartDataList.length}-${this.state.dataList.length}-${this.state.visibility}`} assetType={Equipment.AIR_CONDITIONER} isTraining={true}
                        dataMax={this.state.dataMax} rawMax={this.state.rawMax} rawData={this.state.rawDataList} last150Data={this.state.last150DataList} rlaValue={this.state.dataList.length ? this.state.dataList[0].rla : null}
                        visibility={this.state.visibility} lraValue={this.state.dataList.length ? this.state.dataList[0].lra : null} flaValue={this.state.dataList.length ? this.state.dataList[0].fla : null} alertList={this.state.graphedRuns} assetId={this.state.currentAssetId} setLoading={this.setLoading} />

                </div>
                <div className="col-sm-4 col-md-2">
                    
                </div>
            </div>
        );
    }

    getStartDate(date) {
        var dateStr = moment(date).format();
        return (dateStr);
    }

    handleViewInTT(id) {
        if (id !== null) {
            this.setState({ selectedRowIdForContextMenu: id }, () => {
                this.setState({ TTUrl: process.env.REACT_APP_TWIN_URL + id })
                this.openTTConfirmModal();
            })
        }
    }

    showTTConfirmModal() {
        this.setState({ showTTConfirm: !this.state.showTTConfirm });
    }

    openTTConfirmModal() {
        this.setState({ modalMessage: "You are about to leave the LifeWhere portal and be taken to the Device Properties page in TwinThread. Do you wish to continue?", modalTitle: "Leave LifeWhere Portal" }, () => {
            this.showTTConfirmModal();
        });
    }

    onTTConfirm() {
        Event("View Device Properties Clicked", "User clicked view device properties and is being taken to TwinThread Properties view", "View Device Properties Clicked");
        window.open(this.state.TTUrl, '_blank'); // opens new tab with TwinThread twins detail page
        this.showTTConfirmModal(); // closes modal
    }

    renderDeviceContent() {
        const { classes } = this.props;

        let deviceLoader = this.state.deviceTableLoading ?
            <Loader />
            : null;

        let customerInfoBtn = this.state.showCustomerInfo ?
            <button className="tertiary-btn" onClick={this.onCustomerInfoClick}>- Hide Customer Info</button>
            : <button className="tertiary-btn" onClick={this.onCustomerInfoClick}>Show Customer Info</button>;

        let customerInfoContent = this.state.showCustomerInfo ?
            this.state.customerLoading ?
                <div className='centered'><Spinner animation="border" variant="dark" /></div>
                : <div className={"margin-left-15 margin-right-15"}>
                    <small>
                        <div className="detailTitle">Customer Information</div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Customer:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentCustomer.name}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Partner:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentPartnerName}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Phone:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentCustomer.phone}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Email:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentCustomer.email}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Address:</p>
                            </div>
                            <div className="col-8">
                                {this.getAddress()}
                            </div>
                        </div>
                    </small>
                </div>
            : null;

        let assetInfoBtn = this.state.showAssetInfo ?
            <button className="tertiary-btn" onClick={this.onAssetInfoClick}>- Hide Asset Info</button>
            : <button className="tertiary-btn" onClick={this.onAssetInfoClick}>Show Asset Info</button>;

        let assetInfoContent = this.state.showAssetInfo ?
            this.state.assetLoading ?
                <div className='centered'><Spinner animation="border" variant="dark" /></div>
                : <div className={"margin-left-15 margin-right-15"}>
                    <small>
                        <div className="detailTitle">Asset Information</div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Make:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentAssetInfo.make}</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-4">
                                <p className="semibold">Model:</p>
                            </div>
                            <div className="col-8">
                                <p>{this.state.currentAssetInfo.model}</p>
                            </div>
                        </div>
                    </small>
                </div>
            : null;

        let tableContent = this.state.typeRunList.length && this.state.runTypeSelected ?
            <tbody>
                {this.state.typeRunList.map((run, index) =>
                    <tr key={`${index}-${run.runId}`} data={index}>
                        <td>{run.runId}</td>
                        <td>{this.getStartDate(run.startTime)}</td>
                        <td>{this.getStartDate(run.endTime)}</td>
                        <td>{run.alertType}</td>
                        <td>
                            <input type="checkbox" value={index} id={run.runId} checked={this.state.selectedRuns.some(a => a.runId === run.runId)} onChange={this.handleRunCheck} />
                        </td>
                        <td>
                            <input type="checkbox" value={index} id={`${run.runId}-graph`} checked={this.state.graphedRuns.some(a => a.runId === run.runId)} onChange={this.handleGraphRunCheck} />
                        </td>
                        <td>
                            <div className={"display-flex"}>
                                <div className={"color-picker-wrapper"} style={{ backgroundColor: this.state.runData[run.runId] !== undefined ? this.state.runData[run.runId].color : '#ffffff' }} onClick={() => this.handleColorClick(run.runId)} />
                                {this.state.showColorPicker && this.state.selectedColor === run.runId ?
                                    <div className={"color-picker-inner-wrapper"}>
                                        <div className={"color-picker"} onClick={this.closeColorPicker} />
                                        <SketchPicker color={this.state.runData[run.runId] !== undefined ? this.state.runData[run.runId].color : '#ffffff'} onChange={this.handleColorChange} />
                                    </div>
                                    : null 
                                }
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>{this.state.runTableLoading || this.state.loading ? "Loading Runs of Selected Type..." : "No Runs of this Type"}</td></tr>
            </tbody>;

        let runTable = this.state.runTypeSelected ?
            <div className={"max-height-330 magin-bottom-15 overflow-auto"}>
                <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Run Id</th>
                            <th>Start Time</th>
                            <th>End Time </th>
                            <th>Alert Type</th>
                            <th>Select <input type="checkbox" value={this.state.allSelected} checked={this.state.allSelected} id='selectAll' onChange={this.onSelectAll} className={"margin-left-5"} /></th>
                            <th>Graph <input type="checkbox" value={this.state.allGraphed} checked={this.state.allGraphed} id='graphAll' onChange={this.onGraphAll} className={"margin-left-5"} /></th>
                            <th className={"width-25"}></th>
                        </tr>
                    </thead>
                    {tableContent}
                </table>
            </div>
            : <div>{null}</div>;

        return (
            <div className={"pad-left-10 pad-top-5"}>
                <div className="row no-gutters display-flex">
                    <div className="col-sm-12 col-md-4">

                        <div className="grey-container min-height-100p-sub-10">
                            <h5 className="sectionTitle">
                                <button className="tertiary-btn margin-0" onClick={this.onBackClick}>{"<"}</button>
                                Asset Id: {this.state.currentDevice.assetId}
                            </h5>
                            <div className="detailTitle margin-left-15">
                                Mac: {this.state.currentDeviceId}
                            </div>
                            <div className="table-container">

                                <div className={"overflow-auto margin-bottom-0"} >
                                    <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                                        <thead>
                                            <tr>
                                                <th>Run Type</th>
                                                <th>Run Count</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr className={this.state.runTypeSelected && this.state.currentType === "GOOD" ? "tableSelected" : ""} onClick={() => { this.onRunTypeClick(true) }}>
                                                <td>Good Runs</td>
                                                <td>{this.state.currentDevice.goodRunCount}</td>
                                            </tr>

                                            <tr className={this.state.runTypeSelected && this.state.currentType === "BAD" ? "tableSelected" : ""} onClick={() => { this.onRunTypeClick(false) }}>
                                                <td>Bad Runs</td>
                                                <td>{this.state.currentDevice.badRunCount}</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-8">

                        <div className="white-container min-height-100p" >
                            <h5 className="sectionTitle">{this.state.currentType === "" ? "Selected Run Type" : this.state.currentType === "GOOD" ? "Good Runs" : "Bad Runs"}</h5>
                            <div className="table-container max-height-330">
                                {runTable}
                            </div>
                            {deviceLoader}
                        </div>
                    </div>
                </div>

                <div>
                    {this.state.runTypeSelected ?
                        <div>
                            <br />
                            {this.renderGraph()}
                        </div>
                        : <div className="centered">
                            <br />
                            Select a Run Type to generate graph
                            </div>
                    }
                    <p>{this.state.message}</p>
                </div>
                <PublishModal show={this.state.showPublishModal} onClick={this.showPublishModal} onContinuePublish={this.onContinuePublish} />
                <PublishResultModal show={this.state.showPublishResultModal} loading={this.state.publishLoading} isPublishSuccess={this.state.isPublishSuccess} onClick={this.showPublishResultModal} onContinue={this.showPublishResultModal} onViewInTT={this.onViewInTT} />
            </div>
        );
    }

    renderTrainingContent() {
        let tableList = this.state.columnAltered ? this.state.columnList : this.state.trainingList;

        let tableContent = tableList.length ?
            <tbody>
                {this.state.trainingList.map((asset, index) =>
                    <tr id={asset.assetId} key={asset.assetId}>
                        <td onClick={() => this.onDeviceRowClick(index)}
                            className={"pad-left-30 border-left"}>{asset.deviceId}</td>
                        <td onClick={() => this.onDeviceRowClick(index)}>{asset.assetId}</td>
                        <td onClick={() => this.onDeviceRowClick(index)}>{asset.organization}</td>
                        <td onClick={() => this.onDeviceRowClick(index)}>{asset.runCount}</td>
                        <td onClick={() => this.onDeviceRowClick(index)}>{asset.goodRunCount}</td>
                        <td onClick={() => this.onDeviceRowClick(index)}>{asset.badRunCount}</td>
                        <td className={"border-right"}>
                            <div className={"display-flex pad-0 margin-left-5"}>
                                <IconTooltip title="Show in TwinThread" enterDelay={200} leaveDelay={200}>
                                    <IconButton key={`${index}-${alert.alertId}`}
                                                onClick={() => this.handleViewInTT(asset.assetId)}>
                                        <VisibilityIcon style={{color: "#0033a0", width: '13px', height: '13px'}}/>
                                    </IconButton>
                                </IconTooltip>
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
            :
            <tbody>
            <tr>
                <td>No Trainings to Show!</td>
            </tr>
            </tbody>;

        let arrowIcon = this.state.columnSort.enabled ?
            (this.state.columnSort.reverse ?
                <LongArrowDownIcon style={{height: '16px', width: '15px', paddingBottom: '2px'}}/>
                : <LongArrowUpIcon style={{height: '16px', width: '15px', paddingBottom: '2px'}}/>)
            : null;

        let devIdIcon = this.state.columnSort.enabled && this.state.columnSort.type === "DEVICEID" ?
            <span className={"column-icon"}>
                {arrowIcon}
            </span>
            : null;
        let assetIdIcon = this.state.columnSort.enabled && this.state.columnSort.type === "ASSETID" ?
            <span className={"column-icon"}>
                {arrowIcon}
            </span>
            : null;
        let orgIcon = this.state.columnSort.enabled && this.state.columnSort.type === "ORGANIZATION" ?
            <span className={"column-icon"}>
                {arrowIcon}
            </span>
            : null;
        let totalIcon = this.state.columnSort.enabled && this.state.columnSort.type === "TOTALRUNS" ?
            <span className={"column-icon"}>
                {arrowIcon}
            </span>
            : null;
        let goodIcon = this.state.columnSort.enabled && this.state.columnSort.type === "GOODRUNS" ?
            <span className={"column-icon"}>
                {arrowIcon}
            </span>
            : null;
        let badIcon = this.state.columnSort.enabled && this.state.columnSort.type === "BADRUNS" ?
            <span className={"column-icon"}>
                {arrowIcon}
            </span>
            : null;

        return (
            <div>
                <div className="row no-gutters alerts-tab-wrapper alerts-top-tabs">
                    <div className="col"></div>
                    <div className="pad-top-15 col-4">
                        <p>Assets in Training Session: &nbsp;<b>{this.state.trainingInfo.assetCount}</b></p>
                        <p>Runs Observed: &nbsp;<b>{this.state.trainingInfo.runCount}</b></p>
                    </div>
                    <div className="pad-top-15 col-4">
                        <p>Training Session Date: &nbsp;<b>{this.state.trainingInfo.trainingSessionDate}</b></p>
                        <p>Training Session Id: &nbsp;<b>{this.state.trainingInfo.trainingSessionId}</b></p>
                    </div>
                    <div className="col"></div>
                </div>

                <div className="alerts-tab-wrapper alerts-tab-wrapper-grey">
                    <div onChange={e => this.changeTableRadio(e)} className={"pad-left-40 margin-5"}>
                        <div className={"alerts-tab-options"}>
                            <input type="radio" value="Failed" defaultChecked name="trainingType" />
                                    &nbsp; Failed Training &nbsp;&nbsp;
                            <input type="radio" value="LowData" name="trainingType" />
                                &nbsp; Low Data Training &nbsp;&nbsp;
                            <input type="radio" value="Trained" name="trainingType" />
                                &nbsp; Successful Training &nbsp;&nbsp;
                        </div>
                    </div >
                </div>

                <div className={"margin-20-30"}>

                    <div>
                        <table className="width-100p table-striped table-bordered table-sm table-hover">

                            <thead>
                                <tr className="alerts-table-row">
                                    <th className={"pad-left-30"}>
                                        <div onClick={() => this.onColumnClick("DEVICEID")}>
                                            <span className={"cursor-pointer"}>DEVICE ID</span>
                                            {devIdIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("ASSETID")}>
                                            <span className={"cursor-pointer"}>ASSET ID</span>
                                            {assetIdIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("ORGANIZATION")}>
                                            <span className={"cursor-pointer"}>ORGANIZATION</span>
                                            {orgIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("TOTALRUNS")}>
                                            <span className={"cursor-pointer"}>TOTAL RUNS</span>
                                            {totalIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("GOODRUNS")}>
                                            <span className={"cursor-pointer"}>GOOD RUNS</span>
                                            {goodIcon}
                                        </div>
                                    </th>
                                    <th>
                                        <div onClick={() => this.onColumnClick("BADRUNS")}>
                                            <span className={"cursor-pointer"}>BAD RUNS</span>
                                            {badIcon}
                                        </div>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>

                            {tableContent}

                        </table>
                    </div>
                </div>

            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let loader = this.state.loading ?
            <Loader />
            : null;

        let title = this.state.deviceSelected ? this.state.currentDeviceId + " Training Review" : "Air Handler Asset Training Review";
        let contents = this.state.deviceSelected ? this.renderDeviceContent() : this.renderTrainingContent();

        let showAllBtn = !this.state.deviceSelected && this.state.showShowAllBtn ?
            <div className="btn-right pad-25-0-0-30">
                <button className={this.state.showAllTraining ? "primary-btn btn-large width-150" : "secondary-btn btn-large width-150"} onClick={this.onShowAllClick}>Show All</button>
            </div>
            : null;

        return (
            <div sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
                <div className='infoPage'>
                    <div className="row no-gutters">
                        <div className="col">
                            <h2 className="pageTitle dashboard-title" id="tabelLabel">
                                {title}
                            </h2>
                        </div>
                        <div className="col">
                            {showAllBtn}
                        </div>
                    </div>

                    {contents}
                    {loader}

                    <ConfirmationModal
                        show={this.state.showTTConfirm}
                        onYes={this.onTTConfirm}
                        onClick={this.showTTConfirmModal}
                        message={this.state.modalMessage}
                        title={this.state.modalTitle} />
                </div>
            </div>
        );
    }

}

export default AirHandlerAssetTrainingReview;