export function register() {
    if ("serviceWorker" in navigator) {
        window.addEventListener("load", ()=>{
            navigator.serviceWorker
                .register("/service-worker.js")
                .then((registration) => {
                    registration.onupdatefound = () => {
                        const installingWorker = registration.installing;
                        if (installingWorker) {
                            installingWorker.onstatechange = () => {
                                if (installingWorker.state === "installed") {
                                    if(navigator.serviceWorker.controller){
                                        console.log("New version available! Reloading ...");
                                        window.location.reload();
                                    }
                                }
                            };
                        }
                    }
                })
                .catch((error) => {
                    console.error("Service worker registration failed with error: ", error);
                })
        });
    }
}
